import {getFirestore, doc, collection, setDoc, query, where, orderBy, onSnapshot, serverTimestamp} from 'firebase/firestore'
import {makeCardPayment} from '../payment/card-payment.js'

const placeOrder = (shoppingCart, clientId, recipient, payment) => {
  const newOrderDoc = buildNewOrderDoc(shoppingCart, clientId, recipient)
  const newOrderRef = getNewOrderRef()

  if (payment.method === "card") {
    const cardPayment = {
      amount: payment.amount,
      orderId: newOrderRef.id,
      cardOwner: {
        name: payment.cardOwner.name,
        lastName: payment.cardOwner.lastName,
        email: payment.cardOwner.email,
        phoneNumber: payment.cardOwner.phoneNumber,
      }
    }

    savePendingOrder({clientId, recipient, cardPayment, shoppingCart})
    makeCardPayment(cardPayment)
  } else {
    setDoc(newOrderRef, newOrderDoc)

    shoppingCart.productsPerCompany.forEach(async (company) => {
      const newSubOrderDoc = buildNewSubOrderDoc(company)
      newSubOrderDoc.orderId = newOrderRef.id
      newSubOrderDoc.recipient = newOrderDoc.recipient

      company.productTypes.forEach(product => {
        const newProductDoc = buildNewProductDoc(product)
        newSubOrderDoc.products.push(newProductDoc)
      })
      
      const newSubOrderRef = getNewSubOrderRef(newOrderRef)
      await setDoc(newSubOrderRef, newSubOrderDoc)
    })
  }
}

const getNewOrderRef = () => {
  return doc(collection(getFirestore(), 'orders'))
}

const getNewSubOrderRef = (orderRef) => {
  return doc(collection(getFirestore(), 'subOrders'))
}

const OrderStatus = {
  NEW: 'new',
  IN_PROGRESS: 'in_progress',
  ON_ITS_WAY: 'on_its_way',
  DELIVERED: 'delivered'
}
const buildNewOrderDoc = (order, clientId, recipient) => {
  return {
    clientId: clientId,
    recipient: {
      name: recipient.name,
      phoneNumber: recipient.phoneNumber
    },
    timestamp: serverTimestamp(),
    routeDetails: order.routeDetails,
    productCount: order.productCount,
    productsTotal: order.productsTotal,
    total: order.total,
    status: OrderStatus.NEW,
    cancelled: false
  }
}

const SubOrderStatus = {
  NEW: 'new',
  IN_PREPARATION: 'in_preparation',
  READY_FOR_PICKUP: 'ready_for_pickup',
  PICKED_UP: 'picked_up',
  DELIVERED: 'delivered'
}


const buildNewSubOrderDoc = company => {
  return {
    ownerId: company.ownerId,
    companyId: company.id,
    companyName: company.name,
    companyType: company.type,
    companyLocation: company.location,
    timestamp: serverTimestamp(),
    status: SubOrderStatus.NEW,
    productCountPerCompany: company.productCountPerCompany,
    subTotalPerCompany: company.subTotalPerCompany,
    products: []
  }
}

const buildNewProductDoc = product => {
  return {
    productId: product.id,
    name: product.name,
    price: product.price,
    productCountPerType: product.productCountPerType,
    subTotalPerProductType: product.subTotalPerProductType,
    product: product.product
  }
}

const listenForSubOrdersByCompany = (ownerId, setSubOrdersByCompany) => {
  const q = query(collection(getFirestore(), 'subOrders'), 
                  where('ownerId', '==', ownerId),
                  where('status', 'in', [SubOrderStatus.NEW, 
                                          SubOrderStatus.IN_PREPARATION,
                                          SubOrderStatus.READY_FOR_PICKUP]))

  const detachListener = onSnapshot(q, snapshot => {
                            const companies = []

                            snapshot.forEach(subOrder => {
                              const subOrderData = subOrder.data()
                              subOrderData.id = subOrder.id
                              console.log(subOrderData)
                              const companyInArray = companies.find(company => 
                                                        company.companyId === subOrderData.companyId)

                              if (companyInArray) {

                                if (subOrderData.status === SubOrderStatus.NEW)
                                  companyInArray.newSubOrders.push(subOrderData)
                                else if (subOrderData.status === SubOrderStatus.IN_PREPARATION)
                                  companyInArray.subOrdersInPreparation.push(subOrderData)
                                else if (subOrderData.status === SubOrderStatus.READY_FOR_PICKUP)
                                  companyInArray.subOrdersReadyForPickup.push(subOrderData)
                                
                              } else {
                                const newCompany = {
                                  companyId: subOrderData.companyId, 
                                  companyName: subOrderData.companyName,
                                  newSubOrders: [],
                                  subOrdersInPreparation: [],
                                  subOrdersReadyForPickup: []
                                }

                                if (subOrderData.status === SubOrderStatus.NEW)
                                  newCompany.newSubOrders.push(subOrderData)
                                else if (subOrderData.status === SubOrderStatus.IN_PREPARATION)
                                  newCompany.subOrdersInPreparation.push(subOrderData)
                                else if (subOrderData.status === SubOrderStatus.READY_FOR_PICKUP)
                                  newCompany.subOrdersReadyForPickup.push(subOrderData)

                                companies.push(newCompany)
                              }
                            })

                            setSubOrdersByCompany(companies)
                          })

  return detachListener
}

const listenForCompanySubOrders = (company, ownerId, setCompanySubOrders) => {
  const q = query(collection(getFirestore(), 'subOrders'),
                            where('ownerId', '==', ownerId),
                            where('companyId', '==', company.companyId),
                            where('status', 'in', [SubOrderStatus.NEW, 
                                                    SubOrderStatus.IN_PREPARATION,
                                                    SubOrderStatus.READY_FOR_PICKUP]),
                            orderBy('timestamp', 'desc'))

  const detachListener = onSnapshot(q, snapshot => {
        const companySubOrders = {
          companyId: company.companyId, 
          companyName: company.companyName, 
          newSubOrders: [],
          subOrdersInPreparation: [],
          subOrdersReadyForPickup: []
        }
        
        snapshot.forEach(subOrder => {
          const subOrderData = subOrder.data()
          subOrderData.id = subOrder.id

          if (subOrderData.status === SubOrderStatus.NEW)
            companySubOrders.newSubOrders.push(subOrderData)
          else if (subOrderData.status === SubOrderStatus.IN_PREPARATION)
            companySubOrders.subOrdersInPreparation.push(subOrderData)
          else if (subOrderData.status === SubOrderStatus.READY_FOR_PICKUP)
            companySubOrders.subOrdersReadyForPickup.push(subOrderData)
        })

        setCompanySubOrders(companySubOrders)
   })

  return detachListener
}

const updateSubOrderStatus = async (subOrderId, newStatus) => {
  const subOrderRef = doc(getFirestore(), `subOrders/${subOrderId}`)
  await setDoc(subOrderRef, {status: newStatus}, {merge: true})
}

const updateOrderStatus = async (orderId, newStatus, subOrders = []) => {
  console.log('---',subOrders)
  const orderRef = doc(getFirestore(), `orders/${orderId}`)
  await setDoc(orderRef, {status: newStatus}, {merge: true})

  if (newStatus === OrderStatus.DELIVERED) {
    subOrders.forEach(subOrder => {
      updateSubOrderStatus(subOrder.id, SubOrderStatus.DELIVERED)
    })
  }
}

const getSubOrderStatusText = (status) => {
  let newStatus = 'COMENZAR PREPARACION'

  switch(status) {
    case SubOrderStatus.NEW:
      newStatus = 'COMENZAR PREPARACION'
      break
    case SubOrderStatus.IN_PREPARATION:
      newStatus = 'LISTO PARA RECOLECCION'
      break
    case SubOrderStatus.READY_FOR_PICKUP:
      newStatus = 'RECOLECTADO'
      break
    case SubOrderStatus.PICKED_UP:
      newStatus = 'ENTREGADO'
      break
    case SubOrderStatus.DELIVERED:
      newStatus = 'COMPLETO'
      break
    default:
      newStatus = 'COMENZAR PREPARACION'
      break
  }

  return newStatus 
}

const getSubOrderStatusColor = (status) => {
  let newColor = 'darkgrey'

  switch(status) {
    case SubOrderStatus.NEW:
      newColor = 'darkgrey'
      break
    case SubOrderStatus.IN_PREPARATION:
      newColor = 'darkgrey'
      break
    case SubOrderStatus.READY_FOR_PICKUP:
      newColor = 'darkred'
      break
    case SubOrderStatus.PICKED_UP:
      newColor = 'darkorange'
      break
    case SubOrderStatus.DELIVERED:
      newColor = 'darkgreen'
      break
    default:
      newColor = 'darkgrey'
      break
  }

  return newColor
}

const savePendingOrder = async (pendingOrder) => {
  const userRef = doc(getFirestore(), `users/${pendingOrder.clientId}`)

  await setDoc(userRef, {
          clientPaymentInfo: pendingOrder.cardPayment.cardOwner, 
          recipient: pendingOrder.recipient,
          pendingOrder: JSON.stringify(pendingOrder)}, {merge: true})
}

export {placeOrder, 
        listenForSubOrdersByCompany, 
        listenForCompanySubOrders, 
        OrderStatus, 
        SubOrderStatus, 
        updateSubOrderStatus, 
        updateOrderStatus,
        getSubOrderStatusColor,
        getSubOrderStatusText}
