import Style from './_SearchBox.module.sass';
import { SearchBox } from 'react-instantsearch-dom';

const TitleBarComponents = () => {
  return (
    <div className={Style.SearchBox}>
      <SearchBox 
        searchAsYouType={false} 
        showLoadingIndicator={true}
        translations={{placeholder: 'Buscar...'}}/>
    </div> 
  );

}

export default TitleBarComponents;
