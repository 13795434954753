import Style from './_Zoom.module.sass'
import {useSelector, useDispatch} from 'react-redux'
import {closeZoom} from 'redux/app/zoomSlice'
import {useNavigate, useLocation} from 'react-router-dom'
import {useEffect, useState} from 'react'

const Zoom = () => {
  const dispatch = useDispatch()
  const {picture} = useSelector(state => state.zoom)
  const navigate = useNavigate()
  const location = useLocation()
  const [display, setDisplay] = useState('none')

  useEffect(()=>{
   location.hash === '#showPicture' ? setDisplay('flex') : setDisplay('none')
  }, [location.hash])

  return (
    <div className={Style.overall} style={{display: display}} >
      <div className={Style.zoom} 
            onClick={(e)=>{
              if (e.nativeEvent.target.localName !== 'img') {
                dispatch(closeZoom())
                navigate(location.pathname, {replace: true})
              } 
            }}>
        <div className={Style.closeButton} onClick={()=>{
          dispatch(closeZoom())
                navigate(location.pathname, {replace: true})
        }}>X</div>
      </div>
      <div className={Style.pictureContainer}>
        <img src={picture} alt='' width={window.innerWidth/1.1}/>
      </div>
    </div>
  );

}

export default Zoom;
