import TitleBar from 'components/common/TitleBar';
import Content from './Content';
import TitleBarComponents from './TitleBarComponents';
import Cards from './Cards';

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import {useLocation, useNavigate} from 'react-router-dom'

import {makeStyles} from '@material-ui/core'
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'

import Style from './_SearchEngine.module.sass'

const searchClient = algoliasearch(
                        process.env.REACT_APP_ALGOLIA_APP_ID,
                        process.env.REACT_APP_ALGOLIA_SEARCH_KEY);

const useStyles = makeStyles({
  root: {height: '23px'},
  sizeSmall: { padding: '0px 5px'},
  label: { color: 'white' },
  selected: { 
    backgroundColor: 'rgba(0,0,0,0.35)!important',
    '&:hover': {backgroundColor: 'rgba(0,0,0,0.35)!important' }
  }
})

const SearchEngine = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const classes = useStyles()

  let searchIndex =  location.pathname === '/products' ? 'products' : 'companies'

  return (
    <div>
      <InstantSearch 
        key={searchIndex}
        searchClient={searchClient} 
        indexName={searchIndex}>
        <TitleBar>
          <TitleBarComponents />
        </TitleBar>
        <div className={Style.searchConfigBar}>
          <ToggleButtonGroup 
            value={searchIndex} 
            exclusive 
            onChange={(e, v)=> {
              if(v === null)
                return
                
              navigate(`/${v}`, {replace: true})
            }} 
            size='small' >
            <ToggleButton value='companies'
            classes={{root: classes.root,
                      sizeSmall: classes.sizeSmall,
                      label: classes.label,
                      selected: classes.selected}}>
              Establecimientos
            </ToggleButton>
            <ToggleButton value='products'
            classes={{root: classes.root,
                      sizeSmall: classes.sizeSmall,
                      label: classes.label,
                      selected: classes.selected}}>
              Productos
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Content indexName={searchIndex}>
          <Cards indexName={searchIndex}/>
        </Content>
      </InstantSearch>
    </div>
  );
}

export default SearchEngine;
