import Style from "./_Cards.module.sass"
import BusinessCard from "components/account/companies/business-card/BusinessCard.js"
import ProductCard from "components/account/companies/products/product-card/ProductCard.js"
import {connectInfiniteHits} from 'react-instantsearch-dom'
import {useLayoutEffect, useEffect, useRef, useState} from 'react'

const InfiniteHits = ({hits, hasPrevious, refinePrevious, hasMore, refineNext, insights, indexName}) => {
  const cards = useRef(null)

  const sentinelBefore = useRef(null)
  const sentinelAfter= useRef(null)

  const isIntersectingBefore = useRef(false)
  const isIntersectingAfter = useRef(false)

  const [isUpdateNeeded, setIsUpdateNeeded] = useState(false)

  const onSentinelIntersection = entries => {
    entries.forEach(entry => {
      if (entry.target === sentinelBefore.current) {
        isIntersectingBefore.current = entry.isIntersecting
      }

      if (entry.target === sentinelAfter.current) {
        isIntersectingAfter.current = entry.isIntersecting
      }

      if (isIntersectingBefore.current || isIntersectingAfter.current)
        setIsUpdateNeeded(true)
    })
  }

  useEffect(() => {
    if(!isUpdateNeeded)
      return

    if (isIntersectingBefore.current && hasPrevious) {
      refinePrevious()
    }

    if (isIntersectingAfter.current && hasMore) {
      refineNext()
    }
    
    setIsUpdateNeeded(false)
  }, [isUpdateNeeded, hasMore, refineNext, hasPrevious, refinePrevious])

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(onSentinelIntersection)
    observer.observe(sentinelBefore.current)
    observer.observe(sentinelAfter.current)

    return () => observer.disconnect()
  }, [])

  return (
    <div id='cards' ref={cards}>
      <div id="sentinelBefore" className={Style.emptyBefore} ref={sentinelBefore}/>
      <div id="cards" className={Style.cards}>
        {
          indexName === 'companies' ?
            hits.map(hit => <BusinessCard key={hit.objectID} hit={hit} />)
            :
            hits.map(hit => <ProductCard key={hit.objectID} hit={hit} />) 
        }
      </div>
      <div id="sentinelAfter" className={Style.emptyAfter} ref={sentinelAfter}/>
    </div>
  ) 
}

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits);

const Cards = ({indexName}) => {
  return <CustomInfiniteHits indexName={indexName} />
}

export default Cards;
