import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {AiOutlineHome} from 'react-icons/ai'

import {useEffect, useState} from 'react'
import {getAuth} from 'firebase/auth'
import OrderCard from './OrderCard'
import {listenForDeliveryGuyOrders} from './delivery-utils'

import Style from './_DeliveryView.module.sass'

const DeliveryView= () => {
  const [deliveryGuyOrders, setDeliveryGuyOrders] = useState({newOrders: [], 
                                                              ordersInProgress: [], 
                                                              ordersOnItsWay: [], 
                                                              ordersDelivered: []})

  useEffect(() => {
    const detachListener = listenForDeliveryGuyOrders(getAuth().currentUser.uid, setDeliveryGuyOrders)
    return detachListener
  }, [])

  return (
    <div>
      <TitleBar>
        <h4 className={Style.title}>Entregas</h4>
      </TitleBar>
      <Content>
      {
        deliveryGuyOrders?.newOrders?.length > 0 &&
        <div>
          <div className={Style.section}>
            Entrega Activa:
          </div>
          <div>
            {
              deliveryGuyOrders.newOrders.map(order => {
                return <OrderCard key={order.id} order={order} backgroundColor='darkred'/>
              })
            }
          </div>
        </div>
      }
      {
        deliveryGuyOrders?.ordersInProgress?.length > 0 &&
        <div>
          <div className={Style.section}>
            Entrega en recoleccion:
          </div>
          <div>
            {
              deliveryGuyOrders.ordersInProgress.map(order => {
                return <OrderCard key={order.id} order={order} backgroundColor='darkorange'/>
              })
            }
          </div>
        </div>
      }
      {
        deliveryGuyOrders?.ordersOnItsWay?.length > 0 &&
        <div>
          <div className={Style.section}>
            Entrega en camino:
          </div>
          <div>
            {
              deliveryGuyOrders.ordersOnItsWay.map(order => {
                return <OrderCard key={order.id} order={order} backgroundColor='darkgreen'/>
              })
            }
          </div>
        </div>
      }
      {
        deliveryGuyOrders?.ordersDelivered?.length > 0 &&
        <div>
          <div className={Style.section}>
            Entregas terminadas:
          </div>
          <div>
            {
              deliveryGuyOrders.ordersDelivered.map(order => {
                return <OrderCard key={order.id} order={order} backgroundColor='darkgrey' />
              })
            }
          </div>
        </div>
      }
        <FloatingButton
          position='bottom-right'
          to='/'>
          <AiOutlineHome style={{fill: 'white'}} size='26px' />
        </FloatingButton>
      </Content>
    </div>
  )
};

export default DeliveryView;
