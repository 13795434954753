import Style from './_ShoppingCartInformative.module.sass'
import {useSelector} from 'react-redux'
import {useLocation, Link} from 'react-router-dom'
import {useCallback} from 'react'

const ShoppingCartInformative = () => {
  const location = useLocation()
  const shoppingCart = useSelector(state => state.shoppingCart)

  const getDeliveryLocationText = useCallback((locationType) => {
    const locationState = {fromSC: location.pathname}
    if (location.pathname.startsWith('/company/')) {
      locationState.company = location.state?.company
      locationState.from = location.state?.from
    }

    if (locationType === 'none')
      return <p className={Style.text}>
              {location.pathname !== '/shoppingcart' ?
                  <Link to={{pathname: '/shoppingcart', state: locationState}}> 
                    Selecciona ubicacion de entrega para incluir costo de envio
                  </Link> 
                :
                `Selecciona ubicacion de entrega para incluir costo de envio`
              }
             </p>
    else if (locationType === 'current')
      return <p className={Style.text}>
               Entregando en ubicacion <strong>actual</strong>. 
              {location.pathname !== '/shoppingcart' &&
                 <Link to={{pathname: '/shoppingcart', state: locationState}}>Actualizar</Link>}
             </p>
    else if (locationType === 'alternative') 
      return <p className={Style.text}>
               Entregando en ubicacion <strong>alterna</strong>. 
                {location.pathname !== '/shoppingcart' &&
                 <Link to={{pathname: '/shoppingcart', state: locationState}}>Actualizar</Link>}
             </p>
    else 
      return ''
  },[location.pathname, location.state?.company, location.state?.from])

  return (
        <div className={Style.shoppingCartInformative}
                                style={{display: (shoppingCart.productCount > 0 && 
                                  (['/', '/products', '/companies','/shoppingcart'].includes(location.pathname) ||
                                  /\/company\/.+/.test(location.pathname)) ? 
                              'block' : 
                              'none'),
                     }} >
          <div className={Style.flexDiv}>
            <div className={Style.details}>
              <div className={Style.quantity}>
                  <span className={Style.label}>Productos:</span>
                  <span className={Style.value}>{shoppingCart.productCount}</span>
              </div>
              <div className={Style.subtotal}>
                  <span className={Style.label}>Subtotoal:</span>
                  <span className={Style.value}>
                    {shoppingCart.productsTotal}
                  </span>
              </div>
              <div className={Style.delivery}>
                  <span className={Style.label}>Envio:</span>
                  <span className={Style.value}>
                    {shoppingCart.routeDetails.deliveryPrice}
                  </span>
              </div>
            </div>
            <div className={Style.total}>
                <span className={Style.label}>Total:</span>
                <div className={Style.value}>
                  {shoppingCart.total} 
                  <div className={Style.tipoMoneda}> MXN </div>
                </div>
            </div>
          </div>
          <div className={Style.foot}>
              {
                getDeliveryLocationText(shoppingCart.routeDetails.destination.type)
              }
          </div>
        </div>
  )
}

export default ShoppingCartInformative 
