import Style from './_ProductCard.module.sass'
import PictureAndInfo from './PictureAndInfo.js'
import CompanyReference from './CompanyReference'
import ShoppingCartAggregator from 'components/shopping-cart/ShoppingCartAggregator'
import MultiProductSubtotal from 'components/shopping-cart/MultiProductSubtotal'
import {useLocation} from 'react-router-dom'
import {useState, useEffect, useCallback} from 'react'

const ProductCard = ({hit}) => {
  const location = useLocation()

  const product = {...hit}
  product.id = hit.objectID || hit.id
 // console.log(product);

  const info = {
    ownerId: product.ownerId,
    companyId: product.companyId,
    companyName: product.companyName,
    companyPicture: product.companyPicture,
    id: product.id,
    name: product.name, 
    description: product.description, 
    price: product.price, 
    }

  const checkAvailabilityForDay = (dayToCheck, thisTime) => {
    let startHrsStr = dayToCheck.from
    const [startHrStr, startMinStr] = startHrsStr.split(':')
    const startHrInt = Number.parseInt(startHrStr)
    const startMinInt = Number.parseInt(startMinStr)
    const startTime  = new Date().setHours(startHrInt, startMinInt, 0, 0)

    let endHrsStr = dayToCheck.to
    const [endHrStr, endMinStr] = endHrsStr.split(':')
    const endHrInt = Number.parseInt(endHrStr)
    const endMinInt = Number.parseInt(endMinStr)
    const endTime  = new Date().setHours(endHrInt, endMinInt, 0, 0)

    if (thisTime >= startTime && thisTime <= endTime)
      return true
    else
      return false
  }

  const checkAvailability = useCallback(() => {
    if (product.openHours) {
      const thisTime = new Date()
      const day = thisTime.getDay()

      switch (day) {
        case 0: 
          if(product.openHours.Domingo && product.openHours.Domingo.enabled)
            return checkAvailabilityForDay(product.openHours.Domingo, thisTime)
          break
        case 1: 
          if(product.openHours.Lunes && product.openHours.Lunes.enabled)
            return checkAvailabilityForDay(product.openHours.Lunes, thisTime)
          break
        case 2: 
          if(product.openHours.Martes && product.openHours.Martes.enabled)
            return checkAvailabilityForDay(product.openHours.Martes, thisTime)
          break
        case 3: 
          if(product.openHours.Miercoles && product.openHours.Miercoles.enabled)
            return checkAvailabilityForDay(product.openHours.Miercoles, thisTime)
          break
        case 4: 
          if(product.openHours.Jueves && product.openHours.Jueves.enabled)
            return checkAvailabilityForDay(product.openHours.Jueves, thisTime)
          break
        case 5: 
          if(product.openHours.Viernes && product.openHours.Viernes.enabled)
            return checkAvailabilityForDay(product.openHours.Viernes, thisTime)
          break
        case 6: 
          if(product.openHours.Sabado && product.openHours.Sabado.enabled)
            return checkAvailabilityForDay(product.openHours.Sabado, thisTime)
          break
        default:
          break
      }
    }
  }, [product.openHours])

  const [isOpen, setIsOpen] = useState((product.openHours && checkAvailability()) || false)

  useEffect(() => {
    if(product.openHours) {
      const interval = setInterval(() => setIsOpen(checkAvailability()), 60 * 1000)
      return () => clearInterval(interval)
    }
  })

  return  <div id={product.id} className={Style.card}>
            <PictureAndInfo picture={product.picture} info={info}/> 
            <div className={`${Style.foot} ${location.pathname.startsWith('/shoppingcart') && Style.multiProductSubtotal}`}>
              {
              !(location.pathname.startsWith('/shoppingcart') || location.pathname.startsWith('/company/')) &&
                  <CompanyReference info={info}/>
              }
              { location.pathname.startsWith('/shoppingcart') &&
                <MultiProductSubtotal product={product} />
              } 
              {
                isOpen ?
                  <ShoppingCartAggregator product={product} />
                  :
                  <div className={Style.closed}>CERRADO</div>
              }
            </div>
          </div>
};

export default ProductCard;
