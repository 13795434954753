
const DB_NAME = 'mandux-t-db'
const DB_VERSION = '1'
const DB_STORE_NAME = 'deliveryGuy'

let db;
const request = indexedDB.open(DB_NAME, DB_VERSION)

request.onsuccess = event => {
  db = event.target.result
  console.log('Database created', event)
}
  
request.onerror = event => {
  console.log('Database creation failed: ', event.target.errorCode)
}

request.onupgradeneeded = event => {
  let db = event.target.result
  db.createObjectStore(DB_STORE_NAME, {keyPath: 'dgId'})
}

export {db}
