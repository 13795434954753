import {useSelector} from 'react-redux'
import Style from './_MultiProductSubtotal.module.sass'

const MultiProductSubtotal = ({product}) => {
  const productsPerCompany = useSelector(state => state.shoppingCart.productsPerCompany)
  const companyInCart = productsPerCompany.find(company => company.id === product.companyId)
  const productTypeInCart = companyInCart?.productTypes?.find(productType => productType.id === product.id)

  return  (
            <div className={Style.multiProductSubtotal} 
                  style={{display: productTypeInCart?.productCountPerType > 0 ? 'flex' : 'none' }}>
              <div>
                Subtotal: {productTypeInCart?.subTotalPerProductType}
                <div className={Style.tipoMoneda}> MXN </div>
              </div>
            </div>
        )
};

export default MultiProductSubtotal
