import {onLoad, changeImage} from 'components/common/picture-utils';
import BusinessImg from 'components/common/img/business.svg';
import {FormControl, FormGroup, FormControlLabel, InputLabel, Select, MenuItem, TextField, Switch, makeStyles} from '@material-ui/core';

import {useFormContext, Controller} from 'react-hook-form';

import Style from './_Identity.module.sass';

const useStyles = makeStyles({
  root: {
    alignItems: 'center'
  }
});
const Identity = () => {
  const classes = useStyles();
  const {register, control, getValues, setValue} = useFormContext();

  const previousPictureArray = getValues('picture')
  const previousPictureFile = previousPictureArray?.length === 1 ?
                                window.URL.createObjectURL(previousPictureArray[0]) : 
                                null;

  const previousNameValue = getValues('name')
  const previousTypeValue = getValues('type')
  const previousDescriptionValue = getValues('description')
  const previousHasDeliveryValue = getValues('hasDelivery')

  return  <div className={Style.general}>
            <div className={Style.stepName}>
              <h4>Informacion General</h4>
            </div>
              
            <div id="picture" className={Style.bizImg}>
              <div id="picture-circle" className={Style.pictureCircle}>
                <div id="picture-container" className={Style.pictureContainer}>
                  <img id="photo"
                       src={previousPictureFile ?? BusinessImg}
                       onClick={()=>document.getElementById('photo-picker').click()}
                       onLoad={(e)=>{onLoad(e, 170)}}
                       alt='Foto de negocio' />
                  <Controller 
                    control={control}
                    name='picture'
                    rules={{required: 'La imagen (o logo) obligatoria'}}
                    render={({field})=>(
                          <input id="photo-picker"
                                 type="file"
                                 accept="image/*"
                                 hidden 
                                 onChange={(event)=>{
                                     setValue('picture', event.target.files)
                                     changeImage(event);
                                   }
                                 } />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={Style.info}>
              <div className={Style.fields}>
                <TextField id='name' 
                            label='Nombre'
                            autoComplete='off'
                            defaultValue={previousNameValue}
                            {...register('name', {
                              required: 'El nombre del negocio es necesario'
                            })}/>

                <FormControl>
                  <InputLabel id='type-label'> Tipo </InputLabel>
                  <Controller 
                    control={control} 
                    name='type' 
                    rules={{
                      required: 'El tipo de negocio es necesario'
                    }}
                    render={({field}) => 
                      <Select {...field} labelId='type-label' value={previousTypeValue || field.value || ''}>
                        <MenuItem value='' disabled>Tipo</MenuItem>
                        <MenuItem value='Abarrotes'>Abarrotes</MenuItem>
                        <MenuItem value='Alimento animal'>Alimento animal</MenuItem>
                        <MenuItem value='Bazar'>Bazar</MenuItem>
                        <MenuItem value='Carniceria'>Carniceria</MenuItem>
                        <MenuItem value='Comida'>Comida</MenuItem>
                        <MenuItem value='Farmacia'>Farmacia</MenuItem>
                        <MenuItem value='Ferreteria'>Ferreteria</MenuItem>
                        <MenuItem value='Floreria'>Floreria</MenuItem>
                        <MenuItem value='Joyeria'>Joyeria</MenuItem>
                        <MenuItem value='Paleteria'>Paleteria</MenuItem>
                        <MenuItem value='Panaderia'>Panaderia</MenuItem>
                        <MenuItem value='Papeleria'>Papeleria</MenuItem>
                        <MenuItem value='Pescaderia'>Pescaderia</MenuItem>
                        <MenuItem value='Polleria'>Polleria</MenuItem>
                        <MenuItem value='Resposteria'>Reposteria</MenuItem>
                        <MenuItem value='Ropa'>Ropa</MenuItem>
                        <MenuItem value='Verduleria'>Verduleria</MenuItem>
                        <MenuItem value='Zapateria'>Zapateria</MenuItem>
                      </Select>
                  }/>
                </FormControl>

                <TextField id='description' 
                            label='Descripcion'
                            defaultValue={previousDescriptionValue}
                            {...register('description', {
                              required: 'La descripcion del negocio es necesaria'
                            })}/>

    { false &&
                <FormGroup column='true' classes={{root: classes.root}}>
                  <Controller control={control} 
                              name='hasDelivery' 
                              defaultChecked={previousHasDeliveryValue}
                              render={({field})=>(
                                <FormControlLabel 
                                  label='Entrega a domicilio' 
                                  labelPlacement='start'
                                  control={<Switch 
                                            {...field}
                                            checked={field.value}
                                             color='primary' 
                                             />
                                   } />
                              )} />
                </FormGroup>
    }

              </div>
            </div>

          </div>
};

export default Identity;
