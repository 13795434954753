import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import ProductManagementCards from './ProductManagementCards'
import FloatingButton from 'components/common/FloatingButton'
import {FaPlus} from 'react-icons/fa'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {useLocation} from 'react-router-dom'
import BusinessCard from 'components/account/companies/business-card/BusinessCard'

import Style from './_ProductManagement.module.sass'

const ProductManagement = () => {
  const company = useLocation().state

  return <div>
            <TitleBar>
              <h4 className={Style.title}>Mis Productos</h4>
            </TitleBar>
            <Content>
              <BusinessCard hit={company} />
              
              <ProductManagementCards />
            </Content>
            <FloatingButton
              position='bottom-left'
              to='/account/companies'>
              <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
            <FloatingButton
              position='bottom-right'
              to={`/account/companies/${company.id}/products/add`}
              stateParam={company}>
              <FaPlus style={{fill: 'white'}}/>
            </FloatingButton>
              
    </div>
};

export default ProductManagement;
