import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import {useNavigate, useLocation} from 'react-router-dom'
import {Button} from '@material-ui/core'
import {FcGoogle} from 'react-icons/fc'
import {ImFacebook} from 'react-icons/im'
import {signInWithGoogle} from 'components/auth/GoogleAuth'
import {signInWithFacebook} from 'components/auth/FacebookAuth'
import PhoneAuth from 'components/auth/PhoneAuth'
import {turnOn, turnOff} from 'redux/app/backdropSlice'
import {useDispatch} from 'react-redux'
import {useState} from 'react'

import Style from './_Authentication.module.sass'

const Authentication = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const [existingAccountProvider, setExistingAccountProvider] = useState('')

  console.log(location)

  let { from } = location.state || {from: {pathname: '/'}}
  console.log(from)

  return (
    <div>
      <TitleBar>
        <h4 className={Style.title}> Autenticacion </h4>
      </TitleBar>
      <Content>
        <div className={Style.authWidgetContainer}>
          <fieldset className={Style.authWidget}>
            <legend> Entrar con: </legend>
            {
              existingAccountProvider && 
              <p style={{color: 'white'}}>Al parecer ya estas dado de alta, intenta entrar con {existingAccountProvider}</p>
	          }

	   
            <Button 
              variant='contained' 
              startIcon={<FcGoogle />}
              onClick={()=>{
              dispatch(turnOn())
              signInWithGoogle(from, navigate, setExistingAccountProvider)
              dispatch(turnOff())
              }}>
               Google
            </Button>

            <Button
              variant='contained' 
              color='primary' 
              startIcon={<ImFacebook />}
              onClick={()=>{
              dispatch(turnOn())
              signInWithFacebook(from, navigate, setExistingAccountProvider)
              dispatch(turnOff())
              }}>
              Facebook
            </Button>
          
						<PhoneAuth navigateTo={from}/>
          </fieldset>

        </div>
      </Content>
    </div>
  );
}

export default Authentication;
