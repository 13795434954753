import { ReactComponent as facebook } from "./img/facebook.svg";
import { ReactComponent as instagram } from "./img/instagram.svg";
import { ReactComponent as location } from "./img/location.svg";
import { ReactComponent as phone } from "./img/phone.svg";
import { ReactComponent as site } from "./img/site.svg";
import { ReactComponent as sms } from "./img/sms.svg";
import { ReactComponent as tiktok } from "./img/tiktok.svg";
import { ReactComponent as twitter } from "./img/twitter.svg";
import { ReactComponent as whatsapp } from "./img/whatsapp.svg";
import { ReactComponent as telegram } from "./img/telegram.svg";
import { ReactComponent as youtube } from "./img/youtube.svg";

const SVGList = [
  {
    type: "call",
    link: "tel: +52 ",
    image: phone
  },
  {
    type: "sms",
    link: "sms: +52 ",
    image: sms
  },
  {
    type: "location",
    link: "https://www.google.com/maps/place/",
    image: location
  },
  {
    type: "facebook",
    link: "fb://facewebmodal/f?href=",
    image: facebook
  },
  {
    type: "whatsapp",
    link: "https://wa.me/",
    image: whatsapp
  },
  {
    type: "telegram",
    link: "https://telegram.me/+52 ",
    image: telegram
  },
  {
    type: "twitter",
    link: "https://twitter.com/",
    image: twitter
  },
  {
    type: "instagram",
    link: "https://instagram.com/",
    image: instagram
  },
  {
    type: "tiktok",
    link: "https://tiktok.com/",
    image: tiktok
  },
  {
    type: "youtube",
    link: "",
    image: youtube
  },
  {
    type: "website",
    link: "",
    image: site
  }
];

export default SVGList;
