import Style from "./_Content.module.sass";
import {useEffect, useRef} from 'react'

const Content = (props) => {
  let contentRef = useRef(null)

  useEffect(() => {
    console.log('executing effect', sessionStorage.companiesYScroll)
    if (props.indexName !== 'companies') {
      return
    }

    //we have to wait until algolia finishes drawing the cards (possibly)
    setTimeout(()=>{contentRef.current.scrollTo({top: sessionStorage.companiesYScroll})}, 0)
  }, [props.indexName])

  const onScroll = e => {
    sessionStorage.companiesYScroll = e.target.scrollTop
  }

  return (
    <div ref={contentRef} className={Style.content} onScroll={props.indexName === 'companies' ? onScroll : undefined}>
      {props.children}
    </div>
  )

}

export default Content;
