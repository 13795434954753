import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {IoCall} from 'react-icons/io5'
import {useLocation} from 'react-router-dom'
import ProductCard from 'components/account/companies/products/product-card/SubordersProductCard'
import {Button} from '@material-ui/core'
import {SubOrderStatus, updateSubOrderStatus, getSubOrderStatusText} from './order-utils'
import {listenForSubOrderChanges} from 'components/delivery/delivery-utils'
import {useState, useEffect} from 'react'

import Style from './_SubOrder.module.sass'

const SubOrder = () => {
  const location = useLocation()
  const [subOrder, setSubOrder] = useState(location?.state?.subOrder)
  const [company] = useState(location?.state?.company)

  useEffect(() => {
    const detachListener = listenForSubOrderChanges(subOrder.id, setSubOrder)
    return detachListener
  }, [subOrder.id])

  const changeStatus = () => {
    if (subOrder.status === SubOrderStatus.NEW) {
      updateSubOrderStatus(subOrder.id, SubOrderStatus.IN_PREPARATION)
    } else if (subOrder.status === SubOrderStatus.IN_PREPARATION) {
      updateSubOrderStatus(subOrder.id, SubOrderStatus.READY_FOR_PICKUP)
    } 
  }

  return <div>
            <TitleBar>
              <h4 className={Style.title}>Detalle de venta</h4>
            </TitleBar>
            <Content>
              <div>
                <div className={Style.companyName}>
                  <p>{subOrder.companyName}</p> 
                  <p className={Style.orderNumber}>{subOrder.timestamp.seconds}</p>
                  <div className={Style.recipient}>
                    <div className={Style.label}>Cliente:</div>
                    <Button
                      component='a'
                      color='secondary'
                      variant='contained'
                      size='small'
                      href={`tel: ${subOrder?.recipient?.phoneNumber}`}>
                      {subOrder?.recipient?.name}
                      &nbsp;
                      <IoCall size='20px'/>
                    </Button>
                  </div>
                </div>
                <div>
                  {
                    subOrder.products.map(product => {
                      return <ProductCard key={product.product.id} productType={product} />
                    })
                  }
                </div>
                <div className={Style.statusButtonDiv}>
                  <div className={Style.orderTotal}>
                    Total: {subOrder.subTotalPerCompany} 
                    <div className={Style.tipoMoneda}> MXN </div>
                  </div>
                  <Button color='primary'
                          variant='contained'
                          onClick={changeStatus} 
                          disabled={subOrder.status === SubOrderStatus.READY_FOR_PICKUP ||
                                    subOrder.status === SubOrderStatus.PICKED_UP ||
                                    subOrder.status === SubOrderStatus.DELIVERED}
                          >  

                    { getSubOrderStatusText(subOrder.status) }

                  </Button>   
                </div>
              </div>
            </Content>
            <FloatingButton
              position='bottom-left'
              to={`/suborders/company/${subOrder.companyId}`}
              stateParam={{company: company, subOrder: subOrder}}>
              <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
    </div>
};

export default SubOrder
