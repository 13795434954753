import Style from './_ProductManagementCard.module.sass'
import PictureAndInfo from './PictureAndInfo.js'

const ProductManagementCard = ({companyId, product}) => {
  console.log(product);
  const info = {
    companyId: companyId,
    productId: product.id,
    name: product.name, 
    description: product.description, 
    price: product.price, 
    }

  return  <div id={product.id} className={Style.card}>
            <PictureAndInfo picture={product.picture} info={info}/> 
          </div>
};

export default ProductManagementCard;
