import Style from './_ProductManagementCards.module.sass'
import ProductManagementCard from './product-management-card/ProductManagementCard'
import {useState, useEffect} from 'react'
import {getAuth} from 'firebase/auth'
import {getFirestore, collection, getDocs} from 'firebase/firestore'
import {useParams} from 'react-router-dom'

import {useDispatch} from 'react-redux'
import {turnOn, turnOff} from 'redux/app/backdropSlice'

const ProductManagementCards = ({companyBasicInfo}) => {
  const dispatch = useDispatch()
  const [products, setProducts] = useState([])
  const {companyId} = useParams()

  useEffect(()=>{
    dispatch(turnOn())
    const queryResult = []
    
    getDocs(collection(getFirestore(), `users/${getAuth().currentUser.uid}/companies/${companyId}/products`))
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id

          queryResult.push(data)
        })
        
        setProducts(queryResult)
      })
    dispatch(turnOff())
  }, [companyId, dispatch])

  return <div id='productManagementCards' className={Style.productManagementCards}>
          { 
            products.map((product) => {
              return <ProductManagementCard key={product.id} companyId={companyId} product={product} />
            })
          }
         </div>
}

export default ProductManagementCards;
