import {getAuth} from 'firebase/auth'
import {getFirestore, doc, setDoc} from 'firebase/firestore'
import {isSupported, getMessaging, getToken, onMessage} from 'firebase/messaging'

const vapidKey = process.env.REACT_APP_VAPIDKEY

let areNotificationsGranted = false
let firebaseMessagingToken = ''

const areNotificationsSupported = () => {
  if('Notification' in window) {
    console.log('Notifications supported')
    return true
  }

  console.log('Notifications NOT supported')
  return false
}

const requestNotificationPermission = async () => {
  console.log('Getting notifications permission')
  return await Notification.requestPermission()
}

const isPermissionGranted = (permission) => {
  if(permission === 'granted') {
    console.log('Notification permission granted')
    areNotificationsGranted = true
    return true
  } else if (permission === 'denied') {
    console.log('Notification permission denied')
  } else {
    console.log('Notification permission inconclusive')
  }

  return false
}

const isFirebaseMessagingSupported = () => {
  if (isSupported()) {
    console.log('Firebase messaging supported')
    return true
  }

  console.log('Firebase messaging not supported')
  return false
}

const setUpNotifications = async () => {
  if (!isFirebaseMessagingSupported()) 
    return

  if (!areNotificationsSupported())
    return

  const permission = await requestNotificationPermission()

  if (!isPermissionGranted(permission))
    return

  await processGrantedNotificationPermission()
}

const resetNotifications = async () => {
  if (!isFirebaseMessagingSupported()) 
    return

  if (!areNotificationsSupported())
    return

  await processNotificationReset()
}

const processNotificationReset = async () => {
  const registration = await navigator.serviceWorker.getRegistration()

  if (!registration) {
    console.log('No service worker registration available')
    return
  }

  const token = await getFirebaseMessagingToken(registration)
  await unregisterDeviceFromNotifications(token)
}

const getFirebaseMessagingToken = async (registration) => {
  try {
    const token = await getToken(getMessaging(), {
      vapidKey: vapidKey,
      serviceWorkerRegistration: registration
    })

    console.log('Token: ', token)
    firebaseMessagingToken = token

    return token
  } catch (e) {
    console.log('An error occurred while retrieving the token', e)
  }
}

const processGrantedNotificationPermission = async () => {
  const registration = await navigator.serviceWorker.getRegistration()

  if (!registration) {
    console.log('No service worker registration available')
    return
  }

  const token = await getFirebaseMessagingToken(registration)
  await registerDeviceForNotifications(token)
  setUpMessageListener()
}

const registerDeviceForNotifications = async (token) => {
  try {
    const protocol = 'https'
    const host = `us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net`
    const path = '/notifications-subscribeToNotifications'
    const queryParams = `iid=${token}`
    const url = `${protocol}://${host}${path}?${queryParams}`

    await fetch(url, {mode: 'no-cors'})
    console.log('Device registered for Firebase messaging notifications')
  } catch (error) {
    console.log('Error registering the device for Firebase messaging notifications: ', error)
  }
}

const unregisterDeviceFromNotifications = async (token) => {
  try {
    const protocol = 'https'
    const host = `us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net`
    const path = '/notifications-unsubscribeFromNotifications'
    const queryParams = `iid=${token}`
    const url = `${protocol}://${host}${path}?${queryParams}`

    await fetch(url, {mode: 'no-cors'})
    console.log('Device unregistered from Firebase messaging notifications')
  } catch (error) {
    console.log('Error unregistering the device from  Firebase messaging notifications: ', error)
  }
}

const setUpMessageListener = () => {
  onMessage(getMessaging(), payload => {
    console.log('Message received: ', payload)

    if (payload?.data?.orderId) {
      console.log('auth current user: ', getAuth().currentUser)

      const openIDBRequest = indexedDB.open('mandux-t-db', '1')
      openIDBRequest.onsuccess = event => {
        const db = event.target.result

        const transaction = db.transaction(['deliveryGuy'], 'readonly')
        transaction.onsuccess = e => { console.log('transaction completed successfully') }
        transaction.onerror = e => { console.log('transaction failed') }

        const objectStore = transaction.objectStore('deliveryGuy')

        const IDBRequest = objectStore.get(getAuth().currentUser.uid)
        IDBRequest.onsuccess = async (e) => {
          const orderRef = doc(getFirestore(), 'orders', payload.data.orderId)
          const updateObject = {
            deliveryGuysLocations: {
              [`_${e.target.result.dgId}`]: {
                coords: {
                  lat: e.target.result.coords.lat,
                  lng: e.target.result.coords.lng
                }
              }
          }}
          await setDoc(orderRef, updateObject, {merge: true})
          console.log('firestore updated with the last location')
        }
        IDBRequest.onerror = e => { console.log('db update failed') }
      }
      openIDBRequest.onerror = e => console.log('db open failed')

    } else if (payload?.notification) {
      navigator
        .serviceWorker
        .getRegistration('/firebase-cloud-messaging-push-scope')
        .then(registration => {
          const options = {
                            body: payload.notification.body,
                            icon: process.env.PUBLIC_URL + '/img/icons/gen/apple-icon-180.png',
                            vibrate: [100,100,500],
                            badge: process.env.PUBLIC_URL + '/img/icons/badge_tr_96x96.png',
                            requireInteraction: true
                          }

          registration.showNotification(payload.notification.title, options)
      })
    }
  })
}

export {
  setUpNotifications,
  resetNotifications,
  areNotificationsGranted,
  firebaseMessagingToken
}
