import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import ProductCards from './ProductCards'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {useLocation} from 'react-router-dom'
import BusinessCard from 'components/account/companies/business-card/BusinessCard'
import ShoppingCartButton from 'components/shopping-cart/ShoppingCartButton'
import {useState, useEffect} from 'react'
import algoliasearch from 'algoliasearch'

import Style from './_CompanyOverview.module.sass'

const CompanyOverview = () => {
  const location = useLocation()

  const searchClient = algoliasearch(
                          process.env.REACT_APP_ALGOLIA_APP_ID, 
                          process.env.REACT_APP_ALGOLIA_SEARCH_KEY)

  const companiesIndex = searchClient.initIndex('companies')

  const [company, setCompany] = useState(location.state?.company)
  const [from, setFrom] = useState(location.state?.from || '')

  useEffect(() => {
    if (company) 
      return

    const companyId = location.pathname.split('/')[2]
    companiesIndex
      .getObject(companyId)
      .then(object => {
        setCompany(object)
        setFrom('/')
      })
  }, [company, location.pathname, companiesIndex])

  return <div>
            <TitleBar>
              <h4 className={Style.title}>Detalle de Productos</h4>
              <div style={{position: 'absolute', right: '0px'}}>
                <ShoppingCartButton />
              </div>
            </TitleBar>
            <Content>
              {
                company &&
                <>
                  <BusinessCard hit={company} />
                  <ProductCards company={company}/>
                </>
              }
            </Content>
            <FloatingButton
              position='bottom-left'
              to={from}>
              <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
    </div>
};

export default CompanyOverview;
