import {FormControl, FormGroup, FormControlLabel, InputLabel, Select, MenuItem, Switch, makeStyles} from '@material-ui/core';
import {Controller, useFormContext} from 'react-hook-form'
import {useState} from 'react'

import Style from './_OpenHoursDay.module.sass'

const useStyles = makeStyles({
  root: {
    alignItems: 'center'
  }
})

const OpenHoursDay = ({dayOfWeek}) => {
  const classes = useStyles()
  const {control, getValues} = useFormContext()

  const getHours = () => {
    const  hours = []

    for (let i = 7; i < 23; i++) {
      hours.push(`${i}:00`.padStart(5,'0'))
      hours.push(`${i}:30`.padStart(5,'0'))
    }
    hours.push(`23:00`)

    return hours
  }

  const [dayOfWeekOpen, setDayOfWeekOpen] = useState(getValues(`openHours.${dayOfWeek}.enabled`) || false)
  const [invalidHoursError, setInvalidHoursError] = useState(false)

  const areDayHoursValid = (from, to) => {
    if (!(from && to)) {
      setInvalidHoursError(true)
      return false
    }

    const fromHoursStrNum = from.replace(':', '')
    const toHoursStrNum = to.replace(':', '')

    const fromHoursNum = parseInt(fromHoursStrNum)
    const toHoursNum = parseInt(toHoursStrNum)

    const areHoursValid = fromHoursNum < toHoursNum

    setInvalidHoursError(!areHoursValid)
    return areHoursValid
  }

  return (
    <div>
      <div className={Style.general}>
        <div>
          <FormGroup column='true' classes={{root: classes.root}}>
            <Controller control={control} 
                        name={`openHours.${dayOfWeek}.enabled`} 
                        defaultValue={dayOfWeekOpen}
                        rules={{
                          validate: value => {
                            let isValid = false

                            if (value) {
                              isValid = areDayHoursValid(
                                          getValues(`openHours.${dayOfWeek}.from`), 
                                          getValues(`openHours.${dayOfWeek}.to`))
                            } else {
                              isValid = true
                            }

                            return isValid || `Revisar horas de servicio de ${dayOfWeek}`
                          }
                        }}
                        render={({field})=>(
                          <FormControlLabel 
                            label={dayOfWeek} 
                            labelPlacement='start'
                            control={<Switch 
                                      {...field}
                                      checked={field.value || false}
                                      onChange={(e)=>{
                                        setDayOfWeekOpen(e.target.checked);
                                        field.onChange(e);
                                      }}
                                       color='primary' 
                                       />
                             } />
                        )} />
          </FormGroup>
        </div>

        <div>
          <FormControl>
            <InputLabel id='from-label'>De:</InputLabel>
            <Controller 
              control={control} 
              name={`openHours.${dayOfWeek}.from`}
              rules={{
                required: 'La hora de apertura es necesaria'
              }}
              defaultValue='07:00'
              render={({field, fieldState}) => 
                <Select {...field} 
                  labelId='from-label' 
                  value={field.value ?? '07:00'} 
                  autoWidth
                  disabled={dayOfWeekOpen ? false : true} 
                  error={invalidHoursError}
                  onChange={e => {
                    areDayHoursValid(e.target.value, getValues(`openHours.${dayOfWeek}.to`));
                    field.onChange(e)}}
                  >
                {
                  getHours().map(hour => {
                    return <MenuItem value={hour} key={hour}>{hour}</MenuItem>
                  })
                  
                }
                </Select>
            }/>
          </FormControl>
        </div>

        <div>
          <FormControl >
            <InputLabel id='to-label'>A:</InputLabel>
            <Controller 
              control={control} 
              name={`openHours.${dayOfWeek}.to`} 
              rules={{
                required: 'La hora de cierre es necesaria'
              }}
              defaultValue='23:00'
              render={({field, fieldState}) => 
                <Select {...field} 
                  labelId='to-label' 
                  value={field.value ?? '23:00'} 
                  autoWidth
                  disabled={dayOfWeekOpen ? false : true} 
                  error={invalidHoursError}
                  onChange={e => {
                    areDayHoursValid(getValues(`openHours.${dayOfWeek}.from`),e.target.value);
                    field.onChange(e)}}
                >
                {
                  getHours().map(hour => {
                    return <MenuItem value={hour} key={hour}>{hour}</MenuItem>
                  })
                  
                }
                </Select>
            }/>
          </FormControl>
        </div>
      </div>
      {invalidHoursError && dayOfWeekOpen &&
      <div className={Style.error}>
        La hora de apertura debe ser antes de la hora de cierre
      </div>
      }
    </div>
  )
};

export default OpenHoursDay;
