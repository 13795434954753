import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import BusinessCard from 'components/account/companies/business-card/authorization/AuthorizationBusinessCard'
import FloatingButton from 'components/common/FloatingButton'
import {AiOutlineHome} from 'react-icons/ai'

import Style from './_CompanyAuthorization.module.sass'

import {useState, useEffect} from 'react'
import {listenForCompanies} from './utils'

const CompanyAuthorization = () => {

  const [companies, setCompanies] = useState({authorized: [], unauthorized: []})

  useEffect(() => {
    const detachListener = listenForCompanies(setCompanies)
    return detachListener
  }, [setCompanies])

  return (
    <div>
      <TitleBar>
        <h4 className={Style.title}>Negocios</h4>
      </TitleBar>
      <Content>
        <div className={Style.cards}>
          {
            companies?.authorized?.length > 0 &&
            <div>
              <div className={Style.section}>
                Negocios Autorizados
              </div>
              <div>
                {
                  companies.authorized.map(company => (
                    <BusinessCard key={company.id} hit={company} />
                  ))
                }
              </div>
            </div>
          }
          {
            companies?.unauthorized?.length > 0 &&
            <div>
              <div className={Style.section}>
                Negocios No Autorizados
              </div>
              <div>
                {
                  companies.unauthorized.map(company => (
                    <BusinessCard key={company.id} hit={company} />
                  ))
                }
              </div>
            </div>
          }
        </div>
        <FloatingButton
          position='bottom-right'
          to='/'>
          <AiOutlineHome style={{fill: 'white'}} size='26px' />
        </FloatingButton>
      </Content>
    </div>
  )
}

export default CompanyAuthorization
