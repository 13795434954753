import Style from './_Tiktok.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import {IoLogoTiktok} from 'react-icons/io5';

const Tiktok = () => {

  const methods = useFormContext();
  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Tiktok</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <Controller 
                control={methods.control}
                name='links.tiktok.username'
                defaultValue={methods.getValues('links.tiktok.username')}
                rules={{
                    pattern: {
                      value: /@.+/,
                      message: 'El usuario no es valido'
                    }
                }}
                render={({field})=>(
                  <TextField 
                    label='Usuario de Tiktok' 
                    placeholder='@miUsuario'
                    type='text'
                    value={field.value}
                    onChange={field.onChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <IoLogoTiktok />
                        </InputAdornment>
                      )
                    }}/> 
                )}
              />
            </div> 
          </div>
        </div>
}

export default Tiktok;
