import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {useLocation} from 'react-router-dom'
import ProductCard from 'components/account/companies/products/product-card/MonitoringProductCard'
import {SubOrderStatus} from '../suborders/order-utils'
import {useState, useEffect} from 'react'
import {listenForOrderChanges, listenForSubOrderChanges} from './delivery-utils'

import Style from './_SubOrder.module.sass'

const DeliverySubOrderView = () => {
  const location = useLocation()
  const [subOrder, setSubOrder] = useState(location?.state?.subOrder)
  const [order, setOrder] = useState(location?.state?.order)

  useEffect(() => {
    const detachListener = listenForOrderChanges(order.id, setOrder)
    return detachListener
  }, [order.id])

  useEffect(() => {
    const detachListener = listenForSubOrderChanges(subOrder.id, setSubOrder)
    return detachListener
  }, [subOrder.id])

  const getSubOrderStatusText = (status) => {
  let newStatus = ''

  switch(status) {
    case SubOrderStatus.NEW:
      newStatus = 'PENDIENTE'
      break
    case SubOrderStatus.IN_PREPARATION:
      newStatus = 'EN PREPARACION'
      break
    case SubOrderStatus.READY_FOR_PICKUP:
      newStatus = 'LISTO PARA RECOLECCION'
      break
    case SubOrderStatus.PICKED_UP:
      newStatus = 'RECOLECTADO'
      break
    case SubOrderStatus.DELIVERED:
      newStatus = 'ENTREGADO'
      break
    default:
      break
    }

    return newStatus
  }


  return <div>
            <TitleBar>
              <h4 className={Style.title}>Detalle de pedido</h4>
            </TitleBar>
            <Content>
              <div>
                <div className={Style.companyName}>
                  { subOrder.companyName}: { subOrder.timestamp.seconds}
                </div>
                <div>
                  {
                     subOrder.products.map(product => {
                      return <ProductCard key={product.product.id} productType={product} />
                    })
                  }
                </div>
                <div className={Style.statusButtonDiv}>
                  <div className={Style.orderTotal}>
                    Total: { subOrder.subTotalPerCompany}
                    <div className={Style.tipoMoneda}> MXN </div>
                  </div>
                  <div className={Style.status}>
                    {getSubOrderStatusText(subOrder.status)}
                  </div>
                </div>
              </div>
            </Content>
            <FloatingButton
              position='bottom-left'
              to={`/admin/monitoring/order/${ subOrder.orderId}`}
              stateParam={{order: order, subOrder: subOrder}}>
              <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
    </div>
};

export default DeliverySubOrderView ;
