import {getFirestore, collectionGroup, query, onSnapshot} from 'firebase/firestore'

const listenForCompanies = (setCompanies) => {
  const q = query(collectionGroup(getFirestore(), 'companies'))
  const detachListener = onSnapshot(q, snapshot => {
                            const companies = {
                              authorized: [],
                              unauthorized: []
                            }

                            snapshot.forEach(companySS => {
                              const company = companySS.data()
                              company.id = companySS.id

                              if (company.authorized)
                                companies.authorized.push(company)
                              else 
                                companies.unauthorized.push(company)
                            })

                            setCompanies(companies)
                          })

  return detachListener
}

export {listenForCompanies}
