import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import {useEffect, useState} from 'react'
import OrderCard from './OrderCard'
import {listenForIncomingOrders} from './delivery-utils'
import FloatingButton from 'components/common/FloatingButton'
import {AiOutlineHome} from 'react-icons/ai'

import Style from './_Monitoring.module.sass'

const Monitoring = () => {
  const [incomingOrders, setIncomingOrders] = useState({newOrders: [], 
                                                ordersInProgress: [], 
                                                ordersOnItsWay: [], 
                                                ordersDelivered: []})

  useEffect(() => {
    const detachListener = listenForIncomingOrders(setIncomingOrders)
    return detachListener
  }, [])

  return (
    <div>
      <TitleBar>
        <h4 className={Style.title}>Pedidos</h4>
      </TitleBar>
      <Content>
      {
        incomingOrders?.newOrders?.length > 0 &&
        <div>
          <div className={Style.section}>
            Entrega Activa:
          </div>
          <div>
            {
              incomingOrders.newOrders.map(order => {
                return <OrderCard key={order.id} order={order} backgroundColor='darkred'/>
              })
            }
          </div>
        </div>
      }
      {
        incomingOrders?.ordersInProgress?.length > 0 &&
        <div>
          <div className={Style.section}>
            Entrega en recoleccion:
          </div>
          <div>
            {
              incomingOrders.ordersInProgress.map(order => {
                return <OrderCard key={order.id} order={order} backgroundColor='darkorange'/>
              })
            }
          </div>
        </div>
      }
      {
        incomingOrders?.ordersOnItsWay?.length > 0 &&
        <div>
          <div className={Style.section}>
            Entrega en camino:
          </div>
          <div>
            {
              incomingOrders.ordersOnItsWay.map(order => {
                return <OrderCard key={order.id} order={order} backgroundColor='darkgreen'/>
              })
            }
          </div>
        </div>
      }
      {
        incomingOrders?.ordersDelivered?.length > 0 &&
        <div>
          <div className={Style.section}>
            Entregas terminadas:
          </div>
          <div>
            {
              incomingOrders.ordersDelivered.map(order => {
                return <OrderCard key={order.id} order={order} backgroundColor='darkgrey' />
              })
            }
          </div>
        </div>
      }
      <FloatingButton
        position='bottom-right'
        to='/'>
        <AiOutlineHome style={{fill: 'white'}} size='26px' />
      </FloatingButton>
      </Content>
    </div>
  )
};

export default Monitoring;
