import {getAuth} from 'firebase/auth'
import {getResizedImages, getPictureExtension} from 'components/common/picture-utils'
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {getFirestore, collection, doc, setDoc, increment, getDoc} from 'firebase/firestore'

const registerProduct = async (companyId, record) => {
  const userId = getAuth().currentUser.uid
  const firestore = getFirestore()

  const productsCollection = collection(firestore, `users/${userId}/companies/${companyId}/products`)
  var newProductRef = doc(productsCollection)

  const newPicture = record.picture[0]

  delete record.picture

  record['picture'] = {original: newProductRef.path + '/original' + getPictureExtension(newPicture.name)}
  record.picture.small = newProductRef.path + '/small' + getPictureExtension(newPicture.name) 
  record.picture.medium = newProductRef.path + '/medium' + getPictureExtension(newPicture.name)

  const resizedImages = getResizedImages(newPicture)
  console.log(resizedImages)

  try {
    const storage = getStorage()

    var pictureRef = ref(storage, record.picture.original)
    await uploadBytes(pictureRef, newPicture);
    record.picture.original = await getDownloadURL(pictureRef)
    console.log('original picture saved!')

    var smallPictureRef = ref(storage, record.picture.small)
    await uploadBytes(smallPictureRef, resizedImages.small)
    record.picture.small = await getDownloadURL(smallPictureRef)
    console.log('small picture saved!')

    var mediumPictureRef = ref(storage, record.picture.medium)
    await uploadBytes(mediumPictureRef, resizedImages.medium)
    record.picture.medium = await getDownloadURL(mediumPictureRef)
    console.log('medium picture saved!')

    console.log('Updating the product count')
    const bizRef = doc(firestore, `users/${userId}/companies/${companyId}`)

    await setDoc(bizRef, {ps_count: increment(1)}, {merge: true})

    const bizSS = await getDoc(bizRef)
    const bizData = bizSS.data()
    record.authorized = bizData.authorized
    record.ownerId = bizData.ownerId
    record.companyId = bizSS.id
    record.openHours = bizData.openHours

    await setDoc(newProductRef, record)
    console.log('doc saved!')

  } catch (e){
    console.log(e);
  }
}

export {registerProduct};
