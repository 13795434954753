import Style from './_CompanyCard.module.sass'
import {useState, useEffect} from 'react'
import {FaDirections} from 'react-icons/fa'
import {getCurrentLocation} from 'components/common/map/location-utils'
import {useNavigate} from 'react-router-dom'

const CompanyCard = ({order, subOrder, statusColor, stopInfo}) => {
  const navigate = useNavigate()
  const [currentLocation, setCurrentLocation] = useState('')

  useEffect(() => {
    getCurrentLocation((point, args) => {
      setCurrentLocation(`origin=${point.lat},${point.lng}&`)
    })
  })

  return (
    <div className={Style.stop}>
      <div className={Style.stopNumber} style={{backgroundColor: statusColor}}>
        {stopInfo.stopNumber}
      </div>
      <div className={Style.companyCard}
            onClick={() => subOrder.id && navigate(`/admin/monitoring/order/${subOrder.orderId}/suborder/${subOrder.id}`, {state: {order, subOrder, stopInfo}})}>
        <div className={Style.nameAndType}>
          <p className={Style.name}>
            {subOrder.companyName} 
          </p>
          <p className={Style.type}>
            {subOrder.companyType}
          </p>
        </div>
        <p className={Style.id}>
          id: {subOrder.timestamp.seconds} 
        </p>
        <div className={Style.directions} style={{right: subOrder.productCountPerCompany ? '88px' : '15px'}}>
          <a href={`https://www.google.com/maps/dir/?api=1&${currentLocation}destination=${subOrder.companyLocation.lat},${subOrder.companyLocation.lng}`}>
            <FaDirections size='33px'/>
          </a>
        </div>
        {
          subOrder.productCountPerCompany &&
            <div className={Style.count}>
              <p className={Style.productCount}>
                {subOrder.productCountPerCompany}
              </p>
              <p className={Style.countLabel}>
                Productos
              </p>
            </div>
        }
      </div>
    </div>
  )
}

export default CompanyCard
