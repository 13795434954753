
const Role = {
  CLIENT: 'client',
  OWNER: 'owner',
  DELIVERY_GUY: 'delivery_guy',
  ADMIN: 'admin'
}

const initialState = {
  user: {
    id: '',
    name: '',
    email: '',
    phoneNumber: '',
    pictureUrl: '',
    isAnonymous: true,
    isAdmin: false,
    role: Role.CLIENT,
    devices: {
      deviceName: '',
      msgToken: '',
      lastSignedIn: ''
    },
    client: {
      clientId: '',
      paymentMethods: {},
      locations: {}
    },
    owner: {
      ownerId: '',
      companies: {}
    },
    deliveryGuy: {
      employeeId: '',
      employeeSince: '',
      employeeUntil: '',
      isOnCall: false,
      lastSeen: {
        coords: {
          lat: 0, 
          lng: 0
        },
        timestamp: ''
      },
      workingHours: {
        start: 9,
        end: 5,
        breakAt: 12,
        breakMinutes: 40
      },
      workingDays: {
        0: true, // sunday
        1: true, // monday 
        2: true, // tuesday
        3: true, // wednesday
        4: true, // thursday
        5: true, // friday
        6: true, // saturday
      }
    }
  }
}
  

export {initialState, Role}


