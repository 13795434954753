import Style from "./_TitleBar.module.sass";
import Menu from './Menu' 
const TitleBar = (props) => {

  return  <div className={Style.TitleBar}>
            <Menu />
            {props.children}
          </div>
};

export default TitleBar;
