export const formLinks = [{
    name: 'call',
    label: 'Llamada',
  },{
    name: 'sms',
    label: 'SMS',
  },{
    name: 'telegram',
    label: 'Telegram',
  },{
    name: 'whatsapp',
    label: 'Whatsapp',
  },{
    name: 'facebook',
    label: 'Facebook',
  },{
    name: 'twitter',
    label: 'Twitter',
  },{
    name: 'instagram',
    label: 'Instagram',
  },{
    name: 'tiktok',
    label: 'Tiktok',
  },{
    name: 'youtube',
    label: 'Youtube',
  },{
    name: 'website',
    label: 'Sitio Web',
  }];
