
import {createSlice} from '@reduxjs/toolkit';

const backdropSlice = createSlice({
  name: 'backdropSlice',
  initialState: {
    value: false
  },
  reducers: {
    turnOn: (state) => { state.value = true},
    turnOff: (state) => { state.value = false}
  }
});

export default backdropSlice.reducer;
export const {turnOn, turnOff} = backdropSlice.actions;
