import {onLoad, changeImage} from 'components/common/picture-utils';
import ProductImg from '../box.svg';
import {TextField, InputAdornment} from '@material-ui/core';

import {useFormContext, Controller} from 'react-hook-form';

import Style from './_Identity.module.sass';

import {validatePriceNumber, formatPriceInput, resetCursor} from '../form-utils'

const Identity = () => {
  const {register, control, setValue} = useFormContext();

  const priceInput = register('price', {
    value: '0.00',
    required: 'El precio del producto es necesario'
  })
  return  <div className={Style.general}>
            <div className={Style.stepName}>
              <h4>Informacion General</h4>
            </div>
              
            <div id="picture" className={Style.bizImg}>
              <div id="picture-circle" className={Style.pictureCircle}>
                <div id="picture-container" className={Style.pictureContainer}>
                  <img id="photo"
                       src={ProductImg}
                       onClick={()=>document.getElementById('photo-picker').click()}
                       onLoad={(e)=>{onLoad(e, 170)}}
                       alt='Foto de negocio' />
                  <Controller 
                    control={control}
                    name='picture'
                    rules={{required: 'La imagen (o logo) obligatoria'}}
                    render={({field})=>(
                          <input id="photo-picker"
                                 type="file"
                                 accept="image/*"
                                 hidden 
                                 onChange={(event)=>{
                                     setValue('picture', event.target.files)
                                     changeImage(event);
                                   }
                                 } />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={Style.info}>
              <div className={Style.fields}>
                <TextField id='name' 
                            label='Nombre'
                            autoComplete='off'
                            {...register('name', {
                              required: 'El nombre del producto es necesario'
                            })}/>


                <TextField id='description' 
                            label='Descripcion'
                            multiline
                            maxRows={3}
                            minRows={3}
                            {...register('description', {
                              required: 'La descripcion del negocio es necesaria'
                            })}/>

                <TextField id='price' 
                            label='Precio'
                            type='tel'
                            InputProps={{
                              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                              endAdornment: <InputAdornment position='end'>MXN</InputAdornment>
                            }}
                            defaultValue='0.00'
                            onKeyDown={(e)=>validatePriceNumber(e, setValue, priceInput.name)}
                            onInput={(e)=>formatPriceInput(e, setValue, priceInput.name)}
                            onClick={resetCursor}
                            inputRef={priceInput.ref}
                            {...register('price', {
                            })}/>


              </div>
            </div>

          </div>
};

export default Identity;
