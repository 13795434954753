import Style from './_FloatingButton.module.sass';
import {Link} from 'react-router-dom';

const FloatingButton = ({children, to, position, stateParam={}, onClick}) => {
  let pos = '';

  if (position === 'bottom-right')
    pos = Style.bottomRight;
  else if (position === 'bottom-left')
    pos = Style.bottomLeft;

  if (onClick) {
  return (
    <div className={pos} onClick={onClick}>
      {children}
    </div>
  )
  } else {
  return (
    <div className={pos}>
       <Link to={to} state={stateParam} replace={['/', '/companies', '/products'].includes(to)}>
        {children}
       </Link>
    </div>
  )
  }
}

export default FloatingButton;
