import {turnOn} from 'redux/app/backdropSlice'
import {useDispatch} from 'react-redux'
import {DevTool} from '@hookform/devtools'
import {Controller} from 'react-hook-form'

import {
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  TextField, 
  Button
} from '@material-ui/core'

import {useForm, useFormState} from 'react-hook-form'
//import Style from './_PhoneCodeModal.module.sass'

const PhoneCodeModal = ({show, setShow, verifyCodeCB}) => {
	const dispatch = useDispatch()
  const {handleSubmit, control, setValue, clearErrors, setError} = useForm()
  const {errors} = useFormState({control})

  const onSubmit = async (data) => {
		try {
			dispatch(turnOn())
			const phoneCode = data['phoneCode']
			console.log(phoneCode)

			await verifyCodeCB(phoneCode)
			//setShow(false)
		}	catch (e) {
			setError('phoneCode', {name: 'custom', message: 'Codigo incorrecto'})
		}
  }

	const onCancel = () => {
		clearErrors()
		setValue('phoneCode', '')
		setShow(false)
	}

  return (
		<Dialog open={show}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle>
					Codigo enviado por SMS
				</DialogTitle>
				<DialogContent>
					<DialogContentText component='span'>
						<Controller 
							control={control}
							name='phoneCode'
							rules={{
								required: {
									value: true,
									message: 'Codigo requerido'
								},
								pattern: {
									value: /\d{6}/,
									message: '6 digitos requeridos'
								}
							}}
							render={({field}) => (
								<TextField 
									size='small'
									label="Codigo"
									autoFocus
								  onChange={field.onChange}
								  onBlur={field.onBlur}
									inputProps={{
										maxLength: 6
									}}
								/>
							)}
						/>
						{ errors['phoneCode'] && 
							<p style={{color: 'red', fontSize: '.8rem'}}>{errors['phoneCode'].message}</p> }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button 
						variant='contained' 
						color='primary'
						size='small'
						onClick={()=>onCancel()}
						>
						Cancelar
					</Button>
					<Button 
						type='submit'
						variant='contained' 
						color='primary'
						size='small'
					>
						Aceptar
					</Button>
				</DialogActions>
			</form>
			<DevTool control={control}/>
		</Dialog>
  );
}

export default PhoneCodeModal 
