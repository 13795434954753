import TitleBar from 'components/common/TitleBar';
import Content from 'components/common/Content';
import FloatingButton from 'components/common/FloatingButton';
import {useLocation, useNavigate} from 'react-router'
import {useLayoutEffect, useState} from 'react'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {FaCheckCircle, FaTimesCircle} from 'react-icons/fa'
import {AiOutlineHome} from 'react-icons/ai'
import {GiSandsOfTime} from 'react-icons/gi'
import {Button} from '@material-ui/core'
import {populatePendingShoppingCart} from 'redux/app/shopping-cart/shoppingCartSlice'
import {useDispatch} from 'react-redux'

import Style from './_PaymentStatus.module.sass';

const PaymentStatus  = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const queryString = location.search
  const queryStringWoQm = queryString.slice(1)
  const queryParameterArray = queryStringWoQm.split('&')
  const trIdParam = queryParameterArray.find(e => e.startsWith('id='))
  const trId = trIdParam.split('=')[1]
  
  const [paymentStatus, setPaymentStatus] = useState('in_progress')

  useLayoutEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async user => {
      if (!user)
        return

      const endpoint = `${process.env.REACT_APP_CHECK_PAYMENT_STATUS_ENDPOINT}`

      try {
        const res = await fetch(`${endpoint}?trId=${trId}&uid=${user.uid}`, {method: 'GET', mode: 'cors'})

        if (!res.ok) {
          console.log('error response')
          const error = await res.json()
          console.log('error_res: ', error)
          setPaymentStatus('error_res')
          dispatch(populatePendingShoppingCart(user.uid))
        } else {
          const data = await res.json()
          console.log(data)
          setPaymentStatus(data.status)
        }
      } catch (e){
        console.log(e)
        setPaymentStatus('comms_error')
        dispatch(populatePendingShoppingCart(user.uid))
      }

    })

    return () => unsubscribe()
  }, [trId, dispatch])

  return  <div>
            <TitleBar>
              <h4 className={Style.title}>Estado de pago</h4>
            </TitleBar>
            <Content>
            <div className={Style.status}>
                {
                  paymentStatus === "completed" && 
                  <div className={Style.success}>
                    <p>Pago EXITOSO</p>

                    <p>   
                      <FaCheckCircle color="green" size="80px"/> 
                    </p>

                    <p className={Style.thanks}>Gracias por tu compra!</p>
                    <p className={Style.text}>Puedes ver las actualizaciones del envio en la seccion de Pedidos</p>
                    <p className={Style.text}>El tiempo estimado de entrega puede variar debido a los tiempos de preparacion de los productos seleccionados</p>

                    <p>
                      <Button color="primary" variant='contained' onClick={()=>navigate('/client/orders')}>Ir a mis pedidos</Button>
                    </p>
                  </div>
                }
                {
                  paymentStatus === "failed" && 
                  <div className={Style.failure}>
                    <p>Pago NO EXITOSO</p>

                    <p>   
                      <FaTimesCircle color="red" size="80px"/> 
                    </p>

                    <p className={Style.thanks}>El pago no pudo ser procesado.</p>
                    <p className={Style.text}>Por favor revise su informacion o intente mas tarde.</p>

                    <p>
                      <Button color="primary" variant='contained' onClick={()=>navigate('/shoppingcart')}>Ir a mi carrito</Button>
                    </p>
                  </div>
                }
                {
                  paymentStatus === "in_progress" && 
                  <div className={Style.inProgress}>
                    <p>En progreso ...</p>

                    <p>   
                      <GiSandsOfTime color="orange" size="80px"/> 
                    </p>

                    <p className={Style.thanks}>Esperando respuesta de la operacion.</p>
                    <p className={Style.text}>Por favor espere un momento para recibir la respuesta de la operacion.</p>

                  </div>
                }

            </div>


            </Content>
            <FloatingButton
              position='bottom-right'
              to='/'>
              <AiOutlineHome style={{fill: 'white'}} size='25px'/>
            </FloatingButton>
              
          </div>
};

export default PaymentStatus;
