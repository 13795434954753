import Style from './_CompanySubOrdersCard.module.sass'
import {useNavigate} from 'react-router-dom'

const CompanySubOrdersCard = ({company}) => {
  const navigate = useNavigate()
  return (
    <div className={Style.companyOrdersCard}
         onClick={() => navigate(`/suborders/company/${company.companyId}`, {state: {company}})}>
      <div className={Style.name}>
        <p>{company.companyName}</p>
      </div>
      {
        company.newSubOrders.length > 0 &&
      <div className={Style.newOrderNumber}>
        <div className={Style.new}>
          {company.newSubOrders.length}
        </div>
        <p>Pedidos nuevos</p>
      </div>
      }
      {
        company.subOrdersInPreparation.length > 0 &&
      <div className={Style.inPreparationOrderNumber}>
        <div className={Style.inPreparation}>
          {company.subOrdersInPreparation.length}
        </div>
        <p>Pedidos en preparacion</p>
      </div>
      }
      { 
        company.subOrdersReadyForPickup.length > 0 &&
       <div className={Style.readyForPickupOrderNumber}>
        <div className={Style.readyForPickup}>
          {company.subOrdersReadyForPickup.length}
        </div>
        <p>Pedidos listos para ser recogidos</p>
      </div>
      }

    </div>
  )
}

export default CompanySubOrdersCard
