import Style from './_AuthorizationBusinessCard.module.sass'
import PictureAndInfo from './PictureAndInfo'
import Links from '../Links'
import TopBar from '../TopBar'

const BusinessCard = ({hit}) => {
  //console.log(hit);

  const links = {
    location: hit?.location, 
    call: hit?.links?.call?.number, 
    sms: hit?.links?.sms?.number, 
    telegram: hit?.links?.telegram?.number,
    whatsapp: hit?.links?.whatsapp?.number,
    facebook: hit?.links?.facebook?.link, 
    twitter: hit?.links?.twitter?.handle, 
    instagram: hit?.links?.instagram?.username, 
    tiktok: hit?.links?.tiktok?.username, 
    youtube: hit?.links?.youtube?.channel, 
    website: hit?.links?.website?.link};

  //const company = {
    //id: hit.objectID,
    //picture: hit.picture,
    //name: hit.name, 
    //type: hit.type, 
    //description: hit.description, 
    //hasDelivery: hit.hasDelivery, 
    //ps_count: hit.ps_count,
    //links : links
  //};
  return  <div id="c1" className={Style.card}>
            <TopBar company={hit} />
            <PictureAndInfo company={hit}/> 
            <Links links={links}/>
          </div>
};

export default BusinessCard;
