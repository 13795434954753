import { BrowserRouter, Routes, Route} from 'react-router-dom';

import './_index.sass';
import SearchEngine from './search-engine/SearchEngine';
import CompanyOverview from './account/companies/company-overview/CompanyOverview';

import BusinessManagement from './account/companies/management/BusinessManagement';
import ShoppingCart from './shopping-cart/ShoppingCart';
import BusinessForm from './account/companies/management/add/BusinessForm';
import UpdateBusinessForm from './account/companies/management/update/UpdateBusinessForm';
import ProductManagement from './account/companies/products/management/ProductManagement'
import ProductForm from './account/companies/products/management/add/ProductForm'
import UpdateProductForm from './account/companies/products/management/update/UpdateProductForm'
import Payment from './payment/Payment'
import PaymentResult from './payment/PaymentResult'
import Backdrop from './common/Backdrop'
import Zoom from './common/Zoom'
import ShoppingCartInformative from './shopping-cart/ShoppingCartInformative'
import ShoppingCartButton from './shopping-cart/ShoppingCartButton'
import SubOrders from './suborders/SubOrders'
import CompanySubOrders from './suborders/CompanySubOrders'
import SubOrder from './suborders/SubOrder'
import DeliveryView from './delivery/DeliveryView'
import DeliveryOrderView from './delivery/DeliveryOrderView'
import DeliverySubOrderView from './delivery/DeliverySubOrderView'
import ClientOrders from 'components/client-orders/ClientOrders'
import ClientOrder from 'components/client-orders/ClientOrder'
//import CreditCard from 'components/payment/CreditCard'
import PaymentStatus from 'components/payment/PaymentStatus'
import About from 'components/common/About'
import Terms from 'components/common/Terms'
import Privacy from 'components/common/Privacy'

import Monitoring from './monitoring/Monitoring'
import MonitoringOrder from './monitoring/Order'
import MonitoringSubOrder from './monitoring/SubOrder'

import CompanyAuthorization from './company-authorization/CompanyAuthorization'

import Authentication from './account/Authentication';
import AuthenticationManagement from './common/AuthenticationManagement';
import ProtectedRoute from 'components/common/ProtectedRoute'

import {useSelector} from 'react-redux'
import DeliveryGuyTracker from './delivery/DeliveryGuyTracker'

const App = () => {
  const backdropValue = useSelector(state => state.backdrop.value)
  const isAnonymousUser = useSelector(state => state.auth.user.isAnonymous) 
  const hiredSince = useSelector(state => state.auth.user.deliveryGuy.employeeSince)
  const hiredUntil = useSelector(state => state.auth.user.deliveryGuy.employeeUntil)

  return (
    <div>
      <AuthenticationManagement />
      {
        hiredSince && !hiredUntil && !isAnonymousUser &&
        <DeliveryGuyTracker />
      }
      
      <BrowserRouter>
        <Routes>
          <Route path="/account/auth" element={<Authentication />} />
          <Route path='' element={<SearchEngine />} />
          <Route path='/' element={<SearchEngine />} />
          <Route path='/products' element={<SearchEngine />} />
          <Route path='/companies' element={<SearchEngine />} />
          <Route path="/company/:companyId" element={<CompanyOverview />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/account/companies" element={<BusinessManagement />} />
            <Route path="/account/companies/add/:step" element={<BusinessForm />} />
            <Route path="/account/companies/:companyId/update/:step" element={<UpdateBusinessForm />} />
            <Route path="/account/companies/:companyId/products" element={<ProductManagement />} />
            <Route path="/account/companies/:companyId/products/add" element={<ProductForm />} />
            <Route path="/account/companies/:companyId/products/:productId/update" element={<UpdateProductForm />} />

            <Route path="/suborders" element={<SubOrders />} />
            <Route path="/suborders/company/:companyId" element={<CompanySubOrders />} />
            <Route path="/suborders/company/:companyId/suborder/:subOrderId" element={<SubOrder />} />
            <Route path="/delivery" element={<DeliveryView />} />
            <Route path="/delivery/order/:orderId" element={<DeliveryOrderView />} />
            <Route path="/delivery/order/:orderId/suborder/:suborder" element={<DeliverySubOrderView />} />

            <Route path="/admin/monitoring" element={<Monitoring />} />
            <Route path="/admin/monitoring/order/:orderId" element={<MonitoringOrder />} />
            <Route path="/admin/monitoring/order/:orderId/suborder/:suborder" element={<MonitoringSubOrder />} />
            <Route path="/admin/authorization" element={<CompanyAuthorization />} />
          </Route>

          <Route path="/shoppingcart" element={<ShoppingCart />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment/result" element={<PaymentResult />} />

          <Route path="/client/orders" element={<ClientOrders />} />
          <Route path="/client/order/:orderId" element={<ClientOrder />} />

          {/*<Route path="/cc" element={<CreditCard />} /> */}
          <Route path="/paymentStatus" element={<PaymentStatus />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
        <Zoom />
        <Backdrop enabled={backdropValue}/>
        <ShoppingCartInformative />
        <ShoppingCartButton />
      </BrowserRouter>
    </div>
  )
}

export default App
