import concepts from "../concepts.js"

const getHRCostPerMin = () => {
  const minimumWage = concepts.admon.hr.wage
  const healthInsurance = concepts.admon.hr.healthInsurance
  const biMoRetirementAndHome = concepts.admon.hr.biMoRetirementAndHome
  const monthFactor = 365 / 12

  const monthlyRetirementAndHome = biMoRetirementAndHome / 2

  const monthlySalary = minimumWage * monthFactor
  const HRCost = monthlySalary + monthlyRetirementAndHome + healthInsurance
  const weeklySalary = HRCost / 4
  const dailySalary = weeklySalary / 5
  const hourlySalary = dailySalary / 8
  let salaryPerMinute = hourlySalary / 40

  const deliveryGuysNumber = concepts.deliveryGuysNumber
  salaryPerMinute = salaryPerMinute / deliveryGuysNumber

  return salaryPerMinute
}

export {getHRCostPerMin}
