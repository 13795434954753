import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import {MdExpandMore} from 'react-icons/md'
import {loader, xicohtzincoBounds} from 'components/common/map/location-utils'
import {useEffect, useRef} from 'react'

import Style from './_DeliveryMap.module.sass'

const DeliveryMap = ({order, subOrders, setStopOrderLocations}) => {
  const mapElemRef = useRef()
  const mapObjRef = useRef()

  useEffect(() => {
    const zoom = mapObjRef?.current?.getZoom() ?? 14

    loader
      .load()
      .then(() => {
        mapObjRef.current = new window.google.maps.Map(mapElemRef.current, {
            center: {lat: 19.17473, lng: -98.23358},//  latlng plaza xicohtzinco
            zoom: zoom,
            maxZoom: 18,
            minZoom: 14,
        })

        mapObjRef.current.data.add({geometry: new window.google.maps.Data.Polygon([xicohtzincoBounds])})

        const directionsRenderer = new window.google.maps.DirectionsRenderer()
        directionsRenderer.setMap(mapObjRef.current)
       directionsRenderer.setPanel(null) 

        const directionsService = new window.google.maps.DirectionsService()

        const newWaypoints = order.routeDetails.stopOrder.map(stop => ({location: stop.location}))
        newWaypoints.splice(0, 0, order.routeDetails.origin)

        const directionsRequest = {
          origin: order.routeDetails.origin.location,
          destination: order.routeDetails.destination.marker, 
          travelMode: 'DRIVING',
          waypoints: newWaypoints, 
          optimizeWaypoints: true
        }
        
        directionsService.route(directionsRequest, (result, status) => {
          if (status === 'OK') {
            directionsRenderer.setDirections(result)

            const stopOrderLocations = []
            result.routes[0].waypoint_order.forEach(waypoint => {
              stopOrderLocations.push(newWaypoints[waypoint])
            })

            setStopOrderLocations(stopOrderLocations)
          }
        })

      })
  }, [order, setStopOrderLocations])
  
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          <div className={Style.routeInfo}>
            <p className={Style.routeDetails}>Detalle de ruta</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div ref={mapElemRef} style={{height: `${window.innerHeight/2}px`, width: '100%'}} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default DeliveryMap
