import concepts from "./concepts.js"

//const getKmsPerDay = () => {
//  const warrantyKms = concepts.motorcycle.warrantyKms
//  const warrantyMonths = concepts.motorcycle.warrantyMonths
//  const monthFactor = 365 / 12
//  const warrantyDays = monthFactor * warrantyMonths
//  
//  return warrantyKms / warrantyDays
//}

//const getBaseDeliveryGuyCostPerKm = () => {
//  const minimumWage = concepts.baseDeliveryGuy.minimumWage
//  const kmsPerDay = getKmsPerDay()
//
//  return minimumWage / kmsPerDay
//}

const getBaseDeliveryGuyCostPerMin = () => {
  const minimumWage = concepts.baseDeliveryGuy.minimumWage
  const healthInsurance = concepts.baseDeliveryGuy.healthInsurance
  const biMoRetirementAndHome = concepts.baseDeliveryGuy.biMoRetirementAndHome
  const monthFactor = 365 / 12

  const monthlyRetirementAndHome = biMoRetirementAndHome / 2

  const monthlySalary = minimumWage * monthFactor
  const baseDeliveryGuyCost = monthlySalary + monthlyRetirementAndHome + healthInsurance
  const weeklySalary = baseDeliveryGuyCost / 4
  const dailySalary = weeklySalary / 5
  const hourlySalary = dailySalary / 8
  const salaryPerMinute = hourlySalary / 40

  return salaryPerMinute
}

export {getBaseDeliveryGuyCostPerMin}
