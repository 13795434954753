import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {getFirestore, doc, getDoc} from 'firebase/firestore'
import {loader} from 'components/common/map/location-utils'
import {getCostPerKm, getCostPerMin} from '../../../components/shopping-cart/pricing/builder'

const initialState = {
  total: '0.0',
  productCount: 0,
  productsTotal: '0.0',
  productsPerCompany: [],
  routeDetails: {
    origin: null,
    destination: {
      type: 'none',
      marker: null
    },
    distance: 0,
    duration: 0,
    stopOrder: [],
    directions: '',
    deliveryPrice: '$ 0.00'
  }
}

export const addToCart = createAsyncThunk(
  'shoppingCart/addToCart',
  async (product, thunkAPI) => {
    console.log('adding product: ', product)
    const shoppingCart = thunkAPI.getState().shoppingCart 
    const productsPerCompany = shoppingCart.productsPerCompany
    const destinationMarker = shoppingCart.routeDetails.destination.marker

    let companyInArray = productsPerCompany.find(company => company.id === product.companyId)
    if (!companyInArray && destinationMarker) {
      console.log('recalculating route details')

      const companies = productsPerCompany.map(company => ({id: company.id, location: company.location}))
      companies.push({id: product.companyId, location: product.companyLocation})
      recalculateRouteDetails(companies, destinationMarker, thunkAPI)
    }

    thunkAPI.dispatch(addProductToCart(product))
  }
)

export const removeFromCart = createAsyncThunk(
  'shoppingCart/removeFromCart',
  async (product, thunkAPI) => {
    console.log('removing product: ', product)
    const shoppingCart = thunkAPI.getState().shoppingCart 
    const productsPerCompany = shoppingCart.productsPerCompany
    const destinationMarker = shoppingCart.routeDetails.destination.marker

    let companyInArray = productsPerCompany.find(company => company.id === product.companyId)
    if (companyInArray && destinationMarker && companyInArray.productCountPerCompany === 1) {
      console.log('recalculating route details')

      const companies = productsPerCompany.map(company => ({id: company.id, location: company.location}))
      const filteredCompanies = companies.filter(company => company.id !== product.companyId)

      recalculateRouteDetails(filteredCompanies, destinationMarker, thunkAPI)
    }

    thunkAPI.dispatch(removeProductFromCart(product))
  }
)

const shoppingCartSlice = createSlice({
  name: 'shoppingCartSlice',
  initialState,
  reducers: {
    addProductToCart: (state, {payload}) => {
      console.log('adding product: ', payload)

      const newCompany = {
        ownerId: payload.ownerId,
        id: payload.companyId,
        name: payload.companyName,
        type: payload.companyType,
        location: payload.companyLocation,
        productCountPerCompany: 0,
        subTotalPerCompany: '0.0',
        productTypes: []
      }

      let companyInArray = state.productsPerCompany.find(company => company.id === newCompany.id)
      if (!companyInArray) {
        state.productsPerCompany.push(newCompany)
        companyInArray = newCompany
        // calculate route again
        // change delivery price
      }

      const newProductType = {
        id: payload.id,
        name: payload.name,
        productCountPerType: 0,
        price: payload.price,
        subTotalPerProductType: '0.0',
        product: payload
      }

      let productTypeInArray = companyInArray.productTypes.find(productType => productType.id === newProductType.id)
      if (!productTypeInArray) {
        companyInArray.productTypes.push(newProductType)
        productTypeInArray = newProductType
      }

      const intPrice = (Number.parseFloat(productTypeInArray.price.replaceAll(/[,$ ]/g,'')) * 100)

      productTypeInArray.productCountPerType += 1
      const intSubTotalPerProductType = (Number.parseFloat(productTypeInArray.subTotalPerProductType.replaceAll(/[,$ ]/g,'')) * 100)
      productTypeInArray.subTotalPerProductType = ((intSubTotalPerProductType + intPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})

      companyInArray.productCountPerCompany += 1
      const intSubTotalPerCompany = (Number.parseFloat(companyInArray.subTotalPerCompany.replaceAll(/[,$ ]/g,'')) * 100)
      companyInArray.subTotalPerCompany = ((intSubTotalPerCompany + intPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})

      state.productCount += 1
      const intProductsTotal = (Number.parseFloat(state.productsTotal.replaceAll(/[,$ ]/g,'')) * 100)
      state.productsTotal = ((intProductsTotal + intPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})

      const intDeliveryPrice = (Number.parseFloat(state.routeDetails.deliveryPrice.replaceAll(/[,$ ]/g,'')) * 100)
      state.total = ((intProductsTotal + intPrice + intDeliveryPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})
    },

    removeProductFromCart: (state, {payload}) => {
      console.log('removing product: ', payload)

      if(state.productCount === 0)
        return;

      const companyIndex = state.productsPerCompany.findIndex(company => company.id === payload.companyId)
      if (companyIndex < 0)
        return;
      const company = state.productsPerCompany[companyIndex]

      const productTypeIndex = company.productTypes.findIndex(productType => productType.id === payload.id)
      if (productTypeIndex < 0)
        return;
      const productType = company.productTypes[productTypeIndex]
      
      const intPrice = (Number.parseFloat(productType.price.replaceAll(/[,$ ]/g,'')) * 100)

      productType.productCountPerType -= 1
      const intSubTotalPerProductType = (Number.parseFloat(productType.subTotalPerProductType.replaceAll(/[,$ ]/g,'')) * 100)
      productType.subTotalPerProductType = ((intSubTotalPerProductType - intPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})

      if (productType.productCountPerType === 0) 
        company.productTypes.splice(productTypeIndex, 1)

      company.productCountPerCompany -= 1
      const intSubTotalPerCompany = (Number.parseFloat(company.subTotalPerCompany.replaceAll(/[,$ ]/g,'')) * 100)
      company.subTotalPerCompany = ((intSubTotalPerCompany - intPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})

      if (company.productCountPerCompany === 0) {
        state.productsPerCompany.splice(companyIndex, 1)
        // calculate route again
        // change delivery price
      }

      state.productCount -= 1
      const intProductsTotal = (Number.parseFloat(state.productsTotal.replaceAll(/[,$ ]/g,'')) * 100)
      state.productsTotal = ((intProductsTotal - intPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})

      const intDeliveryPrice = (Number.parseFloat(state.routeDetails.deliveryPrice.replaceAll(/[,$ ]/g,'')) * 100)
      state.total = ((intProductsTotal - intPrice + intDeliveryPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})
    },
    
    setDeliveryLocationToCurrent: (state, action) => {
      state.routeDetails.destination.type = 'current'
      state.routeDetails.destination.marker = action.payload
    },

    setDeliveryLocationToAlternative: (state, action) => {
      state.routeDetails.destination.type = 'alternative'
      state.routeDetails.destination.marker = action.payload
    },

    resetDeliveryLocation: (state) => {
      state.routeDetails.destination.type = 'none'
      state.routeDetails.destination.marker = null
      state.routeDetails.origin = null
      state.routeDetails.directions = ''
      state.routeDetails.stopOrder = []
      state.routeDetails.distance = 0
      state.routeDetails.duration = 0
      state.routeDetails.deliveryPrice = '$ 0.00'
      
    },

    updateDeliveryDetails: (state, {payload}) => {
      console.log(payload)
      state.routeDetails.distance = payload.distance 
      state.routeDetails.duration = payload.duration
      state.routeDetails.origin = payload.origin
      state.routeDetails.directions = payload.directions
      state.routeDetails.stopOrder = payload.stopOrder
      state.routeDetails.deliveryPrice = payload.deliveryPrice

      const intProductsTotal = (Number.parseFloat(state.productsTotal.replaceAll(/[,$ ]/g,'')) * 100)
      const intDeliveryPrice = (Number.parseFloat(state.routeDetails.deliveryPrice.replaceAll(/[,$ ]/g,'')) * 100)

      state.total = ((intProductsTotal + intDeliveryPrice) * .01).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})

    },

    resetShoppingCart: state => {
      state.total = '0.0'
      state.productCount = 0
      state.productsTotal = '0.0'
      state.productsPerCompany = []
      state.routeDetails.origin = null
      state.routeDetails.destination.type = 'none'
      state.routeDetails.destination.marker = null
      state.routeDetails.distance = 0
      state.routeDetails.duration = 0
      state.routeDetails.stopOrder = []
      state.routeDetails.directions = ''
      state.routeDetails.deliveryPrice = '$ 0.00'
    },

    loadPendingShoppingCart: (state, {payload}) => {
      state.total = payload.total
      state.productCount = payload.productCount
      state.productsTotal = payload.productsTotal
      state.productsPerCompany = payload.productsPerCompany
      state.routeDetails.origin = payload.routeDetails.origin
      state.routeDetails.destination.type = payload.routeDetails.destination.type
      state.routeDetails.destination.marker = payload.routeDetails.destination.marker
      state.routeDetails.distance = payload.routeDetails.distance
      state.routeDetails.duration = payload.routeDetails.duration
      state.routeDetails.stopOrder = payload.routeDetails.stopOrder
      state.routeDetails.directions = payload.routeDetails.directions
      state.routeDetails.deliveryPrice = payload.routeDetails.deliveryPrice
    }
  },

  extraReducers: {
    [addToCart.pending]: (state, action) => {
      console.log('in pending state', action.payload)
    },
    [addToCart.rejected]: (state, action) => {
      console.log('in rejected state', action.error, action.error.message)
    },
    [addToCart.fulfilled]: (state, action) => {
      console.log('in fulfilled state', action.payload)
    }
  }
})

export const populatePendingShoppingCart = createAsyncThunk(
  'shooppingCart/getPendingShoppingCart',
  async (uid, thunkAPI) => {
    console.log('in thunk')
    const userDoc = await getDoc(doc(getFirestore(), 'users', uid))

    if (!userDoc.exists) 
      return

    const user = userDoc.data()

    if (!user.pendingOrder)
      return

    const pendingShoppingCart = JSON.parse(user.pendingOrder).shoppingCart

    thunkAPI.dispatch(loadPendingShoppingCart(pendingShoppingCart))
  }
)

const recalculateRouteDetails = (companiesArg, destinationMarker, thunkAPI) => {
  loader
    .load()
    .then(() => {
          const origins = companiesArg.map(company => ({location: company.location}))
          const destination = [destinationMarker]

          const distanceMatrixService = new window.google.maps.DistanceMatrixService()
          const distanceMatrixRequest = {
            origins: origins,
            destinations: destination,
            travelMode: 'DRIVING'
          }
          distanceMatrixService.getDistanceMatrix(distanceMatrixRequest, (result, status) => {
            if (status === 'OK') {

              let farthestDistance = 0
              let farthestPointIndex = -1

              result.rows.forEach((row, index) => {
                //console.log('from ', result.originAddresses[index], 
                //            ' to ', result.destinationAddresses[0], ': ', 
                //            row.elements[0].distance.value, 'm')

                if (row.elements[0].distance.value > farthestDistance) {
                  farthestDistance = row.elements[0].distance.value
                  farthestPointIndex = index
                }
              })

              console.log('farthest point distance: ', farthestDistance, ', index: ', farthestPointIndex)
              const directionsService = new window.google.maps.DirectionsService()
              
              //const directionsRenderer = new window.google.maps.DirectionsRenderer()
              //const map = new window.google.maps.Map(mapRef.current, {zoom: 14})
              //directionsRenderer.setMap(map)

              let waypoints = origins.slice()
              waypoints.splice(farthestPointIndex, 1)

              const directionsRequest = {
                origin: origins[farthestPointIndex],
                destination: destinationMarker,
                travelMode: 'DRIVING',
                waypoints: waypoints,
                optimizeWaypoints: true
              }

              directionsService.route(directionsRequest, (result, status) => {
                if (status === 'OK') {
                  //directionsRenderer.setDirections(result)
                  const legs = result.routes[0].legs
                  const distanceReducer = (accumulator, route) => accumulator + route.distance.value
                  const durationReducer = (accumulator, route) => accumulator + route.duration.value
                  
                  const distanceKms = (legs.reduce(distanceReducer, 0) / 1000).toFixed(1)
                  const durationMins = Math.ceil(legs.reduce(durationReducer, 0) / 60) + 10

                  const companies = companiesArg.map(company => ({id: company.id, 
                                                                        location: company.location})) 

                  const stopOrder = [] 
                  result.routes[0].waypoint_order.forEach(waypoint => {
                    stopOrder
                      .push(companies
                              .find(company => company.location.lat === waypoints[waypoint].location.lat && 
                                                 company.location.lng === waypoints[waypoint].location.lng
                              )
                      )
                  })

                  let distanceCost = distanceKms * getCostPerKm()
                  distanceCost = Math.ceil(distanceCost)

                  let timeCost = (durationMins - 3.7) * getCostPerMin()
                  timeCost = Math.ceil(timeCost)

                  const deliveryPrice = (distanceCost + timeCost).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})


                  thunkAPI.dispatch(updateDeliveryDetails({
                                                  origin: origins[farthestPointIndex], 
                                                  distance: distanceKms, 
                                                  duration: durationMins,
                                                  stopOrder: stopOrder,
                                                  directions: JSON.stringify(result),
                                                  deliveryPrice: deliveryPrice
                                                }))
                }

              })
            }
          })
        })
}

export default shoppingCartSlice.reducer
export const {
              addProductToCart, 
              removeProductFromCart,
              setDeliveryLocationToCurrent,
              setDeliveryLocationToAlternative,
              updateDeliveryDetails,
              resetShoppingCart,
              loadPendingShoppingCart} = shoppingCartSlice.actions
