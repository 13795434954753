import { HiShoppingCart } from 'react-icons/hi';
//import Style from "./_ShoppingCartButton.module.sass"
import {useLocation} from "react-router-dom";
import FloatingButton from 'components/common/FloatingButton'
import {Badge} from '@material-ui/core'
import {useSelector} from 'react-redux'
import {useState, useEffect} from 'react'

const ShoppingCartButton = () => {
  const location = useLocation()
  const productCount = useSelector(state => state.shoppingCart.productCount)


  const [locationState, setLocationState] = useState({})

  useEffect(() => {
    const ls = {fromSC: location.pathname}
    if (location.pathname.startsWith('/company/')) {
      ls.company = location.state?.company
      ls.from = location.state?.from
    }
    setLocationState(ls)

  }, [location.pathname, location.state?.from, location.state?.company])

  return  ( 
    productCount > 0 && 
      (['/','/products','/companies'].includes(location.pathname) ||
      /\/company\/.+/.test(location.pathname)) &&
    <FloatingButton
      to='/shoppingcart'
      stateParam={locationState}
      position='bottom-right'>
        <Badge 
          overlap='rectangular'
          color='error' 
          badgeContent={productCount} 
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
          <HiShoppingCart size="29px" style={{fill: 'white'}}/>
        </Badge>
    </FloatingButton>
  )
}

export default ShoppingCartButton;
