import Style from './_Info.module.sass'

const Info = ({info}) => {
 // console.log(info)

  return    <div className={Style.cardInfo}>
              <div className={Style.priceRow}>
                <div className={Style.price}>
                  {`$ ${info.price}`} 
                  <div className={Style.tipoMoneda}> MXN </div>
                </div>
              </div>

              <div className={Style.nombre}>
                <h4>{info.name}</h4>
              </div>
              <div className={Style.description}>
                <p>{info.description}</p>
              </div>
              <div className={Style.toast}>
              </div>
            </div>
};

export default Info;
