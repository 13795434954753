import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {IoCall} from 'react-icons/io5'
import {useEffect, useState} from 'react'
import {listenForOrderChanges, listenForDeliverySubOrders} from './delivery-utils'
import {useLocation} from 'react-router-dom'
import DeliveryMap from './DeliveryMap'
import CompanyCard from './CompanyCard'
import {OrderStatus, SubOrderStatus, updateOrderStatus} from 'components/suborders/order-utils'
import {Button} from '@material-ui/core'

import Style from './_DeliveryOrderView.module.sass'

const DeliveryOrderView = () => {
  const location = useLocation()
  const [order, setOrder] = useState(location?.state?.order)
  const [subOrders, setSubOrders] = useState([])
  const [stopOrderLocations, setStopOrderLocations] = useState([])

  useEffect(() => {
    const detachListener = listenForOrderChanges(order.id, setOrder)
    return detachListener
  }, [order.id])

  useEffect(() => {
    const detachListener = listenForDeliverySubOrders(order.id, setSubOrders)
    return detachListener
  }, [order.id])
  
  const getSubOrderStatusColor = (status) => {
    let newColor = 'darkred'

    switch(status) {
      case SubOrderStatus.NEW:
        newColor = 'darkred'
        break
      case SubOrderStatus.IN_PREPARATION:
        newColor = 'darkred'
        break
      case SubOrderStatus.READY_FOR_PICKUP:
        newColor = 'darkred'
        break
      case SubOrderStatus.PICKED_UP:
        newColor = 'darkorange'
        break
      case SubOrderStatus.DELIVERED:
        newColor = 'darkgreen'
        break
      default:
        newColor = 'darkred'
        break
    }

    return newColor
  }

  const getButtonText = (status) => {
    let newText = 'EN RECOLECCION'

    switch(status) {
      case OrderStatus.NEW:
        newText = 'EN PRECOLECCION'
        break
      case OrderStatus.IN_PROGRESS:
        newText = 'EN CAMINO'
        break
      case OrderStatus.ON_ITS_WAY:
        newText = 'ENTREGADO'
        break
      case OrderStatus.DELIVERED:
        newText = 'COMPLETADO'
        break
      default:
        newText = 'EN RECOLECCION'
        break
    }

    return newText
  }

  const getButtonColor = (status) => {
    let newColor = 'darkgrey'

    switch(status) {
      case OrderStatus.NEW:
        newColor = 'darkgrey'
        break
      case OrderStatus.IN_PROGRESS:
        newColor = 'darkgrey'
        break
      case OrderStatus.ON_ITS_WAY:
        newColor = 'darkgreen'
        break
      case OrderStatus.DELIVERED:
        newColor = 'darkgrey'
        break
      default:
        newColor = 'darkgrey'
        break
    }

    return newColor
  }

  const changeOrderStatus = (status) => {
    updateOrderStatus(order.id, OrderStatus.DELIVERED, subOrders)
  }

  return (
    <div>
      <TitleBar>
        <h4 className={Style.title}>Detalle de recorrido</h4>
      </TitleBar>
      <Content>
        <div className={Style.recipient}>
          <div className={Style.label}>Destinatario:</div>
            <Button 
              component='a' 
              color='secondary' 
              variant='contained' 
              size='small' 
              href={`tel: ${order?.recipient?.phoneNumber}`}>
              {order?.recipient?.name} 
              &nbsp;&nbsp;&nbsp;
              <IoCall size='20px'/>
            </Button>
        </div>
        <DeliveryMap  order={order} subOrders={subOrders} setStopOrderLocations={setStopOrderLocations}/>
        <div className={Style.stopList}>
          {
            stopOrderLocations.length > 0 && subOrders.length > 0 &&
            stopOrderLocations.map((nextStop, index) => {
              console.log(nextStop)
              console.log(subOrders)
              const subOrder = subOrders.find(subOrder => subOrder.companyLocation.lat === nextStop.location.lat &&
                                                          subOrder.companyLocation.lng === nextStop.location.lng)

              const stopInfo = {}
              stopInfo.isFirstStop = index === 0
              stopInfo.isLastStop = index === stopOrderLocations.length - 1
              stopInfo.stopNumber = index + 1

              console.log(subOrder)
              return <CompanyCard 
                        key={subOrder.id}
                        order={order}
                        subOrder={subOrder} 
                        statusColor={getSubOrderStatusColor(subOrder.status)} 
                        stopInfo={stopInfo} />
            })
            //
          }

           <CompanyCard 
              order={order}
              subOrder={{
                companyName: order?.recipient?.name,
                companyType: 'Destinatario',
                timestamp: order.timestamp,
                companyLocation: order.routeDetails.destination.marker
              }} 
              statusColor={getSubOrderStatusColor(order.status)} 
              stopInfo={{
                stopNumber: stopOrderLocations.length + 1,
              }} />
        </div>

        <div className={Style.price}>
          <div className={Style.countAndTotal}>
            <div className={Style.count}>
              Productos: {order.productCount}
            </div>

            <hr style={{border: '1px solid black', width: '100%'}}/>

            <div className={Style.subtotal}>
              Subtotal: {order.productsTotal}
              <div className={Style.tipoMoneda}> MXN </div>
            </div>

            <div className={Style.delivery}>
              Envio: {order.routeDetails.deliveryPrice}
              <div className={Style.tipoMoneda}> MXN </div>
            </div>

            <hr style={{border: '1px solid black', width: '100%'}}/>

            <div className={Style.total}>
              Total: {order.total}
              <div className={Style.tipoMoneda}> MXN </div>
            </div>
          </div>
        </div>

        <div className={Style.statusButtonDiv}>
          <Button className={Style.statusButton}
                  onClick={changeOrderStatus}
                  disabled={order.status !== OrderStatus.ON_ITS_WAY}
                  style={{backgroundColor: getButtonColor(order.status)}}>
            {getButtonText(order.status)}
          </Button>
        </div>
      </Content>
      <FloatingButton
        position='bottom-left'
        to='/delivery'>
        <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
      </FloatingButton>
    </div>
  )
};

export default DeliveryOrderView;
