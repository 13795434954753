import {OrderStatus} from '../suborders/order-utils'
import {getFirestore, query, where, orderBy, collection, onSnapshot} from 'firebase/firestore'

const listenForClientOrders = (clientId, setClientOrders) => {
    const q = query(collection(getFirestore(), 'orders'),
                where('clientId', '==', clientId),
                orderBy('timestamp', 'desc'))
    const detachListener = onSnapshot(q, snapshot => {
                            const clientOrders = {
                              newOrders: [],
                              ordersInProgress: [],
                              ordersOnItsWay: [],
                              ordersDelivered: [],
                            }

                            snapshot.forEach(orderDoc => {
                              const order = orderDoc.data()
                              order.id = orderDoc.id

                              if (order.status === OrderStatus.NEW)
                                clientOrders.newOrders.push(order)
                              else if (order.status === OrderStatus.IN_PROGRESS)
                                clientOrders.ordersInProgress.push(order)
                              else if (order.status === OrderStatus.ON_ITS_WAY)
                                clientOrders.ordersOnItsWay.push(order)
                              else if (order.status === OrderStatus.DELIVERED)
                                clientOrders.ordersDelivered.push(order)
                            })

                            setClientOrders(clientOrders)
                          })

    return detachListener
}

const listenForSubOrdersChanges = (orderId, setSubOrders) => {
  const q = query(collection(getFirestore(), 'subOrders'),
              where('orderId', '==', orderId))

  const detachListener = onSnapshot(q, snapshot => {
                                  const subOrders = []

                                  snapshot.forEach(subOrderDoc => {
                                    const subOrder = subOrderDoc.data()
                                    subOrder.id = subOrderDoc.id
                                    subOrders.push(subOrder)
                                  })

                                  setSubOrders(subOrders)
                                })

  return detachListener
}

export {listenForClientOrders, listenForSubOrdersChanges}
