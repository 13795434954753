import Style from './_Whatsapp.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import {IoLogoWhatsapp} from 'react-icons/io';
import {validatePhoneNumber, formatPhoneInput} from 'utils/phone-utils'

const Whatsapp = () => {
  const methods = useFormContext();
  const call = methods.getValues('links.call')
  const sms = methods.getValues('links.sms')
  const telegram = methods.getValues('links.telegram')
  const whatsapp = methods.getValues('links.whatsapp')

  const previousMobile = whatsapp && whatsapp.number && whatsapp.number.length > 0 ? whatsapp.number : 
                         telegram?.number ?? 
                         sms?.number ?? 
                         (call?.number && call?.isMobile ? call.number : '')

  const whatsappInput = methods.register('links.whatsapp.number', {
                          value: previousMobile,
                          pattern: {
                            value: /\(\d{3}\) \d{3} - \d{4}/,
                            message: 'El numero debe ser de 10 digitos2q'
                         }
                        })

  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Whatsapp</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <TextField 
                label='Numero telefonico' 
                type='tel'
                onKeyDown={e => validatePhoneNumber(e, methods.setValue, whatsappInput.name)}
                onInput={e => formatPhoneInput(e, methods.setValue, whatsappInput.name)}
                inputProps={{
                  maxLength: 16
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IoLogoWhatsapp/>
                    </InputAdornment>
                  )
                }}
                inputRef={whatsappInput.ref}
              /> 
            </div> 
          </div>
        </div>
}

export default Whatsapp;
