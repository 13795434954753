import Style from './_Youtube.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import {IoLogoYoutube} from 'react-icons/io5';

const Youtube = () => {

  const methods = useFormContext();
  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Youtube</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <Controller 
                control={methods.control}
                name='links.youtube.channel'
                defaultValue={methods.getValues('links.youtube.channel')}
                rules={{
                  pattern: {
                    value: /https:\/\/w{3}\.youtube\.com\/.+/,
                    message: 'La liga del canal no es valida'
                  }
                }}
                render={({field})=>(
                  <TextField 
                    label='Liga de Canal de Youtube' 
                    type='text'
                    value={field.value}
                    onChange={field.onChange}
                    placeholder='https://www.youtube.com/...'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <IoLogoYoutube />
                        </InputAdornment>
                      )
                    }}/> 
                )}
              />
            </div> 
          </div>
        </div>
}

export default Youtube;
