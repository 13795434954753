import {IoCall} from 'react-icons/io5'
import {turnOn} from 'redux/app/backdropSlice'
import {useDispatch} from 'react-redux'
import {validatePhoneNumber, formatPhoneInput} from 'utils/phone-utils'
import {DevTool} from '@hookform/devtools'

import {
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions, 
  TextField, 
  InputAdornment, 
  Button
} from '@material-ui/core'

import {useForm, useFormState} from 'react-hook-form'
//import Style from './_PhoneModal.module.sass'

const PhoneModal = ({show, setShow, signInCB}) => {
	const dispatch = useDispatch()
  const {register, handleSubmit, control, setValue, clearErrors} = useForm()
  const {errors} = useFormState({control})

  const numberInput = register('phoneNumber', {
                        required: {
                          value: true,
                          message: 'Numero celular requerido'
                        },
                        pattern: {
                          value: /\(\d{3}\) \d{3} - \d{4}/,
                          message: '10 digitos requeridos'
                        }
                      })

  const onSubmit = async (data) => {
		dispatch(turnOn())
		const countryCode = '+52'
		const phoneNumberDigits = data[numberInput.name].replace(/\D/g, '') 
		const phoneNumber = countryCode + phoneNumberDigits

		await signInCB(phoneNumber)
  }

	const onCancel = () => {
		clearErrors()
		setValue(numberInput.name, '')
		setShow(false)
	}

  return (
		<Dialog open={show}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle>
					Numero Celular
				</DialogTitle>
				<DialogContent>
					<DialogContentText component='span'>
						<TextField 
							type='tel'
							size='small'
							label="Numero Celular"
							autoFocus
							onKeyDown={e => validatePhoneNumber(e, setValue, numberInput.name)} 
							onInput={e => formatPhoneInput(e, setValue, numberInput.name)} 
							inputProps={{
								maxLength: 16
							}}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<IoCall />
									</InputAdornment>
								)
							}}
							inputRef={numberInput.ref}
						/>
						{ errors[numberInput.name] && 
							<p style={{color: 'red', fontSize: '.8rem'}}>{errors[numberInput.name].message}</p> }
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button 
						variant='contained' 
						color='primary'
						size='small'
						onClick={()=>onCancel()}
					>
						Cancelar
					</Button>
					<Button 
						type='submit'
						id='sign-in-button'
						variant='contained' 
						color='primary'
						size='small'
					>
						Aceptar
					</Button>
				</DialogActions>
			</form>
			<DevTool control={control} />
		</Dialog>
  );
}

export default PhoneModal 
