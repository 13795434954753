import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import ManagementCards from './ManagementCards'
import FloatingButton from 'components/common/FloatingButton'
import { AiOutlineHome } from 'react-icons/ai'
import { FaPlus } from 'react-icons/fa'

import Style from './_BusinessManagement.module.sass'

const BusinessManagement = () => {
  return <div>
            <TitleBar>
              <h4 className={Style.title}>Mis Negocios</h4>
            </TitleBar>
            <Content>
              <ManagementCards />
            </Content>
            <FloatingButton
              position='bottom-left'
              to='/'>
              <AiOutlineHome style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
            <FloatingButton
              position='bottom-right'
              to='/account/companies/add/identity'>
              <FaPlus style={{fill: 'white'}}/>
            </FloatingButton>
              
    </div>
};

export default BusinessManagement;
