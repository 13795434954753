import {xicohtzincoBounds} from './xicohtzincoBounds';
import {Loader} from '@googlemaps/js-api-loader';

const loader = new Loader({
  apiKey: 'AIzaSyDr-roneYUT3qfo7BoVs9aExXoz2WiF-KE',
  libraries: ['places', 'geometry']});

const markCurrentLocation = (setMarker, setValue,  coordsRef) => {
  getCurrentLocation(markCurrentLocationCB, setMarker, setValue, coordsRef);
}

const markCurrentLocationCB = (mark, args) => {
  const [setMarker, setValue, coordsRef] = args;

  setMarker(mark);
  if(setValue && coordsRef) {
    setValue(coordsRef.name, JSON.stringify(mark));
  }
};

const isInTown = (setIsInTown) => {
  return getCurrentLocation(isInTownCB, setIsInTown);
}

const isInTownCB = async (mark, args) => {
 // console.log(args)
  const setIsInTown = args[0];

  if (!(window?.google?.maps))
    await loader.load();

  const point = new window.google.maps.LatLng(mark);
  //const point = new window.google.maps.LatLng({lat: 19.17292, lng: -98.23867});
  const polygon = new window.google.maps.Polygon({paths: xicohtzincoBounds});
//  console.log(window.google.maps.geometry.poly.containsLocation(point, polygon));
  setIsInTown(window.google.maps.geometry.poly.containsLocation(point, polygon));
}

const getCurrentLocation = (callback, ...args) => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      const point = {lat: position.coords.latitude, lng: position.coords.longitude}
      callback(point, args);
    },
    (error) => {
      console.log(error);
    },
    {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 1000,
    });
};

export {markCurrentLocation, isInTown, xicohtzincoBounds, loader, getCurrentLocation};
