
function mask(phoneArray){
  var phoneNumberArray = phoneArray.join("").replace(/[^\d]/gi,"").split("");  

  if([1,2].includes(phoneNumberArray.length)){
    phoneNumberArray.splice(0,0,"(");
  } else if([3,4,5].includes(phoneNumberArray.length)){
    phoneNumberArray.splice(0,0,"(");
    phoneNumberArray.splice(4,0,")"," ");
  } else if([6,7,8,9,10].includes(phoneNumberArray.length)){
    phoneNumberArray.splice(0,0,"(");
    phoneNumberArray.splice(4,0,")"," ");
    phoneNumberArray.splice(9,0," ","-"," ");
  }

  return phoneNumberArray.join("");
}

const validatePhoneNumber = (event, setValue, inputName) => {
  var isDigit = /^\d$/.test(event.key);
  var isAnyOtherCharacter = /^[^\d]$/.test(event.key);
  var isBackspace = event.key === "Backspace";
  var isDelete = event.key === "Delete";

  if (isDigit || isBackspace || isDelete || isAnyOtherCharacter) {
    event.preventDefault();
    var phoneArray = event.target.value.split("");
    var cursorPosition = event.target.selectionStart;
    var newCursorPosition = cursorPosition;
    if (isDigit) {
      if (event.target.value.length <= 15) {
        phoneArray.splice(cursorPosition,0,event.key); 
        if ([0,1].includes(cursorPosition)) {
          newCursorPosition = 2;
        } else if ([3,4,5].includes(cursorPosition)) {
          newCursorPosition = 6;
        } else if([8,9,10,11].includes(cursorPosition)) {
          newCursorPosition = 12;
        } else {
          newCursorPosition = cursorPosition + 1;
        }
      }
    } else if (isBackspace) {
      console.log(cursorPosition)
      if (cursorPosition === 2) {
        phoneArray.splice(0,2); 
      } else if([4,5,6].includes(cursorPosition)) {
        phoneArray.splice(3,1); 
        newCursorPosition = 3;
      } else if([9,10,11,12].includes(cursorPosition)) {
        phoneArray.splice(8,1); 
        newCursorPosition = 8;
      } else {
        phoneArray.splice(cursorPosition - 1,1);
        newCursorPosition = cursorPosition -1;
      }
    } else if (isDelete) {
      if ([0,1].includes(cursorPosition)) {
        phoneArray.splice(0,2); 
      } else if ([4,5,6].includes(cursorPosition)) {
        phoneArray.splice(6,1); 
        newCursorPosition = 6;
      } else if([9,10,11,12].includes(cursorPosition)) {
        phoneArray.splice(12,1); 
        newCursorPosition = 12;
      } else {
        phoneArray.splice(cursorPosition,1);
        newCursorPosition = cursorPosition;
      }
    }
    event.target.value = mask(phoneArray);
    setValue(inputName, mask(phoneArray));
    event.target.selectionStart = newCursorPosition;
    event.target.selectionEnd = newCursorPosition;
  }
}
const formatPhoneInput = (event, setValue, inputName) => {
  event.target.value = mask(event.target.value.split(''))
  setValue(mask(inputName, event.target.value.split('')))
}
const formatExtInput = (event, setValue, inputName) => {
  event.target.value = event.target.value.replace(/\D/g, '')
  setValue(inputName, event.target.value.replace(/\D/g, ''))
}

export {validatePhoneNumber, formatPhoneInput, formatExtInput};



