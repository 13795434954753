const sanitizeRecord = (data) => {
  const record = ({...data})
  console.log('record: ',record)
  
  if (!record?.links?.call?.number || record?.links?.call?.number?.length === 0)
    delete record.links.call
  if (record.cbs.sms && record.links.sms.number && record.links.sms.number.length === 0)
    delete record.links.sms
  if (record.cbs.telegram && record.links.telegram.number && record.links.telegram.number.length === 0)
    delete record.links.telegram
  if (record.cbs.whatsapp&& record.links.whatsapp.number && record.links.whatsapp.number.length === 0)
    delete record.links.whatsapp
  if (record.cbs.facebook && record.links.facebook.link && record.links.facebook.link.length === 0)
    delete record.links.facebook
  if (record.cbs.twitter && record.links.twitter.handle && record.links.twitter.handle.length === 0)
    delete record.links.twitter
  if (record.cbs.instagram && record.links.instagram.username && record.links.instagram.username.length === 0)
    delete record.links.instagram
  if (record.cbs.tiktok && record.links.tiktok.username && record.links.tiktok.username === 0)
    delete record.links.tiktok
  if (record.cbs.youtube && record.links.youtube.channel && record.links.youtube.channel === 0)
    delete record.links.youtube
  if (record.cbs.website && record.links.website.link && record.links.website.link.length === 0)
    delete record.links.website

  delete record.cbs 
  delete record.any

  console.log(record)

  return record;
}

export {sanitizeRecord};
