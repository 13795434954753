import ReactDOM from 'react-dom'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
//import reportWebVitals from './reportWebVitals'

import {initializeFirebase} from './firebase'
import {setUpNotifications} from './notifications'
import './localdb.js'

import {Provider} from 'react-redux'
import store from 'redux/store'

import {IconContext} from 'react-icons'
import {createTheme, ThemeProvider} from '@material-ui/core/styles'

import App from 'components/App'

initializeFirebase()
setUpNotifications()

const theme = createTheme({
  palette: {
    primary: {
      main: '#1365C0',
      light: '#5D92F3',
      dark: '#003C8F'
    },
    secondary: {
      main: '#FF9100',
      light: 'FFC246',
      dark: 'C56200'
    },
    error: {
      main: '#FF0000'
    },
    success: {
      main: '#00FF00'
    }

  }
})

ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <IconContext.Provider value={{className: 'icons'}}>
          <App />
        </IconContext.Provider>
      </ThemeProvider>
    </Provider>, document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
