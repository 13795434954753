import {OrderStatus} from '../suborders/order-utils'
import {getFirestore, query, where, collection, orderBy, doc, onSnapshot} from 'firebase/firestore'

const listenForIncomingOrders = (setIncomingOrders) => {
    const q = query(collection(getFirestore(), 'orders'),
                orderBy('timestamp', 'desc'))

    const detachListener = onSnapshot(q, snapshot => {
                            const incomingOrders= {
                              newOrders: [],
                              ordersInProgress: [],
                              ordersOnItsWay: [],
                              ordersDelivered: [],
                            }

                            snapshot.forEach(orderDoc => {
                              const order = orderDoc.data()
                              order.id = orderDoc.id

                              if (order.status === OrderStatus.NEW)
                                  incomingOrders.newOrders.push(order)
                              else if (order.status === OrderStatus.IN_PROGRESS)
                                  incomingOrders.ordersInProgress.push(order)
                              else if (order.status === OrderStatus.ON_ITS_WAY)
                                  incomingOrders.ordersOnItsWay.push(order)
                              else if (order.status === OrderStatus.DELIVERED)
                                  incomingOrders.ordersDelivered.push(order)
                            })

                            setIncomingOrders(incomingOrders)
                          })

    return detachListener
}

const listenForIncomingSubOrders = async (orderId, setSubOrders)=> {
  const q = query(collection(getFirestore(), 'subOrders'),
              where('orderId', '==', orderId))

  const detachListener = onSnapshot(q, snapshot => {
                            const subOrders = []

                            snapshot.forEach(subOrderDoc => {
                              const subOrder = subOrderDoc.data()
                              subOrder.id = subOrderDoc.id
                              subOrders.push(subOrder)
                            })

                            setSubOrders(subOrders)
                          })

  return detachListener
}

const listenForOrderChanges = (orderId, setOrder)=> {
  const detachListener = onSnapshot(doc(getFirestore(), 'orders', orderId), snapshot => {
                            const order = snapshot.data()
                            order.id = snapshot.id
                            setOrder(order)
                          })

  return detachListener
}

const listenForSubOrderChanges = (subOrderId, setSubOrder)=> {
  const detachListener = onSnapshot(doc(getFirestore(), 'subOrders', subOrderId), snapshot => {
                            const subOrder = snapshot.data()
                            subOrder.id = snapshot.id
                            setSubOrder(subOrder)
                          })

  return detachListener
}

export {listenForIncomingOrders, listenForIncomingSubOrders, listenForOrderChanges, listenForSubOrderChanges}
