import Style from './_ManagementCards.module.sass'
import ManagementCard from './management-card/ManagementCard'
import {useState, useEffect} from 'react'
import {getAuth} from 'firebase/auth'
import {getFirestore, collection, getDocs} from 'firebase/firestore'

import {useDispatch} from 'react-redux'
import {turnOn, turnOff} from 'redux/app/backdropSlice'

const ManagementCards = () => {
  const dispatch = useDispatch()
  const [companies, setCompanies] = useState([])

  useEffect(()=>{
    dispatch(turnOn())
    const queryResult = []

    getDocs(collection(getFirestore(), `users/${getAuth().currentUser.uid}/companies`))
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data()
          data.id = doc.id

          queryResult.push(data)
        })
        
        setCompanies(queryResult)
      })
    dispatch(turnOff())
  }, [dispatch])

  return <div id='managementCards' className={Style.managementCards}>
          { 
            companies.map((company)=>{
              return <ManagementCard key={company.id} company={company} />
            })
          }
         </div>
}

export default ManagementCards;
