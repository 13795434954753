import concepts from "./concepts.js"

const getGasolineCostPerKm = () => {
  const consumption = concepts.motorcycle.consumption
  const gasolinePrice = concepts.gasoline.price

  return gasolinePrice / consumption
}

export {getGasolineCostPerKm}
