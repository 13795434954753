import ProductTypes from './ProductTypes'

import Style from './_ProductsPerCompany.module.sass'

//import {useRef} from 'react'

const ProductsPerCompany = ({companies}) => {
 // const colorRef = useRef()

 // const getRandomColor = () => {
 //   const randomColor = `hsl(${Math.floor(Math.random() * 12) * 30}, 100%, 50%)` 

 //   if (randomColor !== colorRef.current) {
 //     colorRef.current = randomColor 
 //     return randomColor
 //   }

 //   return getRandomColor()
 // }


  return (
    <div>
      {
        companies.map(company => {
           return  <div key={company.id} className={Style.company} >
                     <div className={Style.header} >
                       <div className={Style.nameAndType}>
                         <p className={Style.name}>
                            {company.name}
                         </p>
                         <p className={Style.type}>
                            {company.type}
                         </p>
                       </div>
                       <div className={Style.quantityAndSubtotal}>
                         <p className={Style.quantity}>
                            Productos: {company.productCountPerCompany}
                         </p>
                         <div className={Style.subtotal} >
                            Subtotal: {company.subTotalPerCompany}
                            <div className={Style.tipoMoneda}> MXN </div>
                         </div> 
                       </div>
                     </div>
                     <ProductTypes productTypes={company.productTypes} />
                    </div>
          
        })
      }
  </div>
  )
};

export default ProductsPerCompany;
