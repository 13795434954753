import React from "react";
import Style from "./_AppLink.module.sass";

const AppLink = ({value}) => {
  return  <div className={`${Style.appLink} ${value.type==="location" ? Style.location : ""}`} >
            <a href={value.link + value.data}>
              {React.createElement(value.image)}
            </a>
          </div>
};

export default AppLink;
