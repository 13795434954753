//import Style from './_Menu.module.sass'
import {Drawer, ListItem, ListItemText, Button, List, Divider, makeStyles} from '@material-ui/core'
import {GiHamburgerMenu} from 'react-icons/gi'
import ProfileMenuButton from './ProfileMenuButton'

import {useState, Fragment, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate, useLocation} from 'react-router-dom'
import {Role, changeRole} from 'redux/app/account/authSlice'

const Menu = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const ownerCompanies = useSelector(state => state.auth.user.owner.companies)
  //const isAdmin = useSelector(state => state.auth.user.isAdmin)
  const role = useSelector(state => state.auth.user.role)

  const getUserRole = (path) => {
    let newRole = Role.CLIENT

    if (path.startsWith('/account') || path.startsWith( '/suborders')) 
      newRole = Role.OWNER 
    else if (path.startsWith('/delivery'))
      newRole = Role.DELIVERY_GUY
    else if (path.startsWith('/admin'))
      newRole = Role.ADMIN
    else if (!['/about', '/terms', '/privacy'].includes(path))
      newRole = Role.CLIENT

    return newRole
  }

  useEffect(() => {
    dispatch(changeRole(getUserRole(location.pathname)))
  }, [dispatch, location.pathname])

  useEffect(() => {
    if (location.hash === '#showMenu') {
      dispatch(changeRole(getUserRole(location.pathname)))
      setOpen(true)
    } else {
      setOpen(false)
    } 
  }, [location.hash, dispatch, location.pathname])

  const useStyles = makeStyles({
    menuButton: {padding: '13px', minWidth: '25px'},
    drawer: {minWidth: '60%'},
    profile: {justifyContent: 'center', alignItems: 'center'}
  })

  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const toggleDrawer = (event, opened) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    if (opened) {
      navigate(`${location.pathname}#showMenu`)
    } else {
      navigate(location.pathname, {replace: true})
    }
  }

  const goToPage = (e, path) => {
    navigate(location.pathname, {replace: true})
    navigate(path)
  }

  return (
    <Fragment>
      <Button onClick={e => toggleDrawer(e, true)} classes={{root: classes.menuButton}}
      >
        <GiHamburgerMenu color='white' size='27px'/>
      </Button>
      <Drawer anchor='left' 
              open={open} 
              onClose={e => toggleDrawer(e, false)}
              classes={{paperAnchorLeft: classes.drawer}}>
          <List>
            <ListItem classes={{root: classes.profile}}>
              <ProfileMenuButton />
            </ListItem>
            <Divider />
              <ListItem button onClick={e => goToPage(e, `/`)}>
                <ListItemText primary='Inicio' />
              </ListItem>
            <Divider />
            {
              (!ownerCompanies || Object.keys(ownerCompanies).length === 0)  && Role.CLIENT &&
              <ListItem button onClick={e => goToPage(e, `/account/companies/add/identity`)}>
                <ListItemText primary='Publicar un negocio' />
              </ListItem>
            }

            {
              ownerCompanies && Object.keys(ownerCompanies).length > 0 && role === Role.OWNER &&
              <>
                <ListItem button onClick={e => goToPage(e, '/account/companies')}>
                  <ListItemText primary='Editar Negocios/Productos' />
                </ListItem>
                <ListItem button onClick={e => goToPage(e, '/suborders')}>
                  <ListItemText primary='Ventas' />
                </ListItem>
              </>
            }

            {
              role === Role.CLIENT &&
              <>
                <ListItem button onClick={e => goToPage(e, `/client/orders`)}>
                  <ListItemText primary='Pedidos' />
                </ListItem>
              </>
            }

            {
              role === Role.DELIVERY_GUY &&
              <>
                <ListItem button onClick={e => goToPage(e, `/delivery`)}>
                  <ListItemText primary='Entregas' />
                </ListItem>
              </>
            }

            {
              role === Role.ADMIN &&
              <>
                <ListItem button onClick={e => goToPage(e, `/admin/authorization`)}>
                  <ListItemText primary='Autorizaciones' />
                </ListItem>
                <ListItem button onClick={e => goToPage(e, `/admin/monitoring`)}>
                  <ListItemText primary='Monitoreo' />
                </ListItem>
              </>
            }

            <Divider />

            <ListItem button onClick={e => goToPage(e, `/about`)}>
              <ListItemText primary='Sobre ManduX-T' />
            </ListItem>
            <ListItem button onClick={e => goToPage(e, `/terms`)}>
              <ListItemText primary='Términos y Condiciones' />
            </ListItem>
            <ListItem button onClick={e => goToPage(e, `/privacy`)}>
              <ListItemText primary='Política de Privacidad' />
            </ListItem>

          </List>
      </Drawer>
    </Fragment>
  )
}

export default Menu
