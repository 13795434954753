import Style from "./_ManagementCard.module.sass";
import PictureAndInfo from "./PictureAndInfo.js";
import Links from "./Links.js";

const ManagementCard = ({company}) => {
  console.log(company);
//  const info = {
//    companyId: company.id,
//    picture: company.picture,
//    name: company.name, 
//    type: company.type, 
//    description: company.description, 
//    hasDelivery: company.hasDelivery, 
//    ps_count: company.ps_count
//  }

  const links = {
    location: company?.location, 
    call: company?.links?.call?.number, 
    sms: company?.links?.sms?.number, 
    telegram: company?.links?.telegram?.number,
    whatsapp: company?.links?.whatsapp?.number,
    facebook: company?.links?.facebook?.link, 
    twitter: company?.links?.twitter?.handle, 
    instagram: company?.links?.instagram?.username, 
    tiktok: company?.links?.tiktok?.username, 
    youtube: company?.links?.youtube?.channel, 
    website: company?.links?.website?.link
  }

  return  <div id={company.id} className={Style.card}>
            <PictureAndInfo company={company}/> 
            <Links links={links}/>
          </div>
};

export default ManagementCard;
