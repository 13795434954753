import Style from './_Website.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import {BiWorld} from 'react-icons/bi';

const Website = () => {

  const methods = useFormContext();
  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Sitio Web</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <TextField 
                label='Liga de Sitio Web' 
                type='text'
                placeholder='http(s)://(www.)miSitioWeb.com'
                {...methods.register('links.website.link', {
                  value: methods.getValues('links.website.link'),
                  pattern: {
                    value: /https?:\/\/.+/,
                    message: 'El link no es valido'
                  }
                })}
                inputProps={{
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <BiWorld />
                    </InputAdornment>
                  )
                }}/> 
            </div> 
          </div>
        </div>
}

export default Website;
