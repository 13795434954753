import {getAuth} from 'firebase/auth'
import {getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject} from 'firebase/storage'
import {getFirestore, doc, setDoc} from 'firebase/firestore'
import {getResizedImages, getPictureExtension} from 'components/common/picture-utils'

const updateProduct = async (companyId, record) => {
  const storage = getStorage()
  const firestore = getFirestore()

  try {
    var prodRef = doc(firestore, `users/${getAuth().currentUser.uid}/companies/${companyId}/products/${record.id}`)

    delete record.id

    console.log(record.picture)
    if (record?.picture && record.picture.length > 0) {
      const newPicture = record.picture[0]

      delete record.picture
      
      const productPicturesRef = ref(storage, prodRef.path)
      const productPictures = await listAll(productPicturesRef) 
      productPictures.items.forEach(async (itemRef) => {
        await deleteObject(itemRef)
      })

      record['picture'] = {original: prodRef.path + '/original' + getPictureExtension(newPicture.name)}
      record.picture.small = prodRef.path + '/small' + getPictureExtension(newPicture.name) 
      record.picture.medium = prodRef.path + '/medium' + getPictureExtension(newPicture.name)

      const resizedImages = getResizedImages(newPicture)
      console.log(resizedImages)

      var pictureRef = ref(storage, record.picture.original)
      await uploadBytes(pictureRef, newPicture)
      record.picture.original = await getDownloadURL(pictureRef)
      console.log('original picture saved!')

      var smallPictureRef = ref(storage, record.picture.small)
      await uploadBytes(smallPictureRef, resizedImages.small)
      record.picture.small = await getDownloadURL(smallPictureRef)
      console.log('small picture saved!')

      var mediumPictureRef = ref(storage, record.picture.medium)
      await uploadBytes(mediumPictureRef, resizedImages.medium)
      record.picture.medium = await getDownloadURL(mediumPictureRef)
      console.log('medium picture saved!')
    } else {
      delete record.picture
    }

    delete record.currentPicture

    await setDoc(prodRef, record, {merge: true})
    console.log('doc saved!')

  } catch (e){
    console.log(e);
  }
}

export {updateProduct};
