import Style from './_Youtube.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import {IoLogoYoutube} from 'react-icons/io5';

const Youtube = () => {

  const methods = useFormContext();
  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Youtube</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <TextField 
                label='Liga de Canal de Youtube' 
                type='text'
                placeholder='https://www.youtube.com/...'
                {...methods.register('links.youtube.channel', {
                  value: methods.getValues('links.youtube.channel'),
                  pattern: {
                    value: /https:\/\/w{3}\.youtube\.com\/.+/,
                    message: 'La liga del canal no es valida'
                  }
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <IoLogoYoutube />
                    </InputAdornment>
                  )
                }}/> 
            </div> 
          </div>
        </div>
}

export default Youtube;
