import {useDispatch} from 'react-redux'
import {setDeliveryLocationToCurrent, setDeliveryLocationToAlternative} from 'redux/app/shopping-cart/shoppingCartSlice'
import {useState, useEffect} from 'react'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, makeStyles, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import XicohtzincoMap from 'components/common/map/XicohtzincoMap'
import {markCurrentLocation} from 'components/common/map/location-utils'

import Style from './_SelectionMapModal.module.sass'

const SelectionMapModal = ({open, setOpen, option}) => {

  const dispatch = useDispatch()
  const [marker, setMarker] = useState(null)
  const [isInTown, setIsInTown] = useState(true)
  const [showSnack, setShowSnack] = useState(false)
  console.log(isInTown)

  const useStyles = makeStyles({
    paper: {width: '100%', height: '100%'}
  })
  const classes = useStyles()

  useEffect(() => {
      if (option === 'current')
        setMarker(null)

      markCurrentLocation(setMarker)
  }, [option])

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway')
      return

    setShowSnack(false)
  }
  return (
    <div className={Style.selectionMapModal}>
      <Dialog open={open} classes={{paper: classes.paper}}>
        <DialogTitle>
            <p className={Style.title}>Selecciona la ubicacion</p>
        </DialogTitle>

        <DialogContent style={{padding: '0px'}}>
          <div className={Style.content}>
            {
            option === 'current' ?
            <div className={Style.buttons}>
              <Button color='primary' variant='contained' onClick={() => markCurrentLocation(setMarker)}>
                Tomar mi ubicacion actual
              </Button>
            </div>
              :
            <p className={Style.instructions}>Arrastra el marcador a la ubicacion de entrega alterna</p>
            }
            <XicohtzincoMap 
              marker={marker} 
              setMarker={setMarker}
              isInTownSetter={setIsInTown} 
              draggableMarker={option === 'alternative'? true : false}/>
          </div>
        </DialogContent>

        <DialogActions className={Style.actionButtons}>
          <Button 
            color='primary'
            variant='contained'
            onClick={() => {
              if (!marker) {
                setOpen(false)
                return
              }
              
              if (!isInTown) {
                setShowSnack(true)
                return
              }

              option === 'current' ?
                dispatch(setDeliveryLocationToCurrent(marker)) :
                dispatch(setDeliveryLocationToAlternative(marker))

              setOpen(false)
          }}>
            Aceptar
          </Button>
          <Button onClick={() => setOpen(false)}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        open={showSnack}
        onClose={closeSnack}
        anchorOrigin={{vertical: 'top', horizontal: 'center'}}>

        <MuiAlert 
          elevation={1}
          variante='filled'
          severity='error'
          onClose={closeSnack} >
            <p>Por el momento solo contamos con servicio en Xicohtzinco</p>
        </MuiAlert>
      </Snackbar>
    </div>
  )
};

export default SelectionMapModal ;
