import Style from "./_ProfileMenuButton.module.sass";
import {makeStyles} from '@material-ui/core'
import {ImUser} from 'react-icons/im'
import {FaStore} from 'react-icons/fa'
import {MdAdminPanelSettings} from 'react-icons/md'
import {ReactComponent as Truck} from './img/truck.svg'
import {useNavigate} from 'react-router-dom'
import {Button} from '@material-ui/core'
import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab'
import {getAuth, signOut} from 'firebase/auth'
import {useSelector, useDispatch} from 'react-redux'
import {signInAnonymousUser, changeRole, Role} from 'redux/app/account/authSlice'
import {onLoad} from './picture-utils'
//import {resetNotifications} from 'notifications'
import {getFirestore, doc, setDoc, arrayRemove} from 'firebase/firestore'
import {firebaseMessagingToken} from 'notifications'

const ProfileMenuButton = () => {
  const isSignedInUserAnonymous = useSelector(state => state.auth.user.isAnonymous)
  const userName = useSelector(state => state.auth.user.name)
  const userPicture = useSelector(state => state.auth.user.pictureUrl)
  const role = useSelector(state => state.auth.user.role)

  const hiredSince = useSelector(state => state.auth.user.deliveryGuy.employeeSince)
  const hiredUntil = useSelector(state => state.auth.user.deliveryGuy.employeeUntil)
  const isAdmin = useSelector(state => state.auth.user.isAdmin)
  
  const ownerCompanies = useSelector(state => state.auth.user.owner.companies)

  const dispatch = useDispatch()

  const useStyles = makeStyles({
    toggleButton: {backgroundColor: 'blue', padding: '3px 17px'},
    toggleButtonGroup: {backgroundColor: 'darkblue'}
    }
  )

  const classes = useStyles()
  const navigate = useNavigate()

  return  (
    <div className={Style.profileMenuButton}>
      <div className={Style.profileButton}>
        <div className={Style.picture}>
          <div className={Style.pictureCircle}>
            <div className={Style.pictureContainer}>
              {
                userPicture ? 
                  <img 
                  className={Style.pictureImg}
                  onLoad={e => onLoad(e, 65)}
                  src={userPicture} alt='Imagen de usuario'/> :
                  <ImUser size='75px' color='darkblue' />
              }
            </div>
          </div>
        </div>
        <div className={Style.userName}>
          {
            isSignedInUserAnonymous ?
              'Invitado' :
              userName
          }
        </div>
        {
          ((hiredSince && !hiredUntil) || 
           (ownerCompanies && Object.keys(ownerCompanies).length > 0) ||
           (isAdmin)) && 
          !isSignedInUserAnonymous &&
            <div className={Style.userName}>
              <ToggleButtonGroup 
                exclusive
                value={role}
                onChange={(e, v) => {
                  if (!v) {
                    return
                  }

                  dispatch(changeRole(v))
                }}
                classes={{root: classes.toggleButtonGroup}}>
              >
                <ToggleButton 
                  value={Role.CLIENT}
                  classes={{root: classes.toggleButton}}>
                  <ImUser size='19px' color='white' />
                </ToggleButton>
                {
                  ownerCompanies && Object.keys(ownerCompanies).length > 0 &&
                    <ToggleButton 
                      value={Role.OWNER}
                      classes={{root: classes.toggleButton}}>
                      <FaStore size='18px' color='white' />
                    </ToggleButton>
                }
                {
                  hiredSince && !hiredUntil &&
                    <ToggleButton 
                      value={Role.DELIVERY_GUY}
                      classes={{root: classes.toggleButton}}>
                      <Truck className={Style.truckIcon} color='white' />
                    </ToggleButton>
                }
                {
                  isAdmin &&
                    <ToggleButton 
                      value={Role.ADMIN}
                      classes={{root: classes.toggleButton}}>
                      <MdAdminPanelSettings size='19px' color='white' />
                    </ToggleButton>
                }
              </ToggleButtonGroup>
            </div>
        }
        <div className={Style.login}>
          {
            isSignedInUserAnonymous ?
            <Button 
              size='small' 
              variant='text' 
              color='primary' 
              onClick={() => navigate('/account/auth')}
            >
              Entrar
            </Button> :
            <Button 
              size='small' 
              variant='text' 
              color='primary' 
              onClick={async () => {
                try {
                  //resetNotifications()
                  const msgToken = firebaseMessagingToken
                  if (msgToken)
                    await setDoc(doc(getFirestore(), 'users', getAuth().currentUser.uid), {msgToken: arrayRemove(msgToken)}, {merge: true}) 

                  await signOut(getAuth())
                  dispatch(signInAnonymousUser())
                  dispatch(changeRole(Role.CLIENT))
                  console.log('User signed out')
                  navigate('/', {replace: true})
                } catch (e) {
                  console.log('Sign user out failed', e)
                } 
              }}
            >
              Salir
            </Button> 
          }
          
        </div>
      </div>
    </div>
  )
};

export default ProfileMenuButton;
