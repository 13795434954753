import ProductTypes from './ProductTypes'
import Style from './_CompanyCard.module.sass'

const CompanyCard = ({order, subOrder, statusColor, stopInfo}) => {

  return (
    <div className={Style.stop}>
      <div className={Style.stopNumber} style={{backgroundColor: statusColor}}>
        {stopInfo.stopNumber}
      </div>
      <div className={Style.companyCard} >
        <div className={Style.nameAndType}>
          <p className={Style.name}>
            {subOrder.companyName} 
          </p>
          <p className={Style.type}>
            {subOrder.companyType}
          </p>
        </div>
        <ProductTypes productTypes={subOrder.products} />
        <div className={Style.count}>
          <p className={Style.productCount}>
            Productos: {subOrder.productCountPerCompany}
          </p>
          <div className={Style.subtotal}>
            Subtotal: {subOrder.subTotalPerCompany}
            <div className={Style.tipoMoneda}> MXN </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompanyCard
