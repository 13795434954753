import Style from './_Location.module.sass';
import {Button} from '@material-ui/core';
import {markCurrentLocation} from 'components/common/map/location-utils';
import {useFormContext} from 'react-hook-form';
import {useState} from 'react';
import XicohtzincoMap from 'components/common/map/XicohtzincoMap';

const Location = () => {
  const {register, getValues, setValue} = useFormContext();
  const [isInTown, setIsInTown] = useState(true);

  const coordsReg = register('location.coords', {
    validate: {
      required: value => value?.length > 0 || 'La ubicacion es necesaria',
      inScope: value => isInTown || 'Solo disponible en Xicohtzinco'
    }
  })
  const previousLocationField = getValues(coordsReg.name);
  const previousLocationJSON = previousLocationField ? JSON.parse(previousLocationField) : null;
  const [marker, setMarker] = useState(previousLocationJSON);

 return <div className={Style.general}>
    {/*<div id='advice' className={`${Style.advice} ${isInTown ? "" : Style.advice2}`}>
            { isInTown ? '' : 'Por el momento solo disponible en Xicohtzinco' }
          </div>*/}
          <div className={Style.stepName}>
            <h4>Ubicacion</h4>
          </div>

          <br/>
          <p className={Style.description}>Para agregar su ubicacion es necesario que se encuentre fisicamente en su negocio, ya que la ubicacion es tomada del GPS de su celular.</p>

          <br/>
          
          <div className={Style.fields}>
            <div className={Style.permission}>
              <Button 
                color='primary' 
                variant='contained' 
                size='small'
                onClick={(e)=>markCurrentLocation(setMarker, setValue, coordsReg)}>Tomar mi ubicacion actual</Button>
              <br/> 
              <input 
                type='hidden'
                id='current-location-coords' 
                onChange={coordsReg.onChange}
                onBlur={coordsReg.onBlur}
                value={coordsReg.value}
                ref={coordsReg.ref}/>
            </div>
          </div>
          <XicohtzincoMap marker={marker} isInTownSetter={setIsInTown}/>
        </div>
}

export default Location;
