import {getAuth} from 'firebase/auth'
import {getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject} from 'firebase/storage'
import {getFirestore, collection, doc, setDoc, getDoc, getDocs} from 'firebase/firestore'
import {getResizedImages, getPictureExtension} from 'components/common/picture-utils'

const updateBusiness = async (record) => {
  try {
    const firestore = getFirestore()
    const storage = getStorage()

    const bizRef = doc(firestore, `users/${getAuth().currentUser.uid}/companies/${record.id}`)

    delete record.id

    console.log(record.picture)
    if (record?.picture && record.picture.length > 0) {
      const newPicture = record.picture[0]

      delete record.picture

      const companyPicturesRef = ref(storage, bizRef.path)
      const companyPictures = await listAll(companyPicturesRef)
      companyPictures.items.forEach(async (itemRef) => {
        await deleteObject(itemRef)
      })

      record['picture'] = {original: bizRef.path + '/original' + getPictureExtension(newPicture.name)}
      record.picture.small = bizRef.path + '/small' + getPictureExtension(newPicture.name) 
      record.picture.medium = bizRef.path + '/medium' + getPictureExtension(newPicture.name)

      const resizedImages = getResizedImages(newPicture)
      console.log(resizedImages)

      var pictureRef = ref(storage, record.picture.original)
      await uploadBytes(pictureRef, newPicture);
      record.picture.original = await getDownloadURL(pictureRef)
      console.log('original picture saved!')

      var smallPictureRef = ref(storage, record.picture.small)
      await uploadBytes(smallPictureRef, resizedImages.small)
      record.picture.small = await getDownloadURL(smallPictureRef)
      console.log('small picture saved!')

      var mediumPictureRef = ref(storage, record.picture.medium)
      await uploadBytes(mediumPictureRef, resizedImages.medium)
      record.picture.medium = await getDownloadURL(mediumPictureRef)
      console.log('medium picture saved!')
    } else {
      delete record.picture
    }

    delete record.currentPicture

    const bizSS = await getDoc(bizRef)
    const bizData = bizSS.data()

    await updateCompanyProducts(bizSS.id, bizData, record)

    await setDoc(bizRef, record, {merge: true})
    console.log('doc saved!')

  } catch (e){
    console.log(e);
  }
}

const updateCompanyProducts = async (companyId, dbCompany, record) => {
  console.log('updating company products to reflect openHours changes')
  const firestore = getFirestore()

  const updatedCompanyInfo = {}

  if (dbCompany.name !== record.name)
    updatedCompanyInfo.companyName = record.name

  if (dbCompany.location.coords !== record.location.coords)
    updatedCompanyInfo.companyLocation = JSON.parse(record.location.coords)

  if (record?.picture?.small)
    updatedCompanyInfo.companyPicture = record.picture.small

  if (dbCompany.type !== record.type)
    updatedCompanyInfo.companyType = record.type

  const days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
  const openHoursChanged = days.find(day => checkDaySchedule(dbCompany, record, day))
  if (openHoursChanged)
    updatedCompanyInfo.openHours = record.openHours

  const productsCollection = collection(firestore, `users/${getAuth().currentUser.uid}/companies/${companyId}/products`)
  const productsQS  = await getDocs(productsCollection)

  productsQS.forEach(async (productDocument) => {
    await setDoc(productDocument.ref, updatedCompanyInfo, {merge: true})
  })
}

const checkDaySchedule = (dbCompany, record, day) => {
 return (dbCompany.openHours && dbCompany.openHours[day] && record.openHours[day] &&
        (dbCompany.openHours[day].enabled !== record.openHours[day].enabled ||
        dbCompany.openHours[day].from !== record.openHours[day].from ||
        dbCompany.openHours[day].to !== record.openHours[day].to))
}

export {updateBusiness};
