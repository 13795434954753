import Style from './_Backdrop.module.sass'
import {CircularProgress} from '@material-ui/core'

const Backdrop = ({enabled}) => {
  return (
    <div className={Style.backdrop} style={{display: enabled ? 'flex' : 'none'}}>
      <CircularProgress color='primary'/>
    </div>
  )
}

export default Backdrop
