import Style from './_Info.module.sass'
import {AiOutlineDelete} from 'react-icons/ai'
import {BiPencil} from 'react-icons/bi'
import {deleteProduct} from './product-management-utils'
import {useNavigate, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {turnOn, turnOff} from 'redux/app/backdropSlice'

const Info = ({info}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  return    <div className={Style.cardInfo} onClick={()=>console.log("info clicked!")}>
              <div className={Style.priceRow}>
                <div className={Style.price}>
                  {`$ ${info.price}`}
                  <div className={Style.tipoMoneda}> MXN </div>
                </div>
              </div>

              <div className={Style.nombre}>
                <h4>{info.name}</h4>
              </div>
              <div className={Style.description}>
                <p>{info.description}</p>
              </div>
              <div className={Style.editButton} 
                   onClick={
                     ()=>navigate(`/account/companies/${info.companyId}/products/${info.productId}/update`, {state: location.state})
                   }>
                <BiPencil color='white' size='25px'/>
              </div>
              <div className={Style.deleteButton} 
                   onClick={()=>{
                     dispatch(turnOn())
                     deleteProduct(info.companyId, info.productId)
                     dispatch(turnOff())
                   }}>
                <AiOutlineDelete color='white' size='25px'/>
              </div>
              <div className={Style.toast}>
              </div>
            </div>
};

export default Info;
