import {initializeApp} from 'firebase/app'
import {getAuth, setPersistence, browserLocalPersistence, connectAuthEmulator} from 'firebase/auth'
import {getStorage, connectStorageEmulator} from 'firebase/storage'
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
}

const initializeFirebase = () => {
  console.log(new Date(), 'initializing firebase', firebaseConfig)
  console.log(process.env)

  initializeApp(firebaseConfig)
	setPersistence(getAuth(), browserLocalPersistence)

  if (window.location.hostname === "localhost") {
    connectAuthEmulator(getAuth(), 'http://localhost:9099')
    connectFirestoreEmulator(getFirestore(), "localhost", 8080)
    connectStorageEmulator(getStorage(), "localhost", 9199)
  }

  console.log(new Date(), ' after product initialization')
}

export {initializeFirebase}
