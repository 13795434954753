import ProductCard from 'components/account/companies/products/product-card/ClientOrdersProductCard' 
//import Style from './_ProductTypes.module.sass' 

const ProductTypes = ({productTypes}) => {

  return <div>
          {
            productTypes.map(productType => {
              return <ProductCard key={productType.product.id}  hit={productType} /> 
            })
          }
        </div>
};

export default ProductTypes;
