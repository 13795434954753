import Style from "./_Info.module.sass";
import {useNavigate, useLocation} from 'react-router-dom'
import {useState} from 'react'

const Info = ({company}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showToast, setShowToast] = useState(false)

  //console.log(company)
  return    <div className={Style.cardInfo} 
                 onClick={()=>{
                    if(company.ps_count === 0) {
                      console.log('no products to show')
                      setShowToast(true)
                      setTimeout(()=>setShowToast(false), 1000)
                      return
                    } 
                      
                    if(location.pathname !== `/company/${company.objectID}`)
                      navigate(`/company/${company.objectID}`, {state: {from: location.pathname, company}})

                 }}>
              <div className={Style.nombre}>
                <h4>{company.name}</h4>
              </div>
              <div className={Style.presentacionNegocio}>
                <p>{company.description}</p>
              </div>
              <div className={`${Style.toast} ${showToast && Style.toast2}`}
                    style={{right: `${(window.innerWidth / 2) - 44}px` }}>Sin productos</div>
            </div>
};

export default Info;
