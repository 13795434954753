import {createSlice} from '@reduxjs/toolkit'
import {initialState, Role} from './initialState'

const authSlice = createSlice({
  name: 'authSlice',
  initialState: initialState,
  reducers: {
    signInRegisteredUser: (state, {payload}) => { 
      state.user.isAnonymous = false
      state.user.name = payload.name
      state.user.pictureUrl = payload.pictureUrl
      state.user.owner.companies = payload?.owner?.companies
      state.user.deliveryGuy.employeeSince = payload?.deliveryGuy?.employeeSince
      state.user.deliveryGuy.employeeUntil = payload?.deliveryGuy?.employeeUntil
      state.user.isAdmin = payload.isAdmin
    },
    signInAnonymousUser: (state, {payload}) => { 
      state.user.isAnonymous = true
      state.user.name = ''
      state.user.pictureUrl = ''
      state.user.owner.companies = {}
      state.user.deliveryGuy.employeeSince = ''
      state.user.deliveryGuy.employeeUntil = ''
    },
    changeRole: (state, {payload}) => {
      state.user.role = payload
    },
    updateCompanies: (state, {payload}) => {
      state.user.owner.companies = payload?.owner?.companies
    }
  }
})

export default authSlice.reducer
export const {signInRegisteredUser, signInAnonymousUser, changeRole, updateCompanies} = authSlice.actions
export {Role}


