import Style from './_Facebook.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import {GrFacebook} from 'react-icons/gr';

const Facebook = () => {

  const methods = useFormContext();
                    console.log(methods.getValues('links.facebook.link') || 'asdf');

  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Facebook</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <Controller 
                control={methods.control}
                name='links.facebook.link'
                rules={{
                  pattern: {
                    value: /https:\/\/w{3}\.facebook\.com\/.+/,
                      message: 'La liga no es valida'
                    }
                }}
                render={({field})=>(
                  <TextField 
                    label='Liga de Facebook' 
                    type='text'
                    placeholder='https://www.facebook.com/...'
                    value={field.value || ''}
                    onChange={field.onChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <GrFacebook />
                        </InputAdornment>
                      )
                    }}/> 
                )}/>
            </div> 
          </div>
        </div>
}

export default Facebook;
