import { configureStore } from '@reduxjs/toolkit'
import authSliceReducer from './app/account/authSlice'
import phoneAuthSliceReducer from './app/account/phoneAuthSlice'
import backdropReducer from './app/backdropSlice'
import zoomReducer from './app/zoomSlice'
import shoppingCartReducer from './app/shopping-cart/shoppingCartSlice'

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    phoneAuth: phoneAuthSliceReducer,
    backdrop: backdropReducer,
    zoom: zoomReducer,
    shoppingCart: shoppingCartReducer
  }
});

export default store;
