import Style from './_SubOrderCard.module.sass'
import {useState, useEffect} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'

const SubOrderCard = ({subOrder, color}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const company = location.state.company
  const [time, setTime] = useState()

  useEffect (() => {
    const timer = setInterval(() => {
      const time = Math.floor((Date.now() - subOrder.timestamp.toMillis()))

      const seconds = Math.floor(time / 1000 % 60).toString().padStart(2, '0')
      const minutes = Math.floor(time / 1000 / 60 % 60).toString().padStart(2, '0')
      const hours = Math.floor(time / 1000 / 60 / 60 % 24).toString().padStart(2, '0')

      setTime(`${hours} : ${minutes} : ${seconds}`)
    }, 1000)

    return () => clearTimeout(timer)
  },[subOrder.timestamp])

  return (
    <div className={Style.orderCard} 
         style={{backgroundColor: color}}
         onClick={() => navigate(`/suborders/company/${company.companyId}/suborder/${subOrder.id}`, {state: {subOrder, company}})}>
      <div className={Style.info}>
        <div>
          {subOrder.productCountPerCompany} Productos
        </div>
        <div>
          {subOrder.subTotalPerCompany} 
          <div className={Style.tipoMoneda}> MXN </div>
        </div>
      </div>
      <div className={Style.time}>
        <div className={Style.trackingId}>
          Id:{subOrder.timestamp.seconds}
        </div>
        <div>
          Tiempo en cola: {time}
        </div>
      </div>
    </div>
  )
}

export default SubOrderCard
