import TitleBar from 'components/common/TitleBar';
import Content from 'components/common/Content';
import FloatingButton from 'components/common/FloatingButton';
import {AiOutlineHome} from 'react-icons/ai';

import Style from './_Terms.module.sass';

const Terms = () => {
  return  <div>
            <TitleBar>
              <h4 className={Style.title}>Terminos y Condiciones</h4>
            </TitleBar>
            <Content>
              <div className={Style.terms}>
<p>TIDUCX SAS de CV es una empresa legalmente constituida conforme a las leyes de la República Mexicana.</p>

<p>Misma que ha desarrollado la solución ManduX-T de paquetería local a través de la cual presta sus servicios a comercios locales para uso del usuario donde solicite envío y entrega de productos a domicilio.</p>

<p>Lea estas condiciones detenidamente antes de acceder o usar los servicios de la aplicación.</p>

<h4>Términos y condiciones</h4>

<p>Los presentes términos y condiciones generales regulan el acceso y uso que usted haga sobre la aplicación móvil denominada ManduX-T, así como de todos los contenidos, información, servicios y funciones disponibles a través de los mismos. Si usted no desea sujetarse a lo dispuesto en estos términos y condiciones, no podrá acceder o usar los servicios.</p> 

<h4>Definiciones:</h4>

  <ul>
    <li>
    <strong>Consumidor:</strong> quién accede y usa la plataforma para solicitar por medio de esta un mandato, cuyo encargo consiste en la celebración de un contrato de compraventa o cualquier otro tipo de contrato lícito, con el fin de adquirir bienes o servicios.
    </li>

    <li>
    <strong>Vendedor:</strong> quien publica y ofrece sus productos en la aplicación.
    </li>
    
    <li>
    <strong>Repartidor:</strong> persona que entrega envíos o mercancías a domicilio.
    </li>

    <li>
      <strong>Operador:</strong> técnico encargado de manejar y hacer que el desempeño de la plataforma funcione correctamente.
    </li>

    <li>
    <strong>Producto(s):</strong> Exclusivamente aquellos bien(es) de consumo exhibido(s) a través de la aplicación.
    </li>
  </ul>

<h4>Disposiciones generales:</h4>

<p>Los servicios solo podrán ser utilizados por personas físicas mayores de edad quien manifestó contar con la capacidad suficiente para obligarse conforme a los presentes términos y condiciones.</p>

<p>Los servicios de paquetería solicitados por el cliente podrán ser utilizados únicamente con fines lícitos.</p>

<p>Todos los contenidos que Mandux-T pone al alcance del usuario a través de la aplicación contiene elementos amparados por nomás de propiedad intelectual, incluyendo protección de software, marcas, fotografías, logos, diseños, imágenes gráficas, música y sonido, sin que esta descripción implique limitación de naturaleza alguna.</p>

<p>En caso de que algún menor de edad haga uso de la aplicación, los padres o tutores serán responsables por los actos que ellos realicen según lo dispuesto por los términos y condiciones Incluyendo los daños causados a terceros, acciones realizadas por ellos y que estén prohibidas por ley y por disposiciones de acuerdo, sin perjuicio de la responsabilidad del usuario.</p>

<h4>Objetivo</h4>

<p>El uso de aplicación por parte de la sociedad y usuarios es una declaración formal sobre la aceptación de todos los términos y condiciones presentes en este documento.</p>

<p>Los términos y condiciones  en el presente documento están sujetas a cambio sin previo aviso a los vendedores y usuarios de la aplicación las cuales surtirán efecto a partir de su publicación buscando siempre mejorar la plataforma. </p>

<br/>
<h4>Uso de la aplicación</h4>
<br/>

<h4>Consumidor:</h4>

<p>Existen dos tipos de usuarios, el usuario que se registra con sus datos y crea una cuenta y  el usuario que solamente ingresa como  invitado, para el último solamente se aceptará pago en efectivo contra entrega.</p>

<p>Al ingresar como invitado ManduX-t No puede hacer válidas las garantías de compra y entrega, ya que no se cuenta con datos necesarios. </p>


<p>Al crear  una cuenta debe tomar en  cuenta que se solicitan datos como:  Nombre, teléfono, correo electrónico además de brindar una dirección o en su caso permitir el acceso a su ubicación; estos se utilizan para identificar a los consumidores que pretenden adquirir los productos para el cumplimiento de la compraventa, para la prevención de fraudes, para vincular a consumidor con el repartidor así como con el vendedor. Toda información se encuentra protegida por las políticas de privacidad.</p>

<p>Los consumidores que tengan cuentas cómo Facebook-Gmail podrán crear una cuenta de usuario con la que se utilizan credenciales definidas para lo cual la aplicación aplicará esta opción. </p>

<p>Las cuentas de usuarios serán administradas por el operador a su discreción.</p>

<p>El operador no solicitará al consumidor información que resulte innecesaria para su vinculación con el vendedor y para la facilitación de pago por lo que los datos de tarjeta de crédito o débito solo se solicitarán al momento de realizar el pago virtual si así lo determina el usuario, estos datos no serán almacenados por el operador, serán escritos directamente en la pasarela de pagos correspondientes y se utilizarán directamente por el usuario en esta donde se informará las condiciones de seguridad y privacidad en que se efectúa el mismo.</p>

<p>Al momento de la aceptación de los presentes términos y condiciones expresamente el consumidor autoriza el uso de las cookies por parte del operador en toda actividad de uso que realice con la aplicación. </p>

<p>Los consumidores ocupan como referencia para su compra los productos que se encuentran exhibidos en la aplicación.</p>

Tarifas 
<p>Dependerá del tiempo y distancia qué se encuentre el vendedor con el consumidor, dónde los consumidores pueden elegir entre pagar con tarjeta o pago en efectivo.</p>

<h4>Vendedor:</h4>

<p>El vendedor tendrá acceso a la aplicación para crear una cuenta, dónde cargar a la información relevante de los productos que ofrece para que el usuario solicité el servicio dentro de los horarios establecidos por el vendedor. De lo antes mencionado, ManduX-T no corrobora y verifica la información o detalles de los productos ofrecidos y no es responsable de servicio del vendedor por lo que la relación de la aplicación y el vendedor es totalmente mercantil.</p>

<p>Para que ManduX-T pueda brindar un servicio de calidad, el vendedor  autoriza revelar la información sin limitación, incluyendo logotipo y dirección con el fin de que los usuarios conozcan y confíen al realizar su pedido, así como también, que los repartidores conozcan la ubicación para garantizar la operación.</p>


<h4>Repartidor</h4>

<p>El repartidor se limita a realizar las entregas en tiempo y forma tomando en cuenta el tiempo de traslado de ambos puntos, además del tiempo que pueda tardar el vendedor en preparar o despachar sus productos, cabe mencionar que el repartidor tendrá como responsabilidad que el producto llegue en buen estado pero no  es responsable de algún incidente  vendedor. </p>

<p>El repartidor solicitará al momento de la entrega una identificación para corroborar que se está haciendo entrega del pedido a la persona indicada. </p>
  
<br/>
<br/>
<br/>
              </div>
            </Content>
            <FloatingButton
              position='bottom-right'
              to='/'>
              <AiOutlineHome style={{fill: 'white'}} size='25px'/>
            </FloatingButton>
              
          </div>
};

export default Terms;
