import {getGasolineCostPerKm} from "./gasoline-calc.js"
import {getMotorcycleCostPerKm} from "./motorcycle-calc.js"
import {getBaseDeliveryGuyCostPerMin} from "./base-delivery-guy-calc.js"
import {getHRCostPerMin} from "./admon/hr-calc.js"
import {getRentCostPerMin} from "./rent-calc.js"
import {getElectricityCostPerMin} from "./electricity-calc.js"
import {getPhoneCostPerMin} from "./phone-calc.js"
import {getAccountantCostPerMin} from "./accountant-calc.js"

const getCostPerKm = () => {
  return getGasolineCostPerKm() + 
          getMotorcycleCostPerKm()
}

const getCostPerMin = () => {
  return getBaseDeliveryGuyCostPerMin() +
          getHRCostPerMin() +
          getRentCostPerMin() +
          getElectricityCostPerMin() +
          getPhoneCostPerMin() +
          getAccountantCostPerMin()

}

// const main = () => {
//   console.log("\nCost per kilometer:\n")
// 
//   console.log("    Gasoline:", getGasolineCostPerKm())
//   console.log("  Motorcycle:", getMotorcycleCostPerKm())
// 
//   console.log("\n       Total:", getCostPerKm())
//   console.log("\n")
// 
//   console.log("Cost per minute:\n")
// 
//   console.log("Base delivery guy: ", getBaseDeliveryGuyCostPerMin())
//   console.log("   Administration: ", getHRCostPerMin())
//   console.log("             Rent: ", getRentCostPerMin())
//   console.log("      Electricity: ", getElectricityCostPerMin())
//   console.log("            Phone: ", getPhoneCostPerMin())
//   console.log("       Accountant: ", getAccountantCostPerMin())
// 
//   console.log("\n       Total:", getCostPerMin())
//   console.log()
// }
// 
// main();

export {getCostPerKm, getCostPerMin}
