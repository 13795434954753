import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {signInAnonymousUser, signInRegisteredUser} from 'redux/app/account/authSlice'
import {areNotificationsGranted, firebaseMessagingToken} from 'notifications'
import {getAuth, signInAnonymously, onAuthStateChanged} from 'firebase/auth'
import {getFirestore, doc, setDoc, getDoc, arrayUnion} from 'firebase/firestore'

const AuthenticationManagement = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const signInAnonymous = () => {
      console.log(new Date(), "No user available ");
      
        signInAnonymously(getAuth())
        .then(() => console.log(new Date(), 'Anonymous user logged in ...'))
        .catch(error => console.log(new Date(), 'Error logging in anonymous user: ', error.code, error.message)) 
    }
    console.log(new Date(), getAuth().currentUser)

    onAuthStateChanged(getAuth(), async (user) => {
      if (!user) {
        signInAnonymous()
        return
      }
      
      console.log(new Date(), "user: " + user.uid);
      console.log(new Date(), "isAnonymous?: " + user.isAnonymous);

      if (user.isAnonymous) {
        dispatch(signInAnonymousUser())
        return
      }
      
      const dbUserRef = doc(getFirestore(), 'users', user.uid)
      if (areNotificationsGranted) {
        const msgToken = firebaseMessagingToken
        console.log('######## user token, ', msgToken)
        await setDoc(dbUserRef, {msgToken:  arrayUnion(msgToken)}, {merge: true})
      }

      console.log(user)

      getDoc(dbUserRef)
        .then(doc => {
          const dbUser = doc.data() 
          
          console.log(dbUser)
          if (!dbUser)
            return

          const reduxUser = {
            name: user.providerData[0].displayName || 
									'(' + user.providerData[0].phoneNumber.slice(3, 6) + ') ' +
									user.providerData[0].phoneNumber.slice(6, 9) + ' - ' + 
									user.providerData[0].phoneNumber.slice(9) ,
            pictureUrl: user.providerData[0].photoURL,
          }

          if (dbUser.owner ) {
            reduxUser.owner = {
              companies: dbUser.owner.companies 
            }
          } 

          if (dbUser.deliveryGuy) {
            reduxUser.deliveryGuy = {
              employeeSince: dbUser.deliveryGuy.employeeSince ? 
                              new Date(dbUser.deliveryGuy.employeeSince.toMillis()).toString() : '',
              employeeUntil: dbUser.deliveryGuy.employeeUntil ? 
                              new Date(dbUser.deliveryGuy.employeeUntil.toMillis()).toString() : '',
            }
          }

          if (dbUser.isAdmin) {
            reduxUser.isAdmin = true
          }
        
          dispatch(signInRegisteredUser(reduxUser))
        })
    })

    //signInAnonymously()
  },[dispatch])

  return null
}

export default AuthenticationManagement
