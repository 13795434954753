import {ToggleButtonGroup, ToggleButton} from '@material-ui/lab'
import {Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core'
import {MdExpandMore} from 'react-icons/md'
import {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {updateDeliveryDetails} from 'redux/app/shopping-cart/shoppingCartSlice'
import SelectionMapModal from './SelectionMapModal'
import {loader} from 'components/common/map/location-utils'
import {getCostPerKm, getCostPerMin} from './pricing/builder'

import Style from './_DeliveryLocationSelection.module.sass'

const  DeliveryLocationSelection = () => {
  const dispatch = useDispatch()
  const mapRef = useRef()
  const deliveryLocationType = useSelector(state => state.shoppingCart.routeDetails.destination.type)
  const deliveryLocationMarker = useSelector(state => state.shoppingCart.routeDetails.destination.marker)
  const distance = useSelector(state => state.shoppingCart.routeDetails.distance)
  const duration = useSelector(state => state.shoppingCart.routeDetails.duration)
  const productsPerCompany = useSelector(state => state.shoppingCart.productsPerCompany)

  useEffect(() => {
    productsPerCompany.length > 0 && deliveryLocationType !== 'none' &&
    loader
      .load()
      .then(() => {
        const origins =  productsPerCompany.map(company => ({location: company.location}))
        const destination = [deliveryLocationMarker]

        const distanceMatrixService = new window.google.maps.DistanceMatrixService()
        const distanceMatrixRequest = {
          origins: origins,
          destinations: destination,
          travelMode: 'DRIVING'
        }
        distanceMatrixService.getDistanceMatrix(distanceMatrixRequest, (result, status) => {
          if (status === 'OK') {

            let farthestDistance = 0
            let farthestPointIndex = -1

            result.rows.forEach((row, index) => {
              //console.log('from ', result.originAddresses[index], 
              //            ' to ', result.destinationAddresses[0], ': ', 
              //            row.elements[0].distance.value, 'm')

              if (row.elements[0].distance.value > farthestDistance) {
                farthestDistance = row.elements[0].distance.value
                farthestPointIndex = index
              }
            })

            console.log('farthest point distance: ', farthestDistance, ', index: ', farthestPointIndex)
            const directionsService = new window.google.maps.DirectionsService()
            
            const directionsRenderer = new window.google.maps.DirectionsRenderer()
            const map = new window.google.maps.Map(mapRef.current, {zoom: 14})
            directionsRenderer.setMap(map)

            let waypoints = origins.slice()
            waypoints.splice(farthestPointIndex, 1)

            const directionsRequest = {
              origin: origins[farthestPointIndex],
              destination: deliveryLocationMarker,
              travelMode: 'DRIVING',
              waypoints: waypoints,
              optimizeWaypoints: true
            }

            directionsService.route(directionsRequest, (result, status) => {
              if (status === 'OK') {
                directionsRenderer.setDirections(result)
                const legs = result.routes[0].legs
                const distanceReducer = (accumulator, route) => accumulator + route.distance.value
                const durationReducer = (accumulator, route) => accumulator + route.duration.value
                
                const distanceKms = (legs.reduce(distanceReducer, 0) / 1000).toFixed(1)
                const durationMins = Math.ceil(legs.reduce(durationReducer, 0) / 60) + 10

                const companies = productsPerCompany.map(company => ({id: company.id, 
                                                                      location: company.location})) 
                const stopOrder = [] 
                result.routes[0].waypoint_order.forEach(waypoint => {
                  stopOrder
                    .push(companies
                            .find(company => company.location.lat === waypoints[waypoint].location.lat && 
                                               company.location.lng === waypoints[waypoint].location.lng
                            )
                    )
                })

                let distanceCost = distanceKms * getCostPerKm()
                distanceCost = Math.ceil(distanceCost)
                console.log(distanceCost)

                let timeCost = (durationMins - 3.7) * getCostPerMin()
                timeCost = Math.ceil(timeCost)
                console.log(timeCost)

                const deliveryPrice = (distanceCost + timeCost).toLocaleString('es-MX', {style: 'currency', currency: 'MXN'})
                console.log(deliveryPrice)

                dispatch(updateDeliveryDetails({
                                                origin: origins[farthestPointIndex], 
                                                distance: distanceKms, 
                                                duration: durationMins,
                                                stopOrder: stopOrder,
                                                directions: JSON.stringify(result),
                                                deliveryPrice: deliveryPrice
                                              }))
              }

            })
          }
        })
      })
  }, [deliveryLocationMarker, 
      productsPerCompany, 
      dispatch, 
      deliveryLocationType])

  const [modalOpen, setModalOpen] = useState(false)
  const [option, setOption] = useState(deliveryLocationType)

  return (
  <div>
    <div className={Style.locationSelection}>
      <div className={Style.label}>
        <p>Ubicacion para entrega:</p>
      </div>
      <div className={Style.options}>
        <ToggleButtonGroup 
          exclusive 
          value={deliveryLocationType}
          onChange={(e, v) => {
            console.log(e, v)
            if (v === null)
              setOption(deliveryLocationType)
            else
              setOption(v)

            setModalOpen(true)
          }}
          size='small' >
          <ToggleButton value='current'>
            Actual
          </ToggleButton>
          <ToggleButton value='alternative'>
            Alterna
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
    {
    productsPerCompany.length > 0 && deliveryLocationType !== 'none' &&
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<MdExpandMore />}>
          <div className={Style.routeInfo}>
            <p className={Style.routeDetails}>Tiempo de recorrido (aprox): {duration} mins</p>
            <p className={Style.routeDetails}>Distancia: {distance} Km</p>
            <p className={Style.warning}>El tiempo de preparacion de sus productos no esta incluido y es variable deacuerdo a las capacidades de los negocios</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div ref={mapRef} style={{height: `${window.innerHeight/2}px`, width: '100%'}} />
        </AccordionDetails>
      </Accordion>
    </div>
    }
    <SelectionMapModal open={modalOpen} setOpen={setModalOpen} option={option}/>
  </div>
  )
};

export default DeliveryLocationSelection;
