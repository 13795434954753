import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {AiOutlineHome} from 'react-icons/ai'
import {getAuth} from 'firebase/auth'
import {listenForSubOrdersByCompany} from './order-utils'
import {useEffect, useState} from 'react'
import CompanySubOrdersCard from './CompanySubOrdersCard'

import Style from './_SubOrders.module.sass'

const SubOrders = () => {
  const [subOrdersPerCompany, setSubOrdersPerCompany] = useState([])

  useEffect(() => {
    const detachListener = listenForSubOrdersByCompany(getAuth().currentUser.uid, setSubOrdersPerCompany)
    return detachListener
  },[])

  return (
    <div>
      <TitleBar>
        <h4 className={Style.title}>Ventas por negocio</h4>
      </TitleBar>
      <Content>
        {
          subOrdersPerCompany.map(subOrder => {
            return <CompanySubOrdersCard key={subOrder.companyId} company={subOrder} />
          })
        }
        
        <FloatingButton
          position='bottom-right'
          to='/'>
          <AiOutlineHome style={{fill: 'white'}} size='26px' />
        </FloatingButton>
      </Content>
    </div>
  )
};

export default SubOrders
