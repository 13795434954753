import OpenHoursDay from './OpenHoursDay'
import {useFormContext} from 'react-hook-form'
import {useEffect} from 'react'

import Style from './_OpenHours.module.sass';

const OpenHours= () => {
  const {register, getValues, setValue} = useFormContext()

  useEffect (() => {
    register('any.openHoursDay.enabled', {
      validate: valuea => {
        let isValid = getValues(`openHours.Lunes.enabled`) ||
                  getValues(`openHours.Martes.enabled`) ||
                  getValues(`openHours.Miercoles.enabled`) ||
                  getValues(`openHours.Jueves.enabled`) ||
                  getValues(`openHours.Viernes.enabled`) ||
                  getValues(`openHours.Sabado.enabled`) ||
                  getValues(`openHours.Domingo.enabled`)

        return isValid || 'Proporcionar al menos un dia de servicio'
      }
    })

    //setValue('openHours.valid','noop')
  }, [register, getValues, setValue])

  return  <div className={Style.general}>
            <div className={Style.stepName}>
              <h4>Horario de servicio</h4>
            </div>
            
            <OpenHoursDay dayOfWeek='Lunes' />
            <OpenHoursDay dayOfWeek='Martes' />
            <OpenHoursDay dayOfWeek='Miercoles' />
            <OpenHoursDay dayOfWeek='Jueves' />
            <OpenHoursDay dayOfWeek='Viernes' />
            <OpenHoursDay dayOfWeek='Sabado' />
            <OpenHoursDay dayOfWeek='Domingo' />
          </div>
};

export default OpenHours;
