import Style from './_Info.module.sass'
import { ReactComponent as Truck } from './img/truck.svg'
import {AiOutlineDelete} from 'react-icons/ai'
import {BiPencil} from 'react-icons/bi'
import {MdPlaylistAdd} from 'react-icons/md'
import {deleteCompany} from './management-utils'
import {useNavigate} from 'react-router-dom'

import {useDispatch} from 'react-redux'
import {turnOn, turnOff} from 'redux/app/backdropSlice'
import {updateCompanies, changeRole} from 'redux/app/account/authSlice'

const Info = ({company}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return    <div className={Style.cardInfo} >
              <div className={Style.servicioDomicilioTipoNegocio}>
                <div className={Style.servicioDomicilio}>
                  {company.hasDelivery ? <Truck /> : ''}
                </div>
                <div className={Style.tipoNegocio}>
                  {company.type}
                </div>
              </div>
              <div className={Style.nombre}>
                <h4>{company.name}</h4>
              </div>
              <div className={Style.presentacionNegocio}>
                <p>{company.description}</p>
              </div>
              <div className={Style.adminPnsButton} 
                onClick={()=>{
                  navigate(`/account/companies/${company.id}/products`, {state: company})
                }}>
                <MdPlaylistAdd color='white' size='25px' />
              </div>
              <div className={Style.editButton} 
                   onClick={() => {
                     navigate(`/account/companies/${company.id}/update/identity`)
                }}>
                <BiPencil color='white' size='25px'/>
              </div>
              <div className={Style.deleteButton} 
                   onClick={async () => {
                     dispatch(turnOn)

                     const  {updatedUser} = await deleteCompany(company.id)
                     dispatch(updateCompanies(updatedUser))

                     console.log(updatedUser)

                     if (!updatedUser?.owner?.companies || Object.keys(updatedUser.owner.companies).length === 0) {
                       navigate(`/`, {replace: true})
                       dispatch(changeRole('client'))
                     }

                     dispatch(turnOff)
                   }}>
                <AiOutlineDelete color='white' size='25px'/>
              </div>
              <div className={Style.toast}>
              </div>
            </div>
};

export default Info;
