
import {FormGroup, FormControlLabel, Checkbox} from '@material-ui/core';

import {useFormContext, Controller} from 'react-hook-form';

import {formLinks} from './formLinks';

import Style from './_LinkSelection.module.sass';

import {useEffect} from 'react'

const LinkSelection  = ({updateSteps}) => {

  useEffect(()=>updateSteps(), [updateSteps])

  const {control} = useFormContext();

  return  <div className={Style.general}>
            <div className={Style.stepName}>
              <h4>Formas de contacto</h4>
            </div>

            <p className={Style.description}>Selecciona las formas de contacto que ofrece tu negocio. (Al menos una)</p>

            <div className={Style.selection}>
              <div className={Style.fields}>
                <FormGroup column='true' className={Style.group}>
                  {formLinks.map((cb)=>{
                     return <Controller 
                             key={'cbs.' + cb.name}
                             control={control} 
                             name={'cbs.' + cb.name} 
                             defaultValue={false}
                             render={({field})=>(
                                     <FormControlLabel 
                                        label={cb.label}
                                        control={<Checkbox
                                          {...field}
                                           className={Style.cb}
                                           color='primary' 
                                           checked={field.value ? true : false}
                                           onChange={(e)=>{field.onChange(e); updateSteps()}}
                                           inputRef={field.ref}/>}/>)}/>;})
                  }
                </FormGroup>
              </div>
            </div>
            
          </div>
};

export default LinkSelection;
