import TitleBar from 'components/common/TitleBar';
import Content from 'components/common/Content';
import FloatingButton from 'components/common/FloatingButton';
import {AiOutlineHome} from 'react-icons/ai';

import Style from './_Privacy.module.sass';

const Privacy = () => {

  return  <div>
            <TitleBar>
              <h4 className={Style.title}>Politica de privacidad</h4>
            </TitleBar>
            <Content>
              <div className={Style.privacy}>

<p>
  TIDUCX SAS DE CV,  propietario y creador de la solución ManduX-T se rige por leyes mexicanas y pone a su disposición el presente aviso de  privacidad de datos.
</p>

<p>
  La Ley de Protección de Datos permite el tratamiento de datos personales por organizaciones y empresas en determinadas situaciones, pero siempre teniendo como objetivo preservar al usuario.
</p>

<p>
  ManduX-T está comprometido de conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y diversa normativa vigente y relacionada (la “Legislación de Datos Personales”), se emite el presente Aviso de Privacidad.
</p>

<p>
  Los datos personales que han sido recopilados serán utilizados con finalidades primarias, que son necesarias para la existencia, mantenimiento y cumplimiento de la relación entre usted y ManduX-T.
</p>

<h4>Dichos datos se utilizan para:</h4>

<ul>
  <li>
    Envío de notificaciones y/o comunicación vía  teléfono móvil, sobre el estatus de su servicio para seguimiento de su pedido.
  </li>

  <li>
    Comunicación directa vía llamada telefónica en caso que sea necesario para la conclusión de su pedido o cuando sea necesario contactarlo para aclaración de datos o información de su pedido.
  </li>
</ul>

<h4>Datos recopilados</h4>

<p>
  Usuario : Se  recopila  datos que brindan  cuando crean o actualizan sus cuentas ManduX-T ya sea como vendedor o como consumidor  lo que incluye;
</p>

<ul>
 <li>nombre</li>
 <li>correo electrónico/ facebook</li>
 <li>número de teléfono</li>
 <li>dirección</li>
 <li>foto de perfil</li>
</ul>

<p>
    Con el fin de identificar al vendedor o al consumidor.
</p>

<p>
  Se recopilan datos de ubicación de los vendedores, consumidores y de  repartidores, para permitir que se active el seguimiento y realizar la entrega.
</p>
    
<p>
  ManduX-T recopila esta información cuando la app se ejecuta en primer plano (app abierta y en pantalla) o en segundo plano (app abierta, pero no en pantalla) en su dispositivo móvil.
</p>

<h4>Contacto</h4>

<p>
  ManduX-T pone a tu disposición datos de la empresa y número telefónico para aclarar cualquier duda o información.
</p>

<h4>Modificaciones</h4>

<p>
  El presente aviso de privacidad puede modificarse,tener cambios o actualizaciones  derivado de nuevos requerimientos legales o propios de la empresa.
</p>

<p>
  ManduX-T se compromete a mantener informado sobre los cambios que pueda tener el aviso de privacidad de datos mediante notificación.
</p>
<br/>
<br/>
<br/>

              </div>
            </Content>
            <FloatingButton
              position='bottom-right'
              to='/'>
              <AiOutlineHome style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
              
          </div>
};

export default Privacy;
