import Style from './_XicohtzincoMap.module.sass';
import {xicohtzincoBounds, loader} from './location-utils';
import {useRef, useLayoutEffect} from 'react';

const XicohtzincoMap = ({marker, setMarker = ()=>{}, isInTownSetter, draggableMarker = false})=> {
  const currentMarker = useRef();
  const map = useRef();

  const mapElemRef = useRef(); 

  useLayoutEffect(() =>{
      const zoom = map.current ? map.current.getZoom() : 18;

      loader.load()
        .then(() => {
          map.current = new window.google.maps.Map(mapElemRef.current,{
            center: {lat: 19.17473, lng: -98.23358},//  latlng plaza xicohtzinco
            zoom: zoom,
            maxZoom: 18,
            minZoom: 14,
            restriction: {
              latLngBounds: {
                north: 19.18900,
                south: 19.15000,
                east: -98.22100,
                west: -98.26900
              }
            }
          });
          map.current.data.add({
            geometry: new window.google.maps.Data.Polygon([xicohtzincoBounds]),
          });

          if (marker) {
            if (currentMarker.current)
              currentMarker.current.setMap(null);

            currentMarker.current = new window.google.maps.Marker({
              position: marker,
              map: map.current,
              draggable: draggableMarker
            });

            if (draggableMarker) 
              currentMarker.current.addListener('dragend', () => {
                const position = currentMarker.current.getPosition()
                setMarker({ lat: position.lat(), lng: position.lng()})
              }, {passive: true})

            map.current.setCenter(marker);

            const point = new window.google.maps.LatLng(marker);
            const polygon = new window.google.maps.Polygon({paths: xicohtzincoBounds});
            const isPointInPolygon = window.google.maps.geometry.poly.containsLocation(point, polygon);

            isInTownSetter(isPointInPolygon);
            //isInTownSetter(true);
          }
        })
        .catch((error) => {
          console.log(error)
        })
  })

  return <div id='xicohtzincoMap' className={Style.map} ref={mapElemRef}/>
}

export default XicohtzincoMap;
