import Style from './_SMS.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import {MdSms} from 'react-icons/md';
import {validatePhoneNumber, formatPhoneInput} from 'utils/phone-utils'

const SMS = () => {
  const methods = useFormContext();
  const call = methods.getValues('links.call');
  const sms = methods.getValues('links.sms');

  console.log(sms)
  const previousMobile = sms && sms.number && sms.number.length > 0 ? sms.number : 
                         (call?.number && call?.isMobile ? call.number : '')

  console.log(sms?.number, call?.number, call?.isMobile, previousMobile)
  const smsInput = methods.register('links.sms.number', {
                    value: previousMobile,
                    pattern: {
                      value: /\(\d{3}\) \d{3} - \d{4}/,
                      message: 'El numero debe ser de 10 digitos'
                    }
                  })
  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>SMS</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <TextField 
                label='Numero telefonico' 
                type='tel'
                onKeyDown={e => validatePhoneNumber(e, methods.setValue, smsInput.name)}
                onInput={e => formatPhoneInput(e, methods.setValue, smsInput.name)}
                inputProps={{
                  maxLength: 16
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <MdSms />
                    </InputAdornment>
                  )
                }}
                inputRef={smsInput.ref}
              /> 
            </div> 
          </div>
        </div>
}

export default SMS;
