import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {IoCall} from 'react-icons/io5'
import {useLocation} from 'react-router-dom'
import ProductCard from 'components/account/companies/products/product-card/DeliveryProductCard'
import {Button} from '@material-ui/core'
import {OrderStatus, SubOrderStatus, updateOrderStatus, updateSubOrderStatus, getSubOrderStatusColor, getSubOrderStatusText} from '../suborders/order-utils'
import {useState, useEffect} from 'react'
import {listenForOrderChanges, listenForSubOrderChanges} from './delivery-utils'

import Style from './_DeliverySubOrderView.module.sass'

const DeliverySubOrderView = () => {
  const location = useLocation()
  const [subOrder, setSubOrder] = useState(location?.state?.subOrder)
  const [order, setOrder] = useState(location?.state?.order)
  const [stopInfo] = useState(location?.state?.stopInfo)

  useEffect(() => {
    const detachListener = listenForOrderChanges(order.id, setOrder)
    return detachListener
  }, [order.id])

  useEffect(() => {
    const detachListener = listenForSubOrderChanges(subOrder.id, setSubOrder)
    return detachListener
  }, [subOrder.id])

  const changeStatus = () => {
    if ( subOrder.status === SubOrderStatus.READY_FOR_PICKUP) {
      updateSubOrderStatus(subOrder.id, SubOrderStatus.PICKED_UP)

      if (stopInfo.isFirstStop)
        updateOrderStatus(order.id, OrderStatus.IN_PROGRESS)

      if (stopInfo.isLastStop)
        updateOrderStatus(order.id, OrderStatus.ON_ITS_WAY)
    }
  }

  return <div>
            <TitleBar>
              <h4 className={Style.title}>Detalle de pedido</h4>
            </TitleBar>
            <Content>
              <div>
                <div className={Style.companyName}>
                  <p>{subOrder.companyName}</p> 
                  <p className={Style.orderNumber}>
                    Order: {subOrder.timestamp.seconds}
                  </p>
                  { false && 
                    <div className={Style.phoneNumber}>
                      <Button
                        component='a'
                        color='secondary'
                        variant='contained'
                        size='small'
                        href={`tel: ${subOrder?.liks?.call?.number}`}>
                        <IoCall size='20px'/>
                      </Button>
                    </div>
                  }
                </div>
                <div>
                  {
                     subOrder.products.map(product => {
                      return <ProductCard key={product.product.id} productType={product} />
                    })
                  }
                </div>
                <div className={Style.statusButtonDiv}>
                  <div className={Style.orderTotal}>
                    Total: { subOrder.subTotalPerCompany}
                    <div className={Style.tipoMoneda}> MXN </div>
                  </div>
                  <Button className={Style.statusButton} 
                          onClick={changeStatus} 
                          disabled={ subOrder.status !== SubOrderStatus.READY_FOR_PICKUP}
                          style={{backgroundColor: getSubOrderStatusColor(subOrder.status)}}>   

                    { getSubOrderStatusText(subOrder.status)}

                  </Button>   
                </div>
              </div>
            </Content>
            <FloatingButton
              position='bottom-left'
              to={`/delivery/order/${ subOrder.orderId}`}
              stateParam={{order: order, subOrder: subOrder}}>
              <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
    </div>
};

export default DeliverySubOrderView ;
