import {createSlice} from '@reduxjs/toolkit';

const zoomSlice = createSlice({
  name: 'zoomSlice',
  initialState: {
    picture: '',
  },
  reducers: {
    zoom: (state, action) => { 
      state.picture = action.payload
    },
    closeZoom: (state) => {
      state.picture = ''
    }
  }
});

export default zoomSlice.reducer;
export const {zoom, closeZoom} = zoomSlice.actions;
