const makeCardPayment = (payment) => {
    const endpoint = `https://us-central1-${process.env.REACT_APP_PROJECTID}.cloudfunctions.net/payment-makePayment`

    fetch(endpoint, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        amount: payment.amount,
        order_id: payment.orderId,
        customer: {
          name: payment.cardOwner.name,
          last_name: payment.cardOwner.lastName,
          email: payment.cardOwner.email,
          phone: payment.cardOwner.phoneNumber 
        }
      }),
      mode: 'cors'
    })
    .then(res => {
      if (!res.ok) {
        console.log('error response')
        const error = res.json()
        console.log('error_res: ', error)

        throw error
      } 

      const data = res.json()
      return data
    })
    .then(data => {
      window.location.href = data.payment_method.url
    })
    .catch(e => {
      console.log('Error: ', e)
    })
}

export {makeCardPayment}
      
