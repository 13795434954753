import Style from './_CompanyReference.module.sass'
import {onLoad} from 'components/common/picture-utils'
import {useLocation, useNavigate} from 'react-router-dom'
import {turnOn, turnOff} from 'redux/app/backdropSlice'
import {Link} from '@material-ui/core'
import {useDispatch} from 'react-redux'
import algoliasearch from 'algoliasearch'

const CompanyReference = ({info}) => {
  const dispatch = useDispatch()

  const location = useLocation()
  const navigate = useNavigate()

  const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY)
  const companiesIndex = searchClient.initIndex('companies')

  return  (
              <div className={Style.companyReference}>
                <div className={Style.companyPicture}>
                  <div className={Style.companyPictureCircle}>
                    <div className={Style.companyPictureContainer}>
                      <img src={info.companyPicture} alt='' onLoad={e => onLoad(e, 20)}/>
                    </div>
                  </div>
                </div>
                <div className={Style.companyName}>
                  { 
                  location.pathname === `/company/${info.companyId}` ?
                  <span>{info.companyName}</span> :
                  <span>
                    <Link component='button' onClick={async (loc) => {
                      dispatch(turnOn())
                      let company = {}
                      try {
                      company = await companiesIndex.getObject(info.companyId)
                      
                      console.log(company)
                      dispatch(turnOff())
                      } catch (e) {
                        console.log('error getting the company: ', e)
                      }
                      
                      navigate(`/company/${info.companyId}`, {state: {from: location.pathname, company}})
                    }}>
                        <p>{info.companyName}</p>
                    </Link>
                  </span>
                  }
                </div>
              </div>
          )
};

export default CompanyReference
