import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {AiOutlineHome} from 'react-icons/ai'
import {Button} from '@material-ui/core'
import {useNavigate} from 'react-router'

import Style from './_PaymentResult.module.sass'

const PaymentResult = () => {
  const navigate = useNavigate()

  return <div>
            <TitleBar>
              <h4 className={Style.title}>Entrega programada</h4>
            </TitleBar>
            <Content>
              <div className={Style.paymentResult}>
                <p className={Style.thanks}>Gracias por tu compra.</p>
                <p className={Style.text}>Puedes ver las actualizaciones del envio en la seccion de Pedidos</p>
                <p className={Style.text}>El tiempo estimado de entrega puede variar debido a los tiempos de preparacion de los productos seleccionados</p>

                <Button color="primary" variant='contained' onClick={()=>navigate('/client/orders')}>Ir a mis pedidos</Button>
              </div>
            </Content>
            <FloatingButton
              position='bottom-right'
              to='/'>
              <AiOutlineHome style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
    </div>
};

export default PaymentResult;
