import {
  getAuth, 
  GoogleAuthProvider, 
  signInWithPopup, 
} from 'firebase/auth'

const signInWithGoogle = async (requestedUrl, navigate, setExistingAcountProvider) => {
  console.log('googleAuth')

  const googleAuthProvider = new GoogleAuthProvider()

  googleAuthProvider.addScope('email')
  googleAuthProvider.addScope('profile')

  googleAuthProvider.setCustomParameters({
    redirect_uri: requestedUrl,
    prompt: 'select_account'
  })

  const auth = getAuth()
  auth.languageCode = 'es'

  try {
    await signInWithPopup(auth, googleAuthProvider)
    console.log('user logged in with google')

    navigate(requestedUrl.pathname, {replace: true})
  } catch (error) {
    console.log('error while signing with google: ' + error)

    if (error.code === 'auth/account-exists-with-different-credential') {
      setExistingAcountProvider('Facebook')
    }
  }
}

export {signInWithGoogle}
