import Style from './_Twitter.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import {FaTwitter} from 'react-icons/fa';

const Twitter = () => {

  const methods = useFormContext();
  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Twitter</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <TextField 
                label='Usuario de Twitter' 
                type='text'
                placeholder='@miUsuario'
                {...methods.register('links.twitter.handle', {
                  value: methods.getValues('links.twitter.handle'),
                  pattern: {
                    value: /@.+/,
                    message: 'El usuario no es valido'
                  }
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FaTwitter />
                    </InputAdornment>
                  )
                }}/> 
            </div> 
          </div>
        </div>
}

export default Twitter;
