import {Badge, Button, makeStyles} from '@material-ui/core'
import {HiShoppingCart} from 'react-icons/hi'

import {useSelector, useDispatch} from 'react-redux'
import {addToCart, removeFromCart} from 'redux/app/shopping-cart/shoppingCartSlice'

const useStyles = makeStyles({
  root: {minWidth: '38px', lineHeight: '20px'},
  text: {fontSize: '20px', padding: '0px'},
  badge: {height: '16px', padding: '0px 2px', minWidth: '16px'}
})

const ShoppingCartAggregator = ({product}) => {
  const dispatch = useDispatch()

  const productsPerCompany = useSelector(state => state.shoppingCart.productsPerCompany)
  const companyInCart = productsPerCompany.find(company => company.id === product.companyId)
  const productTypeInCart = companyInCart?.productTypes?.find(productType => productType.id === product.id)

  const classes = useStyles()

  return  (
            <div style={{position: 'absolute', right: '0px', top: '0px'}}>
              <Button 
                size='small' 
                color='primary' 
                classes={{root: classes.root, 
                          text: classes.text}}
                onClick={() => dispatch(removeFromCart(product))}>
               –
              </Button>
              <Badge 
                overlap='rectangular'
                badgeContent={productTypeInCart?.productCountPerType} 
                color='error' 
                classes={{badge: classes.badge}}>
                <HiShoppingCart size='22px' />
              </Badge>
              <Button 
                size='small' 
                color='primary' 
                classes={{root: classes.root, 
                          text: classes.text}}
                onClick={() => dispatch(addToCart(product))}>
               +
              </Button>
            </div>
        )
};

export default ShoppingCartAggregator 
