import {getAuth} from 'firebase/auth'
import {getStorage, ref, listAll, deleteObject} from 'firebase/storage'
import {getFirestore, doc, deleteDoc, setDoc, increment} from 'firebase/firestore'

const deleteProduct = async (companyId, productId) => {
  const storage = getStorage()
  const firestore = getFirestore()
  const userId = getAuth().currentUser.uid

  try {
    console.log('Deleting product: ', productId, 'by company: ', companyId)
    const productRef = doc(firestore, `users/${userId}/companies/${companyId}/products/${productId}`)
    
    const productPicturesRef = ref(storage, productRef.path)
    const productPictures = await listAll(productPicturesRef)
    productPictures.items.forEach(async (itemRef) => {
      await deleteObject(itemRef)
    })

    console.log('Product pictures deleted')
    await deleteDoc(productRef)
    console.log('Product ', productId, ' deleted from db')
    
    console.log('Updating the product count')
    const bizRef = doc(firestore, `users/${userId}/companies/${companyId}`)
    await setDoc(bizRef, {ps_count: increment(-1)}, {merge: true})

    console.log('Deleting DOM element')
    document.getElementById(productId).remove()

  } catch(e) { 
    console.log('Error while deleting the product', productId, ': ', e)
  }
}

export {deleteProduct}
