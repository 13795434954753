import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {useState, useEffect} from 'react'

const ProtectedRoute = (props) => {
  const location = useLocation()
  const [isAuthReady, setIsAuthReady] = useState(false)
  const [allowAccess, setAllowAccess] = useState(false)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), user => {
      if (user) {
        if(user.isAnonymous)
          setAllowAccess(false)

        setAllowAccess(true)
      } else {
        setAllowAccess(false)
      }
      setIsAuthReady(true)
    })
    return () => unsubscribe()
  })

  return (
    <>
      {!isAuthReady ? <div>Espere...</div> :
                      allowAccess ? <Outlet /> :
                        <Navigate 
                          replace 
                          to='/account/auth'
                          state={{from: location}}
                          />
      }
    </>
  )
}

export default ProtectedRoute
