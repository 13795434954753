import Style from './_ProductCards.module.sass'
//import ProductCard from '../products/product-card/ProductCard'
import Cards from 'components/search-engine/Cards'

import algoliasearch from 'algoliasearch/lite'
import {InstantSearch, Configure/*, Hits*/} from 'react-instantsearch-dom'

const ProductCards = ({company}) => {
  const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_SEARCH_KEY)

  return <div id='productCards' className={Style.productCards}>
            <InstantSearch searchClient={searchClient} indexName='products'>
              <Configure filters={`companyId:${company.objectID}`} />
							<Cards indexName='products' />
            </InstantSearch>
            <div className={Style.empty}/>
         </div>
}

export default ProductCards;
