import concepts from "./concepts.js"

const getRentCostPerMin = () => {
  const monthlyRent = concepts.rent.price
  const monthFactor = 365 / 12

  const dailyRent = monthlyRent /  monthFactor

  const hourlyRent = dailyRent / 16

  const rentPerMinute = hourlyRent / 40

  return rentPerMinute
  
}

export {getRentCostPerMin}
