import {createSlice} from '@reduxjs/toolkit'

const phoneAuthSlice = createSlice({
  name: 'phoneAuthSlice',
  initialState: {
		phoneNumber: '',
		phoneCode: ''
	},
  reducers: {
    savePhoneNumber: (state, {payload}) => { 
      state.phoneNumber = payload
    },
    resetPhoneNumber: (state) => { 
      state.phoneNumber = ''
    },
    savePhoneCode: (state, {payload}) => { 
      state.phoneCode = payload
    },
    resetPhoneCode: (state) => { 
      state.phoneCode = ''
    }
  }
})

export default phoneAuthSlice.reducer
export const {
	savePhoneNumber, 
	resetPhoneNumber, 
	savePhoneCode,
	resetPhoneCode
} = phoneAuthSlice.actions


