const buildDirectionsObj = (directionsJSON) => {
  const json = JSON.parse(directionsJSON)

  json.routes[0].bounds = new window.google.maps.LatLngBounds(
                          {lat: json.routes[0].bounds.south, lng: json.routes[0].bounds.west}, 
                          {lat: json.routes[0].bounds.north, lng: json.routes[0].bounds.east} )

  json.routes[0].legs.forEach(leg => {
    leg.end_location = new window.google.maps.LatLng(leg.end_location.lat, leg.end_location.lng) 
    leg.start_location = new window.google.maps.LatLng(leg.start_location.lat, leg.start_location.lng) 

    leg.steps.forEach(step => {
      step.end_location = new window.google.maps.LatLng(step.end_location.lat, step.end_location.lng) 
      step.start_location = new window.google.maps.LatLng(step.start_location.lat, step.start_location.lng) 
      step.end_point= new window.google.maps.LatLng(step.end_point.lat, step.end_point.lng) 
      step.start_point= new window.google.maps.LatLng(step.start_point.lat, step.start_point.lng) 

      step.path.forEach(point => point = new window.google.maps.LatLng(point.lat, point.lng))
      step.lat_lngs.forEach(point => point = new window.google.maps.LatLng(point.lat, point.lng))
    })
  })

  json.routes[0].overview_path.forEach(point => new window.google.maps.LatLng(point.lat, point.lng))

  json.request.origin.location = new window.google.maps.LatLng(
                                      json.request.origin.location.lat,
                                      json.request.origin.location.lng)
  json.request.destination.location = new window.google.maps.LatLng(
                                        json.request.destination.location.lat,
                                        json.request.destination.location.lng)

  json.request.waypoints.forEach(waypoint => {
    waypoint.location = new window.google.maps.LatLng(
                            waypoint.location.location.lat,
                            waypoint.location.location.lng)
  })

  return json
}

export {buildDirectionsObj}
