import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {useEffect, useState} from 'react'
import {listenForSubOrdersChanges} from './client-orders-utils'
import {useLocation} from 'react-router-dom'
import DeliveryMap from './DeliveryMap'
import CompanyCard from './CompanyCard'
import {SubOrderStatus} from 'components/suborders/order-utils'
import {Button} from '@material-ui/core'

import Style from './_ClientOrder.module.sass'

const ClientOrder = () => {
  const location = useLocation()
  const [order] = useState(location?.state?.order)
  const [subOrders, setSubOrders] = useState([])
  const [stopOrderLocations, setStopOrderLocations] = useState([])

  useEffect(() => {
      const detachListener = listenForSubOrdersChanges(order.id, setSubOrders)
      return detachListener
  }, [order.id])
  
  const getSubOrderStatusColor = (status) => {
    let newColor = 'darkred'

    switch(status) {
      case SubOrderStatus.NEW:
        newColor = 'darkred'
        break
      case SubOrderStatus.IN_PREPARATION:
        newColor = 'darkred'
        break
      case SubOrderStatus.READY_FOR_PICKUP:
        newColor = 'darkred'
        break
      case SubOrderStatus.PICKED_UP:
        newColor = 'darkorange'
        break
      case SubOrderStatus.DELIVERED:
        newColor = 'darkgreen'
        break
      default:
        newColor = 'darkred'
        break
    }

    return newColor
  }

  return (
    <div>
      <TitleBar>
        <h4 className={Style.title}>Detalle de recorrido</h4>
      </TitleBar>
      <Content>
        <DeliveryMap  order={order} subOrders={subOrders} setStopOrderLocations={setStopOrderLocations}/>
        <div className={Style.stopList}>
        {
          stopOrderLocations.length > 0 && subOrders.length > 0 &&
          stopOrderLocations.map((nextStop, index) => {
            const subOrder = subOrders.find(subOrder => subOrder.companyLocation.lat === nextStop.location.lat &&
                                                        subOrder.companyLocation.lng === nextStop.location.lng)

            const stopInfo = {}
            stopInfo.isFirstStop = index === 0
            stopInfo.isLastStop = index === stopOrderLocations.length - 1
            stopInfo.stopNumber = index + 1

            console.log(subOrder)
            return <CompanyCard 
                      key={subOrder.id}
                      order={order}
                      subOrder={subOrder} 
                      statusColor={getSubOrderStatusColor(subOrder.status)} 
                      stopInfo={stopInfo} />
          })
        }
        </div>
        <div className={Style.total}>
          <p>
            Productos: {order.productCount}
          </p>
          <hr style={{width: '160px', border: '1px solid black'}} />
          <div>
            Subtotal: {order.productsTotal}
            <div className={Style.tipoMoneda}> MXN </div>
          </div>
          <div>
            Envio: {order.routeDetails.deliveryPrice}
            <div className={Style.tipoMoneda}> MXN </div>
          </div>
          <hr style={{width: '160px', border: '1px solid black'}} />
          <div>
            Total: {order.total}
            <div className={Style.tipoMoneda}> MXN </div>
          </div>
          <br />
          <Button variant='contained' color='primary' onClick={()=>window.print()}>Imprimir orden de compra</Button>
        </div>
      </Content>
      <FloatingButton
        position='bottom-left'
        to='/client/orders'>
        <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
      </FloatingButton>
    </div>
  )
};

export default ClientOrder;
