import {onLoad, changeImage} from 'components/common/picture-utils';
import {TextField, InputAdornment} from '@material-ui/core';

import {useFormContext, Controller} from 'react-hook-form';

import Style from './_Identity.module.sass';

import {validatePriceNumber, formatPriceInput, resetCursor} from '../form-utils'

const Identity = () => {
  const {control, setValue, getValues} = useFormContext();

  const currentPicture = getValues('currentPicture')

  return  <div className={Style.general}>
            <div className={Style.stepName}>
              <h4>Informacion General</h4>
            </div>
              
            <div id="picture" className={Style.bizImg}>
              <div id="picture-circle" className={Style.pictureCircle}>
                <div id="picture-container" className={Style.pictureContainer}>
                  <img id="photo"
                       src={currentPicture}
                       onClick={()=>document.getElementById('photo-picker').click()}
                       onLoad={(e)=>{onLoad(e, 170)}}
                       alt='Foto de negocio' />
                  <Controller 
                    control={control}
                    name='picture'
                    render={({field})=>(
                          <input id="photo-picker"
                                 type="file"
                                 accept="image/*"
                                 hidden 
                                 onChange={(event)=>{
                                     setValue('picture', event.target.files)
                                     changeImage(event);
                                   }
                                 }
                          />
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={Style.info}>
              <div className={Style.fields}>
                <Controller
                  control={control}
                  name='name'
                  rules={{
                    required: 'El nombre del producto es necesario'
                  }}
                  render={({field})=> (
                    <TextField id='name' 
                                label='Nombre' 
                                autoComplete='off' 
                                value={field.value || ''} 
                                onChange={field.onChange}/> 
                  )}/>


                <Controller 
                  control={control}
                  name='description'
                  rules={{
                    required: 'La descripcion del negocio es necesaria'
                  }} 
                  render={({field}) => (
                <TextField id='description' 
                           label='Descripcion'
                           multiline
                           maxRows={3}
                           minRows={3}
                           value={field.value || ''} 
                           onChange={field.onChange}/>
                  )}
                />

                <Controller 
                  control={control}
                  name='price'
                  rules={{
                    required: 'El precio del producto es necesario',
                  }} 
                  render={({field}) => (
                    <TextField id='price' 
                           label='Precio'
                           type='tel'
                           InputProps={{
                             startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                             endAdornment: <InputAdornment position='end'>MXN</InputAdornment>
                           }}
                           value={field.value || '0.00'} 
                           onKeyDown={(e)=>validatePriceNumber(e, setValue, field.name)}
                           onInput={(e)=>formatPriceInput(e, setValue, field.name)}
                           onClick={resetCursor}
                          />
                  )}
                />
              </div>
            </div>

          </div>
};

export default Identity;
