import Style from './_Instagram.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import {FaInstagramSquare} from 'react-icons/fa';

const Instagram = () => {

  const methods = useFormContext();
  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Instagram</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <Controller 
                control={methods.control}
                name='links.instagram.username'
                defaultValue={methods.getValues('links.instagram.username')}
                render={({field})=>(
                  <TextField 
                    label='Usuario de Instagram' 
                    type='text'
                    value={field.value}
                    onChange={field.onChange}
                    placeholder='MiUsuario'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <FaInstagramSquare />
                        </InputAdornment>
                      )
                    }}/> 
                )}
              />
            </div> 
          </div>
        </div>
}

export default Instagram;
