import Identity from './Identity';
import LinkSelection from './LinkSelection';
import Location from './Location';
import OpenHours from './OpenHours';
import Call from './Call';
import SMS from './SMS';
import Telegram from './Telegram';
import Whatsapp from './Whatsapp';
import Facebook from './Facebook';
import Twitter from './Twitter';
import Instagram from './Instagram';
import Tiktok from './Tiktok';
import Youtube from './Youtube';
import Website from './Website';

export const steps = [ {
    name: 'identity',
    label: 'Datos generales',
    enabled: true,
    component: <Identity />
  }, {
    name: 'location',
    label: 'Ubicacion',
    enabled: true,
    component: <Location />
  }, {
    name: 'openhours',
    label: 'Horario',
    enabled: true,
    component: <OpenHours/>
  },{
    name: 'linkSelection',
    label: 'seleccion de formas de contacto',
    enabled: true,
    component: <LinkSelection />
  },{
    name: 'call',
    label: 'Llamada',
    enabled: false,
    component: <Call />
  },{
    name: 'sms',
    label: 'SMS',
    enabled: false,
    component: <SMS />
  },{
    name: 'telegram',
    label: 'Telegram',
    enabled: false,
    component: <Telegram />
  },{
    name: 'whatsapp',
    label: 'Whatsapp',
    enabled: false,
    component: <Whatsapp />
  },{
    name: 'facebook',
    label: 'Facebook',
    enabled: false,
    component: <Facebook />
  },{
    name: 'twitter',
    label: 'Twitter',
    enabled: false,
    component: <Twitter />
  },{
    name: 'instagram',
    label: 'Instagram',
    enabled: false,
    component: <Instagram />
  },{
    name: 'tiktok',
    label: 'Tiktok',
    enabled: false,
    component: <Tiktok />
  },{
    name: 'youtube',
    label: 'Youtube',
    enabled: false,
    component: <Youtube />
  },{
    name: 'website',
    label: 'Sitio Web',
    enabled: false,
    component: <Website />
  }];
