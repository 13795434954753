import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {MdPayment} from 'react-icons/md'
import {AiOutlineCheck} from 'react-icons/ai'
import {useNavigate, useLocation} from 'react-router-dom'
import {placeOrder} from '../suborders/order-utils'
import {useSelector, useDispatch} from 'react-redux'
import {resetShoppingCart} from 'redux/app/shopping-cart/shoppingCartSlice'
import {getAuth} from 'firebase/auth'
import {getFirestore, getDoc, doc} from 'firebase/firestore'
import {validatePhoneNumber, formatPhoneInput} from 'utils/phone-utils'
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField, InputAdornment, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import {useForm, FormProvider, Controller} from 'react-hook-form'
import {IoCall} from 'react-icons/io5'
import {useState, useEffect} from 'react'
import {DevTool} from '@hookform/devtools'
import {turnOn, turnOff} from 'redux/app/backdropSlice'
import Style from './_Payment.module.sass'
import CreditCardOwnerDetailsForm from './CreditCardOwnerDetailsForm'

const Payment = () => {
  const userId = getAuth().currentUser.uid
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const shoppingCart = useSelector(state => state.shoppingCart)

  const methods = useForm({mode: 'onChange'})
  const {setValue} = methods
  const [errors, setErrors] = useState(null)

  const [paymentMethod, setPaymentMethod] = useState('cash')

  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value)
  }

  const validate = async (fieldArrayToValidate) => {
    const areFieldsValid = await methods.trigger(fieldArrayToValidate)

    return (areFieldsValid ? null : fieldArrayToValidate)
  }

  const orderNow = async () => {
    const invalidFields = await validate(['recipientPhoneNumber', 'recipientName'])
    let cardInvalidFields; 

    if (paymentMethod === 'card') {
        cardInvalidFields = await validate(['cardOwnerName', 'cardOwnerLastname','cardOwnerPhoneNumber' , 'cardOwnerEmail'])
    }
    
    console.log(methods.formState.errors)
    if (!(invalidFields || cardInvalidFields)) {
      console.log(invalidFields, cardInvalidFields)
      methods.handleSubmit(submit)()
      setErrors(null)
    } else {
      console.log(invalidFields, cardInvalidFields)
      const invalidErrorMessages = []

      invalidFields && invalidFields.forEach(invalidField => {
        const errorProperty = methods.formState.errors[invalidField]
        errorProperty?.message && invalidErrorMessages.push(errorProperty.message)
      })
      cardInvalidFields && cardInvalidFields.forEach(invalidField => {
        const errorProperty = methods.formState.errors[invalidField]
        errorProperty?.message && invalidErrorMessages.push(errorProperty.message)
      })

      setErrors(invalidErrorMessages)
    }
  }

  const submit = (data) => {
    dispatch(turnOn())

    const payment = {
      method: paymentMethod,
      status: 'pending',
      amount: parseFloat(shoppingCart.total.slice(1)),
      cardOwner: {
        name: data.cardOwnerName,
        lastName: data.cardOwnerLastname,
        email: data.cardOwnerEmail,
        phoneNumber: data.cardOwnerPhoneNumber
      }
    } 

    const recipient = {
      name: data.recipientName,
      phoneNumber: data.recipientPhoneNumber
    }

    placeOrder(shoppingCart, 
              userId, 
              recipient,
              payment)

    if (paymentMethod === 'cash') {
      dispatch(resetShoppingCart())
      dispatch(turnOff())
      navigate('/payment/result', {replace: true})
    }
  }

  const closeSnack = (event, reason) => {
    if (reason === 'clickaway') 
      return

    setErrors(null)
  }

  const numberInput = methods.register('recipientPhoneNumber', { })

  //const [clientPaymentInfo, setClientPaymentInfo] = useState(null)

  useEffect(() => {
    (async () => {
      const clientSS = await getDoc(doc(getFirestore(), 'users', userId))
      const client = clientSS.data()

      if (client && client.clientPaymentInfo) {
        setValue('cardOwnerName', client.clientPaymentInfo.name, {shouldTouch: true, shouldDirty: true})
        setValue('cardOwnerLastname', client.clientPaymentInfo.lastName, {shouldTouch: true, shouldDirty: true})
        setValue('cardOwnerEmail', client.clientPaymentInfo.email, {shoutlTouch: true, shouldDirty: true} )
        setValue('cardOwnerPhoneNumber', client.clientPaymentInfo.phoneNumber, {shouldTouch: true, shouldDirty: true})
      }

      if (client && client.recipient) {
        setValue('recipientName', client.recipient.name, {shouldTouch: true, shouldDirty: true})
        setValue('recipientPhoneNumber', client.recipient.phoneNumber, {shouldTouch: true, shouldDirty: true})
      }
    })()
  }, [setValue, userId])

  return <div>
            <TitleBar>
              <h4 className={Style.title}>Informacion de pago</h4>
            </TitleBar>
            <Content>
              <Snackbar 
                open={errors ? true : false}
                onClose={closeSnack}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
                <MuiAlert 
                  elevation={1} 
                  variant='filled' 
                  severity='error'
                  onClose={closeSnack}>

                  { errors?.map((error, index) => <p key={index}>{error}</p>) }

                </MuiAlert>
              </Snackbar>
              <br/>
              <div className={Style.payment}>
                <FormProvider {...methods}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Forma de pago:
                  </FormLabel>
                  <RadioGroup
                    aria-label="Forma de pago"
                    defaultValue="cash"
                    name="radio-button-group"
                    value={paymentMethod}
                    onChange={handlePaymentMethodChange}
                  >
                    <FormControlLabel 
                      value="cash" 
                      control={<Radio size="small" color="primary"/>} 
                      label={"Efectivo"/* + " (Solo usuarios registrados)"*/}
                      disabled={false/*getAuth().currentUser.isAnonymous*/} 
    />
                    <FormControlLabel 
                      value="card" 
                      disabled
                      control={<Radio size="small" color="primary"/>} 
                      label="Tarjeta de debito/credito" />
                  </RadioGroup>
                </FormControl>
      
                {paymentMethod === "card" ?  <CreditCardOwnerDetailsForm clientId={userId} /> : ''}
                {paymentMethod === "cash" ?  <p className={Style.legend}>Por favor, prepare su pago para el repartidor y procure que sea lo mas exacto posible, ya que el repartidor podria no contar con el cambio necesario</p> : ''}

                <p className={Style.phoneLabel}>Informacion para seguimiento: </p>
                  <Controller
                    control={methods.control}
                    name='recipientPhoneNumber'
                    defaultValue=""
                    rules={{
                        required: 'El numero telefonico es necesario',
                        pattern: {
                          value: /\(\d{3}\) \d{3} - \d{4}/,
                          message: 'El numero debe ser de 10 digitos'
                        }
                    }}
                    render={({field}) => (
                      <TextField 
                        label='Numero telefonico' 
                        type='tel'
                        size='small'
                        value={field.value}
                        onKeyDown={e => validatePhoneNumber(e, methods.setValue, numberInput.name)}
                        onInput={e => formatPhoneInput(e, methods.setValue, numberInput.name)}
                        inputProps={{
                          maxLength: 16
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <IoCall />
                            </InputAdornment>
                          )
                        }}
                        inputRef={field.ref}
                      /> 
                    )}
                  />

                  <br/>
                  <Controller 
                    control={methods.control}
                    name='recipientName'
                    rules={{required: 'El nombre es necesario'}}
                    defaultValue=""
                    render={({field}) => (
                      <TextField 
                        autoComplete='off'
                        label='Nombre de quien recibe' 
                        size='small'
                        value={field.value}
                        onChange={field.onChange}
                        inputRef={field.ref}
                      /> 
                    )}
                    />

                <div className={Style.countAndTotal}>
                
                  
                  <div className={Style.count}>
                    Productos: {shoppingCart.productCount}
                  </div>
                  <hr style={{border: '1px solid black', width: '100%'}}/>
                  <div className={Style.subtotal}>
                    Subtotal: {shoppingCart.productsTotal}
                    <div className={Style.tipoMoneda}> MXN </div>
                  </div>
                  <div className={Style.delivery}>
                    Envio: {shoppingCart.routeDetails.deliveryPrice}
                    <div className={Style.tipoMoneda}> MXN </div>
                  </div>
                  <hr style={{border: '1px solid black', width: '100%'}}/>
                  <div className={Style.total}>
                    Total: {shoppingCart.total}
                    <div className={Style.tipoMoneda}> MXN </div>
                  </div>
                </div>
                </FormProvider>
              </div>
            </Content>
            <FloatingButton
              position='bottom-left'
              to='/shoppingcart'
              stateParam={location.state}>
              <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
            <FloatingButton
              position='bottom-right'
              onClick={orderNow}
              >
              { paymentMethod === 'card' ?
                <MdPayment style={{fill: 'white'}} size='26px'/> :
                <AiOutlineCheck style={{fill: 'white'}} size='26px'/>

              }
            </FloatingButton>
      <DevTool control={methods.control}/>
    </div>
};

export default Payment;
