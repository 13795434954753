import Style from './_Picture.module.sass';
import ProductPicture from 'components/account/companies/products/management/box.svg';
import {onLoad} from 'components/common/picture-utils';
import {useDispatch} from 'react-redux'
import {zoom} from 'redux/app/zoomSlice'
import {useLocation, useNavigate} from 'react-router-dom'

const Picture = ({picture}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

return  <div className={Style.picture}>
              <div className={Style.pictureCircle} 
                   onClick={e => {
                     dispatch(zoom(picture.medium))
                     navigate(`${location.pathname}#showPicture`)
                   }}>
                <div className={Style.pictureContainer}>
                  <img id='img_1' 
                        src={picture.small !== '' ? picture.small : ProductPicture} 
                        alt='Fotografia de negocio' 
                        onLoad={ e =>onLoad(e, 65) } 
                        className={Style.pictureImg}/>
                </div>
              </div>
            </div>
};

export default Picture;
