import PhoneModal from './PhoneModal'
import PhoneCodeModal from './PhoneCodeModal'
import {Button} from '@material-ui/core'
import {useState, useRef} from 'react'
import {IoCall} from 'react-icons/io5'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {turnOff} from 'redux/app/backdropSlice'

import {
  getAuth, 
  RecaptchaVerifier,
  signInWithPhoneNumber, 
} from 'firebase/auth'

const PhoneAuth = ({navigateTo}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const confirmationResult = useRef()

	const verifyCodeCB = async (phoneCode) => {
		try {
			await confirmationResult.current.confirm(phoneCode)
			console.log('signed in with phone number')
			dispatch(turnOff())
			setShowPhoneCodeModal(false)
			navigate(navigateTo, {replace: true})
		} catch (e) {
		  console.log('error while signing with phone number: ' + e)
			throw e
		}
	}

	const signInCB = async (phoneNumber) => {
			const auth = getAuth()
			auth.languageCode = 'es'

			try {
				const recaptchaVerifier = new RecaptchaVerifier(
					'sign-in-button', 
					{
						size: 'invisible',
						callback: (response) => {
							//reCAPTCHA solved, allow signInWithPhoneNumber
							//onSignInSubmit();
						}
					}, 
					auth)

				confirmationResult.current = await signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
				console.log('sent sms with code')

				setShowPhoneModal(false)
				dispatch(turnOff())
				setShowPhoneCodeModal(true)

				//navigate(requestedUrl.pathname, {replace: true})
			} catch (error) {
				console.log('error while signing with phone number: ' + error)
			}
	}

	const signInWithPhone = async () => {
		console.log('phoneAuth')
		setShowPhoneModal(true)
	}

	const [showPhoneModal, setShowPhoneModal] = useState(false)
	const [showPhoneCodeModal, setShowPhoneCodeModal] = useState(false)

	return (
		<>
			<Button
				variant='contained'
				style={{backgroundColor:'green', color: 'white'}}
				startIcon={<IoCall />}
				onClick={() => signInWithPhone()}
			>
				Celular
			</Button>

			<PhoneModal show={showPhoneModal} setShow={setShowPhoneModal} signInCB={signInCB}/>
			<PhoneCodeModal show={showPhoneCodeModal} setShow={setShowPhoneCodeModal} verifyCodeCB={verifyCodeCB} />
		</>
	) 
}

export default PhoneAuth
