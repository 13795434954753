import Style from './_Call.module.sass'
import {TextField, FormGroup, Switch, FormControlLabel, InputAdornment} from '@material-ui/core'
import {useFormContext, Controller} from 'react-hook-form'
import {useState} from 'react'
import {IoCall} from 'react-icons/io5'

import {validatePhoneNumber, formatPhoneInput, formatExtInput} from 'utils/phone-utils'

const Call = () => {
  const {register, control, getValues, setValue} = useFormContext()
  const call = getValues('links.call')

  const [isMobile, setIsMobile] = useState(call.isMobile)

  const numberInput = register('links.call.number', {
                        value: call?.number ?? '',
                        pattern: {
                          value: /\(\d{3}\) \d{3} - \d{4}/,
                          message: 'El numero debe ser de 10 digitos'
                        }
                      })

  const extensionInput = register('links.call.extension', {
                    value: call?.extension ?? '',
                    pattern: {
                      value: /\d*/,
                      message: 'La extension acepta solo digitos'
                    }
                  })

  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Llamada</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <div className={Style.phoneFields}>
                <div className={Style.number}>
                  <TextField
                    type='tel'
                    size='small'
                    label='Numero telefonico'
                    onKeyDown={e => validatePhoneNumber(e, setValue, numberInput.name)}
                    onInput={e => formatPhoneInput(e, setValue, numberInput.name)}
                    inputProps={{
                      maxLength: 16
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <IoCall />
                        </InputAdornment>
                      )
                    }}
                    inputRef={numberInput.ref} />
                </div>
               { !isMobile &&
                 <div className={Style.extension}>
                    <TextField 
                      label='Ext'
                      type='tel'
                      size='small'
                      margin='none'
                      onInput={e => formatExtInput(e, setValue, extensionInput.name)}
                      inputProps={{
                        maxLength: 3
                      }}
                      inputRef={extensionInput.ref }/> 
                 </div>
              }
              </div>
              
              <FormGroup column='true' className={Style.celular}>
                <Controller 
                  control={control}
                  name='links.call.isMobile'
                  defaultChecked={isMobile}
                  render={({field})=>(
                    <FormControlLabel 
                      label='Es numero celular?'
                      labelPlacement='start'
                      control={<Switch
                                {...field}
                                color='primary'
                                checked={isMobile}
                                onChange={e => {
                                  setIsMobile(e.target.checked); 
                                  field.onChange(e)
                                }}
                                inputRef={field.ref}
                                 />}

                    />
                  )}
                />
              </FormGroup>
            </div> 
          </div>
        </div>
}

export default Call;
