function mask(priceArray){
  console.log(priceArray)
  let priceNumberArray = priceArray.join("").replace(/[^\d]/gi,"").split("");  

  console.log(priceNumberArray)
  priceNumberArray.splice(-2,0,".");

  priceNumberArray = Number.parseFloat(priceNumberArray.join('') || '0').toFixed(2)
  console.log(priceNumberArray)
  if (priceNumberArray ===  0)
    return '0.00'

  priceNumberArray = ('' + priceNumberArray).split('')

  for (let i = (priceNumberArray.length - 6); i > 0; i-=3) {
      priceNumberArray.splice(i,0,",");
      console.log(priceNumberArray)
  }

  return priceNumberArray.join("");
}

const validatePriceNumber = (event, setValue, inputName) => {
  var isDigit = /^\d$/.test(event.key);
  var isBackspace = event.key === "Backspace";
  var isAnyOtherCharacter = !(isDigit || isBackspace) ? true : false

  if (isDigit || isBackspace || isAnyOtherCharacter) {
    event.preventDefault();
    var priceArray = event.target.value.split("");
    var cursorPosition = event.target.value.length;

    if (isDigit) {
        priceArray.splice(cursorPosition,0,event.key); 
    } else if (isBackspace) {
        priceArray.splice(cursorPosition - 1,1);
    } else {
      return
    }
    
    let value = mask(priceArray)

    event.target.value = value;
    setValue(inputName, value);
    event.target.selectionStart = value.length
    event.target.selectionEnd = value.length
  }
}
const formatPriceInput = (event, setValue, inputName) => {
  event.target.value = mask(event.target.value.split(''))
  setValue(mask(inputName, event.target.value.split('')))
}
const resetCursor = (e) => {
  e.target.selectionStart = e.target.value.length
  e.target.selectionEnd = e.target.value.length
} 

export {validatePriceNumber, formatPriceInput, resetCursor};
