import Style from './_Links.module.sass';
import AppLink from './AppLink.js';
import SVGList from './SVGList.js';

const Links = ({links}) => {

  return  <div className={Style.links}>
    {
      Object.entries(links)
      .filter(([key, value]) => value)
      .map(([key, value])=> {
        console.log(key)
        let Link = SVGList.find(appLink => appLink.type === key);

        if (Link !== undefined) {
          if (Link.type === 'location') {
            const coords = JSON.parse(value.coords)
            Link.data = coords.lat + ',' + coords.lng
          } else {
            Link.data = value
          }

          console.log(Link)
          return <AppLink key={Link.type} value={Link} />;
        }

        return '';
      })
    }
    </div>
};

export default Links;
