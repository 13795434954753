const xicohtzincoBounds = [
    {lat: 19.15726, lng: -98.23669},//rio atoyac, limite puebla
    {lat: 19.16043, lng: -98.23486},//zanja del valor
    {lat: 19.16405, lng: -98.23381},//via del tren
    {lat: 19.16364, lng: -98.23104},//via del tren
    {lat: 19.16256, lng: -98.22611},//hotel vias
    {lat: 19.16263, lng: -98.22590},//carretera vias
    {lat: 19.16598, lng: -98.22416},//dulceria, camino real
    {lat: 19.17465, lng: -98.22583},//jaguar camino real
    {lat: 19.18660, lng: -98.23038},//techaloya camino real
    {lat: 19.18677, lng: -98.23559},//techaloya crisanto
    {lat: 19.18704, lng: -98.23638},//atras gas techaloya
    {lat: 19.18595, lng: -98.23967},//barranca ceci
    {lat: 19.18556, lng: -98.24042},//barranca
    {lat: 19.18539, lng: -98.24060},//barranca
    {lat: 19.18451, lng: -98.24125},//barranca
    {lat: 19.18332, lng: -98.24298},//barranca
    {lat: 19.18264, lng: -98.24374},//barranca
    {lat: 19.18186, lng: -98.24442},//cerrito
    {lat: 19.18235, lng: -98.24508},//cerrito
    {lat: 19.18053, lng: -98.24676},//cerrito
    {lat: 19.18012, lng: -98.24622},//cerrito
    {lat: 19.17952, lng: -98.24738},//barranca
    {lat: 19.17664, lng: -98.24927},//barranca
    {lat: 19.17461, lng: -98.25093},//barranca
    {lat: 19.17073, lng: -98.25958},//campo
    {lat: 19.16077, lng: -98.26226},//rio atoyac, limite puebla
    {lat: 19.16069, lng: -98.26163},//rio atoyac, limite puebla
    {lat: 19.16069, lng: -98.26163},//rio atoyac, limite puebla
    {lat: 19.16040, lng: -98.26048},//rio atoyac, limite puebla
    {lat: 19.16037, lng: -98.26001},//rio atoyac, limite puebla
    {lat: 19.16067, lng: -98.25457},//rio atoyac, limite puebla
    {lat: 19.16045, lng: -98.25313},//rio atoyac, limite puebla
    {lat: 19.15739, lng: -98.24108},//rio atoyac, limite puebla
    {lat: 19.15740, lng: -98.24042},//rio atoyac, limite puebla
    {lat: 19.15793, lng: -98.23848},//rio atoyac, limite puebla
    {lat: 19.15788, lng: -98.23819},//rio atoyac, limite puebla
    {lat: 19.15767, lng: -98.23797},//rio atoyac, limite puebla
    {lat: 19.15729, lng: -98.23788},//rio atoyac, limite puebla
    {lat: 19.15655, lng: -98.23798},//rio atoyac, limite puebla
    {lat: 19.15620, lng: -98.23795},//rio atoyac, limite puebla
    {lat: 19.15603, lng: -98.23799},//rio atoyac, limite puebla
    {lat: 19.15593, lng: -98.23799},//rio atoyac, limite puebla
    {lat: 19.15587, lng: -98.23788},//rio atoyac, limite puebla
    {lat: 19.15589, lng: -98.23778},//rio atoyac, limite puebla
    {lat: 19.15602, lng: -98.23760},//rio atoyac, limite puebla
    {lat: 19.15625, lng: -98.23738},//rio atoyac, limite puebla
    {lat: 19.15646, lng: -98.23728},//rio atoyac, limite puebla
    {lat: 19.15688, lng: -98.23723},//rio atoyac, limite puebla
    {lat: 19.15711, lng: -98.23706},//rio atoyac, limite puebla
  ];

export {xicohtzincoBounds};
