import concepts from "./concepts.js"

const getServicesTotal = () => {
  const number = concepts.motorcycle.services.number
  const price = concepts.motorcycle.services.price

  return number * price
}

const getMotorcycleConceptsTotal = () => {
  const servicesTotal = getServicesTotal()
  const parts = concepts.motorcycle.parts.price
  const motorcycle = concepts.motorcycle.price
  const tires = concepts.motorcycle.tires.price

  return servicesTotal + motorcycle + parts + tires
}

const getMotorcycleCostPerKm = () => {
  const motorcycleConceptsTotal = getMotorcycleConceptsTotal()
  const warrantyKms = concepts.motorcycle.warrantyKms

  return motorcycleConceptsTotal / warrantyKms
}

export {getMotorcycleCostPerKm}
