import Style from './_PictureAndInfo.module.sass';
import Picture from '../Picture.js'
import Info from './Info.js'

const PictureAndInfo = ({company}) => {
  return  <div className={Style.picninfo}>
            <Picture picture={company.picture}/>
            <Info company={company}/>
          </div>
};

export default PictureAndInfo;
