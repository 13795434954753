import {
  getAuth, 
  FacebookAuthProvider, 
  signInWithPopup, 
} from 'firebase/auth'

const signInWithFacebook = async (requestedUrl, navigate, setExistingAccountProvider) => {
  console.log('facebookAuth')

  const facebookAuthProvider = new FacebookAuthProvider()

  facebookAuthProvider.addScope('email')
  facebookAuthProvider.addScope('public_profile')

  facebookAuthProvider.setCustomParameters({
    redirect_uri: requestedUrl,
  })

  const auth = getAuth()
  auth.languageCode = 'es'

  try {
    await signInWithPopup(auth, facebookAuthProvider)
    console.log('user logged in with facebook')

    navigate(requestedUrl.pathname, {replace: true})
  } catch (error) {
    console.log('error while signing with facebook: ' + error)
    
    if (error.code === 'auth/account-exists-with-different-credential') {
        setExistingAccountProvider('Google')
    }
  }
}

export {signInWithFacebook}
