import {OrderStatus} from '../suborders/order-utils'
import {getFirestore, collection, query, where, orderBy, onSnapshot, doc} from 'firebase/firestore'


const listenForDeliveryGuyOrders = (deliveryGuyId, setDeliveryGuyOrders) => {
    const q = query(collection(getFirestore(), 'orders'),
                  where('deliveryGuyId', '==', deliveryGuyId),
                  orderBy('timestamp', 'desc'))

    const detachListener = onSnapshot(q, snapshot => {
                            const deliveryGuyOrders = {
                              newOrders: [],
                              ordersInProgress: [],
                              ordersOnItsWay: [],
                              ordersDelivered: [],
                            }

                            snapshot.forEach(orderDoc => {
                              const order = orderDoc.data()
                              order.id = orderDoc.id

                              if (order.status === OrderStatus.NEW)
                                  deliveryGuyOrders.newOrders.push(order)
                              else if (order.status === OrderStatus.IN_PROGRESS)
                                  deliveryGuyOrders.ordersInProgress.push(order)
                              else if (order.status === OrderStatus.ON_ITS_WAY)
                                  deliveryGuyOrders.ordersOnItsWay.push(order)
                              else if (order.status === OrderStatus.DELIVERED)
                                  deliveryGuyOrders.ordersDelivered.push(order)
                            })

                            setDeliveryGuyOrders(deliveryGuyOrders)
                          })

    return detachListener
}

const listenForDeliverySubOrders = async (orderId, setSubOrders)=> {
  const q = query(collection(getFirestore(), 'subOrders'),
              where('orderId', '==', orderId))

  const detachListener = onSnapshot(q, snapshot => {
                            const subOrders = []

                            snapshot.forEach(subOrderDoc => {
                              const subOrder = subOrderDoc.data()
                              subOrder.id = subOrderDoc.id
                              subOrders.push(subOrder)
                            })

                            setSubOrders(subOrders)
                          })

  return detachListener
}

const listenForOrderChanges = async (orderId, setOrder)=> {
  const detachListener = onSnapshot(doc(getFirestore(), 'orders', orderId), snapshot => {
                            const order = snapshot.data()
                            order.id = snapshot.id
                            setOrder(order)
                          })

  return detachListener
}

const listenForSubOrderChanges = async (subOrderId, setSubOrder)=> {
  const detachListener = onSnapshot(doc(getFirestore(), 'subOrders', subOrderId), snapshot => {
                            const subOrder = snapshot.data()
                            subOrder.id = snapshot.id
                            setSubOrder(subOrder)
                          })

  return detachListener
}

export {listenForDeliveryGuyOrders, listenForDeliverySubOrders, listenForOrderChanges, listenForSubOrderChanges}
