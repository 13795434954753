import TitleBar from 'components/common/TitleBar';
import Content from 'components/common/Content';

import {Fab, Snackbar} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {IoCloseSharp} from 'react-icons/io5'
import {IoIosSave} from 'react-icons/io'

import Style from './_ProductForm.module.sass';

import {useState, useEffect} from 'react';
import {useForm, FormProvider} from 'react-hook-form';
import {DevTool} from '@hookform/devtools';

import Identity from './Identity'
import {registerProduct} from './form-utils';

import 'fontsource-roboto';

import {useNavigate, useParams, useLocation} from 'react-router-dom';

import {useDispatch} from 'react-redux'
import {turnOn, turnOff} from 'redux/app/backdropSlice'

const ProductForm  = () => {
  const dispatch = useDispatch()

  const {companyId} = useParams()

  const methods = useForm({
    mode: 'onChange',
  });
  const navigate = useNavigate()
  const location = useLocation()

  const setValue = methods.setValue
  useEffect(() => {
    setValue('companyName', location.state.name)
    setValue('companyType', location.state.type)
    setValue('companyPicture', location.state.picture.small)
    setValue('companyLocation', JSON.parse(location.state.location.coords))
  },[location.state.name, 
      location.state.type, 
      location.state.picture.small, 
      setValue, 
      location.state.location.coords])

  const [errors, setErrors ] = useState(null)

  const cancel = () => {
    setErrors(null)
    navigate(-1)
  }

  const validate = async (fieldArrayToValidate) => {
    const areFieldsValid = await methods.trigger(fieldArrayToValidate);

    console.log(fieldArrayToValidate, areFieldsValid, methods.getValues(fieldArrayToValidate))
    return (areFieldsValid ? null : fieldArrayToValidate)
  }

  const save = async(e) => {

    let invalidFields = await validate(['picture', 'name', 'description', 'price'])

    console.log(invalidFields , methods.formState.errors)

    if (!invalidFields){
      methods.handleSubmit(submit)();
      setErrors(null)

    } else {
      const invalidErrorMessages = []

      invalidFields.forEach(invalidField => {
        const chainNames = invalidField.split('.');
        let errorProperty = methods.formState.errors;
        
        chainNames.forEach(name => errorProperty = errorProperty[name]);

        const invalidFieldMessage = errorProperty?.message;
        invalidFieldMessage && invalidErrorMessages.push(invalidFieldMessage);
      })

      setErrors(invalidErrorMessages)
    }
  }

  const submit = async (data) => { 
    dispatch(turnOn())
    console.log(data)
    data.inStock = true
    await registerProduct(companyId, data)
    dispatch(turnOff())
    navigate(`/account/companies/${companyId}/products`, {state: location.state, replace: true})
  };


  const closeSnack = (event, reason) => {
    if(reason === 'clickaway')
      return;

    setErrors(null);
  }

  return  <div>
            <TitleBar>
              <h4 className={Style.title}>Nuevo Producto</h4>
            </TitleBar>
            <Content>
              <Snackbar 
                open={errors ? true : false}
                onClose={closeSnack}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} >

                <MuiAlert 
                  elevation={1} 
                  variant='filled' 
                  severity='error'
                  onClose={closeSnack}>

                  { errors?.map((error, index) => <p key={index}>{error}</p>) }

                </MuiAlert>
              </Snackbar>
                
              <FormProvider {...methods}>
                <form className={Style.topForm}>
  
                  <Identity />

                  <div className={Style.leftButton}>
                    <Fab color='primary' onClick={()=>cancel()}>
                      <IoCloseSharp size='25px' /> 
                    </Fab>
                  </div>
                  <div className={Style.rightButton}>
                    <Fab color='primary' onClick={e=>save(e)}>
                      <IoIosSave size='25px'/>
                    </Fab>
                  </div>

                </form>
              </FormProvider>
              <DevTool control={methods.control} />
            </Content>
              
          </div>
};

export default ProductForm;
