import Style from './_ClientOrdersProductCard.module.sass'
import PictureAndInfo from './PictureAndInfo.js'

const ProductCard = ({hit}) => {
  const productType = hit
  const product = {...hit.product}
  product.id = hit.objectID || hit.id
  console.log(product);

  const info = {
    ownerId: product.ownerId,
    companyId: product.companyId,
    companyName: product.companyName,
    companyPicture: product.companyPicture,
    id: product.id,
    name: product.name, 
    description: product.description, 
    price: product.price, 
    }

  return  <div id={product.id} className={Style.card}>
            <PictureAndInfo picture={product.picture} info={info}/> 
            <div className={Style.quantity}>
              {productType.productCountPerType}
            </div>
            <div className={Style.foot}>
              <div className={Style.subTotal}>
                Subtotal: {productType.subTotalPerProductType}
                <div className={Style.tipoMoneda}> MXN </div>
              </div>
      
            </div>
          </div>
};

export default ProductCard;
