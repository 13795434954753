import {getAuth} from 'firebase/auth'
import {getResizedImages, getPictureExtension} from 'components/common/picture-utils'
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import {getFirestore, collection, doc, setDoc, getDoc} from 'firebase/firestore'

const registerBusiness = async (record) => {
  const userId = getAuth().currentUser.uid 
  const firestore = getFirestore()

  const companiesCollection = collection(firestore, `users/${userId}/companies`)
  var newBizRef = doc(companiesCollection)

  record.ps_count = 0
  record.authorized = false
  record.ownerId = userId

  const newPicture = record.picture[0]

  delete record.picture

  record['picture'] = {original: newBizRef.path + '/original' + getPictureExtension(newPicture.name)}
  record.picture.small = newBizRef.path + '/small' + getPictureExtension(newPicture.name) 
  record.picture.medium = newBizRef.path + '/medium' + getPictureExtension(newPicture.name)

  const resizedImages = getResizedImages(newPicture)
  console.log(resizedImages)

  let updatedUser = {}
  try {
    const storage = getStorage()

    var pictureRef = ref(storage, record.picture.original)
    await uploadBytes(pictureRef, newPicture);
    record.picture.original = await getDownloadURL(pictureRef)
    console.log('original picture saved!')

    var smallPictureRef = ref(storage, record.picture.small)
    await uploadBytes(smallPictureRef, resizedImages.small)
    record.picture.small = await getDownloadURL(smallPictureRef)
    console.log('small picture saved!')

    var mediumPictureRef = ref(storage, record.picture.medium)
    await uploadBytes(mediumPictureRef, resizedImages.medium)
    record.picture.medium = await getDownloadURL(mediumPictureRef)
    console.log('medium picture saved!')

    await setDoc(newBizRef, record)
    console.log('doc saved!')

    const userUpdate = {owner: {companies: {}}}
    userUpdate.owner.companies[`_${newBizRef.id}`] = newBizRef.path

    const dbUserRef = doc(firestore, `users/${userId}`)
    await setDoc(dbUserRef, userUpdate, {merge: true})

    const dbUserSS = await getDoc(dbUserRef)
    updatedUser = dbUserSS.data()

  } catch (e){
    console.log(e);
  }
  console.log(record, updatedUser)


  return {newRecord: record, updatedUser}
}

export {registerBusiness};



