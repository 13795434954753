import Style from './_ShoppingCartProductCard.module.sass'
import PictureAndInfo from './PictureAndInfo.js'
import CompanyReference from './CompanyReference'
import ShoppingCartAggregator from 'components/shopping-cart/ShoppingCartAggregator'
import MultiProductSubtotal from 'components/shopping-cart/MultiProductSubtotal'
import {useLocation} from 'react-router-dom'

const ProductCard = ({hit}) => {
  const location = useLocation()

  const product = {...hit}
  product.id = hit.objectID || hit.id
  console.log(product);

  const info = {
    ownerId: product.ownerId,
    companyId: product.companyId,
    companyName: product.companyName,
    companyPicture: product.companyPicture,
    id: product.id,
    name: product.name, 
    description: product.description, 
    price: product.price, 
    }

  return  <div id={product.id} className={Style.card}>
            <PictureAndInfo picture={product.picture} info={info}/> 
            <div className={`${Style.foot} ${location.pathname.startsWith('/shoppingcart') && Style.multiProductSubtotal}`}>
              {
              !(location.pathname.startsWith('/shoppingcart') || location.pathname.startsWith('/company/')) &&
                  <CompanyReference info={info}/>
              }
              { location.pathname.startsWith('/shoppingcart') &&
                <MultiProductSubtotal product={product} />
              } 
              <ShoppingCartAggregator product={product} />
            </div>
          </div>
};

export default ProductCard;
