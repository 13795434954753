const concepts = {
  motorcycle: {
    price: 17000,
    warrantyMonths: 24,
    warrantyKms: 20000,
    consumption: 39,
    services: {
      number: 8,
      price: 800,
    },
    parts: {
      price: 3000,
    },
    tires: {
      price: 1500,
    },
  },
  gasoline: {
    price: 23,
  },
  accountant: {
    price: 700,
  },
  baseDeliveryGuy: {
    minimumWage: 180.68,
    healthInsurance: 1100,
    biMoRetirementAndHome: 1100,
  },
  electricity: {
    price: 1000,
  },
  phone: {
    price: 320,
  },
  rent: {
    price: 1500,
  },
  admon: {
    hr: {
      wage: 180.68,
      healthInsurance: 1100,
      biMoRetirementAndHome: 1100,
    }
  },
  deliveryGuysNumber: 2
}

export default concepts
