import Style from "./_TopBar.module.sass";
import { ReactComponent as Truck } from "./img/truck.svg";
import {useState, useEffect, useCallback} from 'react'

const TopBar = ({company}) => {
  const checkAvailabilityForDay = (dayToCheck, thisTime) => {
    let startHrsStr = dayToCheck.from
    const [startHrStr, startMinStr] = startHrsStr.split(':')
    const startHrInt = Number.parseInt(startHrStr)
    const startMinInt = Number.parseInt(startMinStr)
    const startTime  = new Date().setHours(startHrInt, startMinInt, 0, 0)

    let endHrsStr = dayToCheck.to
    const [endHrStr, endMinStr] = endHrsStr.split(':')
    const endHrInt = Number.parseInt(endHrStr)
    const endMinInt = Number.parseInt(endMinStr)
    const endTime  = new Date().setHours(endHrInt, endMinInt, 0, 0)

    if (thisTime >= startTime && thisTime <= endTime)
      return true
    else
      return false
  }

  const checkAvailability = useCallback(() => {
    if (company.openHours) {
      const thisTime = new Date()
      const day = thisTime.getDay()

      switch (day) {
        case 0: 
          if(company.openHours.Domingo && company.openHours.Domingo.enabled)
            return checkAvailabilityForDay(company.openHours.Domingo, thisTime)
          break
        case 1: 
          if(company.openHours.Lunes && company.openHours.Lunes.enabled)
            return checkAvailabilityForDay(company.openHours.Lunes, thisTime)
          break
        case 2: 
          if(company.openHours.Martes && company.openHours.Martes.enabled)
            return checkAvailabilityForDay(company.openHours.Martes, thisTime)
          break
        case 3: 
          if(company.openHours.Miercoles && company.openHours.Miercoles.enabled)
            return checkAvailabilityForDay(company.openHours.Miercoles, thisTime)
          break
        case 4: 
          if(company.openHours.Jueves && company.openHours.Jueves.enabled)
            return checkAvailabilityForDay(company.openHours.Jueves, thisTime)
          break
        case 5: 
          if(company.openHours.Viernes && company.openHours.Viernes.enabled)
            return checkAvailabilityForDay(company.openHours.Viernes, thisTime)
          break
        case 6: 
          if(company.openHours.Sabado && company.openHours.Sabado.enabled)
            return checkAvailabilityForDay(company.openHours.Sabado, thisTime)
          break
        default:
          break
      }
    }
  }, [company.openHours])

  const [isOpen, setIsOpen] = useState((company.openHours && checkAvailability()) || false)

  useEffect(() => {
    if (company.openHours) {
      const interval = setInterval(() => setIsOpen(checkAvailability()), 60 * 1000)
      return () => clearInterval(interval)
    }
  }, [company.openHours, checkAvailability])

  const getTodaysOpenHours = () => {
    if (company.openHours) {
      const today = new Date().getDay()

      switch (today) {
        case 0: 
          if (company.openHours.Domingo && company.openHours.Domingo.enabled)
            return company.openHours.Domingo.from + ' - ' + company.openHours.Domingo.to
          break
        case 1: 
          if(company.openHours.Lunes && company.openHours.Lunes.enabled)
            return company.openHours.Lunes.from + ' - ' + company.openHours.Lunes.to
          break
        case 2: 
          if(company.openHours.Martes && company.openHours.Martes.enabled)
            return company.openHours.Martes.from + ' - ' + company.openHours.Martes.to
          break
        case 3: 
          if(company.openHours.Miercoles && company.openHours.Miercoles.enabled)
            return company.openHours.Miercoles.from + ' - ' + company.openHours.Miercoles.to
          break
        case 4: 
          if(company.openHours.Jueves && company.openHours.Jueves.enabled)
            return company.openHours.Jueves.from + ' - ' + company.openHours.Jueves.to
          break
        case 5: 
          if(company.openHours.Viernes && company.openHours.Viernes.enabled)
            return company.openHours.Viernes.from + ' - ' + company.openHours.Viernes.to
          break
        case 6: 
          if(company.openHours.Sabado && company.openHours.Sabado.enabled)
            return company.openHours.Sabado.from + ' - ' + company.openHours.Sabado.to
          break
        default:
          break
      }
    }
  }

  const isTodayOpen = () => {
    let isTodayOpen = false

    if (company.openHours) {
      const today = new Date().getDay()

      switch (today) {
        case 0: 
          isTodayOpen = company.openHours.Domingo && company.openHours.Domingo.enabled
          break
        case 1: 
           isTodayOpen = company.openHours.Lunes && company.openHours.Lunes.enabled
          break
        case 2: 
          isTodayOpen = company.openHours.Martes && company.openHours.Martes.enabled
          break
        case 3: 
          isTodayOpen = company.openHours.Miercoles && company.openHours.Miercoles.enabled
          break
        case 4: 
          isTodayOpen = company.openHours.Jueves && company.openHours.Jueves.enabled
          break
        case 5: 
          isTodayOpen = company.openHours.Viernes && company.openHours.Viernes.enabled
          break
        case 6: 
           isTodayOpen = company.openHours.Sabado && company.openHours.Sabado.enabled
          break
        default:
          isTodayOpen = false
          break
      }
    }
    return isTodayOpen
  }
  //console.log(company)

  const [showScheduleTable, setShowScheduleTable] = useState(false)

  const toggleScheduleTable = () => {
    setShowScheduleTable(!showScheduleTable)
  }

  return (
    <div className={Style.topBar}>
    { company.openHours &&
      <div className={Style.openHours} onClick={()=>toggleScheduleTable()}>
        <div className={Style.todaysOpenHours}>
          {isTodayOpen() && 'SERVICIO: ' + getTodaysOpenHours() }
        </div>
        <div className={Style.available} style={{color: isOpen ? 'green' : 'red'}}>
          {
            isTodayOpen() ? 
              isOpen ? ' ABIERTO': ' CERRADO'
              :
              'HOY CERRADO'
          }
        </div>
      { showScheduleTable ?
        <div className={Style.arrow}>&#9660;</div>
        :
        <div className={Style.arrow}>&#9650;</div>
      }
        <div className={Style.weeklySchedule} style={{display: showScheduleTable ? 'flex' : 'none'}}>
          {
              company.openHours.Lunes.enabled ?
                <div>
                  {'Lunes: ' + company.openHours.Lunes.from + ' - ' + company.openHours.Lunes.to}
                </div>
                :
                <div>Lunes: CERRADO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
          {
              company.openHours.Martes.enabled ?
                <div>
                  {'Martes: ' + company.openHours.Martes.from + ' - ' + company.openHours.Martes.to}
                </div>
                :
                <div>Martes: CERRADO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
          {
              company.openHours.Miercoles.enabled ?
                <div>
                  {'Miercoles: ' + company.openHours.Miercoles.from + ' - ' + company.openHours.Miercoles.to}
                </div>
                :
                <div>Miercoles: CERRADO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
          {
              company.openHours.Jueves.enabled ?
                <div>
                  {'Jueves: ' + company.openHours.Jueves.from + ' - ' + company.openHours.Jueves.to}
                </div>
                :
                <div>Jueves: CERRADO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
          { 
              company.openHours.Viernes.enabled ?
                <div>
                  {'Viernes: ' + company.openHours.Viernes.from + ' - ' + company.openHours.Viernes.to}
                </div>
                :
                <div>Viernes: CERRADO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
          { 
              company.openHours.Sabado.enabled ?
                <div>
                  {'Sabado: ' + company.openHours.Sabado.from + ' - ' + company.openHours.Sabado.to}
                </div>
                :
                <div>Sabado: CERRADO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
          { 
              company.openHours.Domingo.enabled ?
                <div>
                  {'Domingo: ' + company.openHours.Domingo.from + ' - ' + company.openHours.Domingo.to}
                </div>
                :
                <div>Domingo: CERRADO &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          }
        </div>
      </div>
    }
      <div className={Style.delivery}>
        {company.hasDelivery ? <Truck /> : ''}
      </div>
      <div className={Style.tipoNegocio}>
        {company.type}
      </div>
    </div>
  )
};

export default TopBar ;
