import Style from './_Facebook.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import {GrFacebook} from 'react-icons/gr';

const Facebook = () => {

  const methods = useFormContext();
                    console.log(methods.getValues('links.facebook.link') || 'asdf');

  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Facebook</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <TextField 
                label='Liga de Facebook' 
                type='text'
                placeholder='https://www.facebook.com/...'
                {...methods.register('links.facebook.link', {
                    value: methods.getValues('links.facebook.link'),
                    pattern: {
                      value: /https:\/\/w{3}\.facebook\.com\/.+/,
                      message: 'La liga no es valida'
                    }
                  })
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <GrFacebook />
                    </InputAdornment>
                  )
                }}/> 
            </div> 
          </div>
        </div>
}

export default Facebook;
