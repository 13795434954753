import Style from './_OrderCard.module.sass'
import {useNavigate} from 'react-router-dom'

const OrderCard = ({order, backgroundColor}) => {
  const navigate = useNavigate()
  
  return (
    <div 
      className={Style.orderCard} 
      style={{backgroundColor: backgroundColor}} 
      onClick={() => navigate(`/admin/monitoring/order/${order.id}`, {state: {order}})}>
      <div className={Style.orderId}>
        <div>Entrega: </div> 
        <div className={Style.data}>#{order.timestamp.seconds}</div>
      </div>
      <div className={Style.timestamp}>
        <div>Solicitada: </div>
        <div className={Style.data}>{new Date(order.timestamp.toMillis()).toLocaleString('es-MX', {hourCycle: 'h12'})}</div>
      </div>
      <div className={Style.duration}>
        <div>Tiempo de recorrido (aprox): </div>
        <div className={Style.data}>{order.routeDetails.duration} minutos</div>
      </div>
    </div>
  )
}

export default OrderCard
