import Style from './_Twitter.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import {FaTwitter} from 'react-icons/fa';

const Twitter = () => {

  const methods = useFormContext();
  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Twitter</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <Controller
                control={methods.control}
                name='links.twitter.handle'
                defaultValue={methods.getValues('links.twitter.handle')}
                rules={{
                  pattern: {
                    value: /@.+/,
                    message: 'El usuario no es valido'
                  }
                }}
                render={({field})=>(
                  <TextField 
                    label='Usuario de Twitter' 
                    type='text'
                    value={field.value}
                    onChange={field.onChange}
                    placeholder='@miUsuario'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <FaTwitter />
                        </InputAdornment>
                      )
                    }}/> 
                )}
              />

            </div> 
          </div>
        </div>
}

export default Twitter;
