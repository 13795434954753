const onLoad = (e, diameter) => {
  const foto =  e.target;
  foto.className='';
  //console.log(foto.width + " " + foto.height);
  //console.log(diameter);

  var shortestSide = foto.width < foto.height ? "width" : "height";
  foto.setAttribute("style", shortestSide + ": " + diameter + "px");
  foto.parentElement.style.top = "-" + ((foto.parentElement.clientHeight - diameter) / 2) + "px";
}

const changeImage = (event) => {
  const newImage = window.URL.createObjectURL(event.target.files[0]);
  const foto = document.getElementById('photo');

  foto.removeAttribute("style");
  foto.src = newImage;
}

const getPictureExtension = (picPath) => {
  return picPath.slice(picPath.lastIndexOf('.'))
}

const size = {
  SMALL_IMG: 100,
  MEDIUM_IMG: 250
}

const getNewDimensions = (width, height, size) => {
    const MAX = size
    let new_width = 0
    let new_height = 0
    let ratio = 0

    if (width > height) {
      ratio = MAX / width 
      new_width = MAX
      new_height = height * ratio
    } else {
      ratio = MAX / height
      new_height = MAX
      new_width = width * ratio
    }

    return [new_width, new_height]
}

const getResizedImage = async (img, type, size, name) => {
  let resizedImage = null

  const [width, height] = getNewDimensions(img.width, img.height, size)

  const canvas = new OffscreenCanvas(width, height)
  const context =  canvas.getContext('2d')

  context.drawImage(img, 0, 0, width, height)
  resizedImage = await canvas.convertToBlob({type: type, quality: .8})

  console.log(resizedImage)
  return resizedImage
}

const getResizedImages = (file) => {
  const resizedImages = {} 

  const image = new Image()
  image.onload = async () => {
    try {
      resizedImages.medium  = await getResizedImage(image, file.type, size.MEDIUM_IMG, 'medium')
      resizedImages.small =  await getResizedImage(image, file.type, size.SMALL_IMG, 'small')
    } catch (e) {
      console.log(e)
    }
  }

  image.src = URL.createObjectURL(file)

  return resizedImages
} 


export {onLoad, changeImage, getResizedImages, getPictureExtension};
