import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {listenForCompanySubOrders} from './order-utils'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {getAuth} from 'firebase/auth'
import SubOrderCard from './SubOrderCard' 

import Style from './_CompanySubOrders.module.sass'

const CompanySubOrders = () => {
  const location = useLocation() 
  const company = location.state.company
  const [companySubOrders, setCompanySubOrders] = useState({})

  useEffect(() => {
    const detachListener = listenForCompanySubOrders(company, getAuth().currentUser.uid, setCompanySubOrders)
    return () => detachListener()
  },[company])

  return <div>
            <TitleBar>
              <h4 className={Style.title}>Detalle de ventas</h4>
            </TitleBar>
            <Content>
              <div className={Style.companyName}>
                {company.companyName}
              </div>
              {
                companySubOrders?.newSubOrders?.length > 0 && 
                <div>
                  <div className={Style.section}>
                    Pedidos Nuevos:
                  </div>
                  <div>
                    {
                      companySubOrders?.newSubOrders?.map(subOrder => {
                        return <SubOrderCard key={subOrder.id} subOrder={subOrder} color='darkred' />
                      })
                    }
                  </div>
                </div>
              }

              { 
                companySubOrders?.subOrdersInPreparation?.length > 0 &&
                <div>
                  <div className={Style.section}>
                    Pedidos en preparacion:
                  </div>
                  <div>
                    {
                      companySubOrders?.subOrdersInPreparation?.map(subOrder => {
                        return <SubOrderCard key={subOrder.id} subOrder={subOrder} color='darkorange'/>
                      })
                    }
                  </div>
                </div>
              }
    
              { 
                companySubOrders?.subOrdersReadyForPickup?.length > 0 &&
                <div>
                  <div className={Style.section}>
                    Pedidos listos para ser recogidos:
                  </div>
                  <div>
                    {
                      companySubOrders?.subOrdersReadyForPickup?.map(subOrder => {
                        return <SubOrderCard key={subOrder.id} subOrder={subOrder} color='darkgreen'/>
                      })
                    }
                  </div>
                </div>
              }

            </Content>
            <FloatingButton
              position='bottom-left'
              to='/suborders'>
              <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
    </div>
};

export default CompanySubOrders
