import {getAuth} from 'firebase/auth'
import {getStorage, ref, listAll, deleteObject} from 'firebase/storage'
import {getFirestore, collection, getDocs, deleteDoc, doc, deleteField, updateDoc, getDoc} from 'firebase/firestore'

const deleteCompany = async (companyId) => {
  const userId = getAuth().currentUser.uid
  const storage = getStorage()
  const firestore = getFirestore()

  let updatedUser = {}

  try {
    console.log('Deleting products owned by company: ', companyId)
    const productsCollectionRef = collection(firestore, `users/${userId}/companies/${companyId}/products`)

    console.log('Deleting products from the database')
    const productListSS = await getDocs(productsCollectionRef)
    productListSS.forEach(async (productSS) => {
      await deleteDoc(productSS.ref)
    })

    console.log('deleting pictures for the products')
    const productsPicturesRef = ref(storage, productsCollectionRef.path)
    const productsPictures = await listAll(productsPicturesRef)
    productsPictures.items.forEach(async (itemRef) => {
      await deleteObject(itemRef)
    })

    console.log('Deleting company: ', companyId)
    const companyRef = doc(getFirestore(), `users/${userId}/companies/${companyId}`)
    
    console.log('Deleting company pictures')
    const companyPicturesRef = ref(storage, companyRef.path)
    const companyPictures = await listAll(companyPicturesRef)
    companyPictures.items.forEach(async (itemRef) => {
      await deleteObject(itemRef)
    })
    console.log('Company pictures deleted')

    const dbUserRef = doc(firestore, `users/${userId}`)
    await updateDoc(dbUserRef, {[`owner.companies._${companyRef.id}`]: deleteField()})

    const dbUserSS = await getDoc(dbUserRef)
    updatedUser = dbUserSS.data()

    await deleteDoc(companyRef)
    console.log('company ', companyId, ' deleted from db')

    document.getElementById(companyId).remove()

  } catch(e) { 
    console.log('Error while deleting the company ', companyId, ': ', e)
  }

  return {updatedUser}
}

export {deleteCompany}
