import TitleBar from 'components/common/TitleBar';
import Content from 'components/common/Content';
import FloatingButton from 'components/common/FloatingButton';
import {AiOutlineHome} from 'react-icons/ai';

import Style from './_About.module.sass';

const About  = () => {
  return  <div>
            <TitleBar>
              <h4 className={Style.title}>Sobre ManduX-T</h4>
            </TitleBar>
            <Content>
              <div className={Style.about}>
                <ul>
                  <li>
                    <h3>Misión</h3>
                    <p>
                      Proveer un servicio que impulse la participación de comercios y el crecimiento de la economía local, así como ofrecer la mejor comodidad para nuestros clientes.
                    </p>
                  </li>
                  <li>
                    <h3>Visión</h3>
                    <p>
                      Ser un referente de trabajo, unidad y progreso en la entidad al proveer un servicio de calidad.
                    </p>
                  </li>
                  <li>
                    <h3>Valores</h3>
                    <ul>
                      <li>Honestidad</li>
                      <li>Actitud de servicio</li>
                    </ul>
                  </li>
                  <li>
                    <h3>Contacto</h3>
                    <ul>
                      <li>246 157 9776</li>
                      <li>contacto@mandux-t.com</li>
                      <li>Corregidora 29, Sección 4 "El Rancho", Xicohtzinco, Tlaxcala, CP 90780</li>
                    </ul>
                  </li>
                </ul>

                <p className={Style.footer}>
                  ManduX-T es una solución para las operaciones de la empresa TIDUCX SAS DE CV, orientada a la paqueteria local.
                </p>
              </div>
          
            </Content>
            <FloatingButton
              position='bottom-right'
              to='/'>
              <AiOutlineHome style={{fill: 'white'}} size='26px' />
            </FloatingButton>
              
          </div>
};

export default About;
