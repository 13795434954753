import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {FaCashRegister} from 'react-icons/fa'
import {useLocation} from 'react-router-dom'
import {useSelector} from 'react-redux'
import ProductsPerCompany from './ProductsPerCompany'
import {useState} from 'react'
import DeliveryLocationSelection from './DeliveryLocationSelection'

import Style from './_ShoppingCart.module.sass'

const ShoppingCart = () => {
  const productsPerCompany = useSelector(state => state.shoppingCart.productsPerCompany)
  const deliveryType = useSelector(state => state.shoppingCart.routeDetails.destination.type)

  const location = useLocation()
  const [from] = useState(location.state?.fromSC ?? location.state?.from)
  const [company] = useState(location.state?.company)

  return <div>
            <TitleBar>
              <h4 className={Style.title}>Carrito de compras</h4>
            </TitleBar>
            <Content>
              <div className={Style.shoppingCart}>
                <DeliveryLocationSelection />
                <ProductsPerCompany companies={productsPerCompany} />
                <div className={Style.empty}/>
              </div>
            </Content>
            <FloatingButton
              position='bottom-left'
              to={from}
              stateParam={{from: location.state?.from, company}}>
              <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
            </FloatingButton>
            {
              productsPerCompany.length > 0 && deliveryType !== 'none' &&
            <FloatingButton
              position='bottom-right' 
              to='/payment'
              stateParam={location.state}>
              <FaCashRegister style={{fill: 'white'}} color='white' size='26px'/>
            </FloatingButton>

            }
    </div>
};

export default ShoppingCart;
