import Style from './_Telegram.module.sass';
import {TextField, InputAdornment} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import {FaTelegramPlane} from 'react-icons/fa';
import {validatePhoneNumber, formatPhoneInput} from 'utils/phone-utils'

const Telegram = () => {
  const methods = useFormContext();
  const call = methods.getValues('links.call')
  const sms = methods.getValues('links.sms')
  const telegram = methods.getValues('links.telegram')


  const previousMobile = telegram && telegram.number && telegram.number.length > 0 ? telegram.number :
                         sms?.number ?? 
                         (call?.number && call?.isMobile ? call.number : '')

  const telegramInput = methods.register('links.telegram.number', {
                          value: previousMobile,
                          pattern: {
                            value: /\(\d{3}\) \d{3} - \d{4}/,
                            message: 'El numero debe ser de 10 digitos'
                          }
                        })

  return <div className={Style.general}>
          <div className={Style.stepName}>
            <h4>Telegram</h4>
          </div>
          <p className={Style.description}></p>
          <div className={Style.info}>
            <div className={Style.fields}>
              <TextField 
                label='Numero telefonico' 
                type='tel'
                onKeyDown={e => validatePhoneNumber(e, methods.setValue, telegramInput.name)}
                onInput={e => formatPhoneInput(e, methods.setValue, telegramInput.name)}
                inputProps={{
                  maxLength: 16
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <FaTelegramPlane />
                    </InputAdornment>
                  )
                }}
                inputRef={telegramInput.ref}
              /> 
            </div> 
          </div>
        </div>
}

export default Telegram;
