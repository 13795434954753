import Style from './_Picture.module.sass';
import BusinessPicture from 'components/common/img/business.svg';
import {onLoad} from 'components/common/picture-utils';

const Picture = ({picture}) => {
return  <div className={Style.picture}>
              <div className={Style.pictureCircle}>
                <div className={Style.pictureContainer}>
                  <img id='img_1' src={picture !== '' ? picture : BusinessPicture} alt='Fotografia de negocio' onLoad={ e =>onLoad(e, 65) } className={Style.pictureImg}/>
                </div>
              </div>
            </div>
};

export default Picture;
