import {TextField, InputAdornment} from '@material-ui/core'
import {useFormContext, Controller} from 'react-hook-form'
import {validatePhoneNumber, formatPhoneInput} from 'utils/phone-utils'
import {IoCall} from 'react-icons/io5'

import Style from './_CreditCardOwnerDetailsForm.module.sass';

const CreditCardOwnerDetailsForm  = () => {
  const methods = useFormContext()
  const numberInput = methods.register('cardOwnerPhoneNumber')


  return (
    <div className={Style.ccDetailsForm}>
      <p className={Style.phoneLabel}>Informacion de cliente para pago:</p>
        <Controller 
          control={methods.control}
          name='cardOwnerName'
          defaultValue=''
          rules={{required: 'El nombre es necesario'}}
          render={({field}) => (
            <TextField 
              autoComplete='off'
              label='Nombre(s)' 
              size='small'
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
            /> 
          )}
          />
        <Controller 
          control={methods.control}
          name='cardOwnerLastname'
          defaultValue=''
          rules={{required: 'Los apellidos son necesarios'}}
          render={({field}) => (
            <TextField 
              autoComplete='off'
              label='Apellidos' 
              size='small'
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
            /> 
          )}
          />
        <Controller
          control={methods.control}
          name='cardOwnerPhoneNumber'
          defaultValue=''
          rules={{
              required: 'El numero telefonico es necesario',
              pattern: {
                value: /\(\d{3}\) \d{3} - \d{4}/,
                message: 'El numero debe ser de 10 digitos'
              }
          }}
          render={({field}) => (
            <TextField 
              label='Numero telefonico' 
              type='tel'
              size='small'
              value={field.value}
              onKeyDown={e => validatePhoneNumber(e, methods.setValue, numberInput.name)}
              onInput={e => formatPhoneInput(e, methods.setValue, numberInput.name)}
              inputProps={{
                maxLength: 16
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <IoCall />
                  </InputAdornment>
                )
              }}
              inputRef={field.ref}
            /> 
          )}
        />
        <Controller 
          control={methods.control}
          name='cardOwnerEmail'
          defaultValue=''
          rules={{
            required: 'El correo electronico es necesario',
            pattern: {
              value: /^[A-Za-z0-9._-]+@[A-Za-z0-9._-]+.[A-Za-z]{2,4}$/,
              message: 'El correo electronico es invalido'
            }
          }}
          render={({field}) => (
            <TextField 
              autoComplete='off'
              label='Correo electronico' 
              size='small'
              value={field.value}
              onChange={field.onChange}
              inputRef={field.ref}
            /> 
          )}
          />
    </div>
  )
};

export default CreditCardOwnerDetailsForm;
