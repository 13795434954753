import {useEffect} from 'react'
import {getAuth} from 'firebase/auth'

const DeliveryGuyTracker = () => {
  const locationCB = (coords, uid) => {
    const request = indexedDB.open('mandux-t-db', '1')
    request.onsuccess = event => {
      const db = event.target.result
      const transaction = db.transaction(['deliveryGuy'], 'readwrite')
      transaction.onsuccess = e => { console.log('transaction completed successfully') }
      transaction.onerror = e => { console.log('transaction failed') }

      const objectStore = transaction.objectStore('deliveryGuy')
      const request = objectStore.put({dgId: uid, coords: {lat: coords.latitude, lng: coords.longitude}})
      request.onsuccess = e => { console.log('db updated') }
      request.onerror = e => { console.log('db update failed') }
    }
  }

  useEffect(() => {
    if (getAuth()?.currentUser?.uid) {
      const watchId = navigator.geolocation.watchPosition(
        position => {locationCB(position.coords, getAuth().currentUser.uid)},
        error => console.log('error watching position: ', error),
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 60000
        }
      )

      return () => navigator.geolocation.clearWatch(watchId)
    }
  },[])

  return null
};

export default DeliveryGuyTracker;
