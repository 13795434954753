import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import LinkSelection from './LinkSelection'

import {Fab, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {IoMdArrowRoundForward} from 'react-icons/io'
import {IoCloseSharp} from 'react-icons/io5'
import {IoIosSave} from 'react-icons/io'

import Style from './_UpdateBusinessForm.module.sass'

import {useState, useEffect, useCallback} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {DevTool} from '@hookform/devtools'

import {steps} from './steps'
import {updateBusiness} from './form-utils'
import {sanitizeRecord} from '../form-utils'

import 'fontsource-roboto'

import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {getAuth} from 'firebase/auth'
import {getFirestore, doc, getDoc} from 'firebase/firestore'
import {useDispatch} from 'react-redux'
import {turnOn, turnOff} from 'redux/app/backdropSlice'

const UpdateBusinessForm  = () => {
  const dispatch = useDispatch()
  const {companyId, step} = useParams();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      hasDelivery: true,
      links: {
        call: {
          isMobile: true
        }
      }
    }
  });

  const reset = methods.reset
  const getValues = methods.getValues

  const getEnabledSteps = (allSteps) => {
    return allSteps.filter(e => e.enabled).map(e => ({...e}));
  }

  const [generatedSteps, setGeneratedSteps] = useState(getEnabledSteps(steps));

  const regenerateSteps = useCallback(() => {
    const selectedSteps = steps.map(e=>({...e}));
    const selectedLinks = getValues('cbs');

    if (selectedLinks) { 
    Object.entries(selectedLinks)
     .filter(([key,value])=>value)
     .map(([key,value])=>{
       let index =  selectedSteps.findIndex(({name}) => name === key);
       selectedSteps[index].enabled = value;
       return value;
     });
    }
    
    setGeneratedSteps(getEnabledSteps(selectedSteps));
  },[getValues])

  useEffect(() => {
    dispatch(turnOn())
    getDoc(doc(getFirestore(), `users/${getAuth().currentUser.uid}/companies/${companyId}`))
      .then((doc) => {
        console.log(doc.id, doc.data())
        const record = doc.data()
        record.id = doc.id

        record.currentPicture = record.picture.original
        delete record.picture

        record['cbs'] = {
          call: (record?.links?.call?.number ? true : false),
          sms: (record?.links?.sms?.number ? true : false),
          telegram: (record?.links?.telegram?.number ? true : false),
          whatsapp: (record?.links?.whatsapp?.number ? true : false),
          facebook: (record?.links?.facebook?.link ? true : false),
          twitter: (record?.links?.twitter?.handle ? true : false),
          instagram: (record?.links?.instagram?.username ? true : false),
          tiktok: (record?.links?.tiktok?.username ? true : false),
          youtube: (record?.links?.youtube?.channel ? true : false),
          website: (record?.links?.website?.link ? true : false),
        }

        regenerateSteps()

        console.log(record)

        reset(record)
      })
      .catch((e) => {
        console.log('Error while getting the company record for id: ', companyId)
      })
    dispatch(turnOff())
  }, [companyId, reset, regenerateSteps, dispatch])

  const navigate = useNavigate()
  const location = useLocation()
  const [errors, setErrors ] = useState(null)

  const manageStep = (step) => {
    if (step === 'linkSelection') {
      return <LinkSelection updateSteps={regenerateSteps} />
    }

    return steps.find(stepElement => stepElement.name === step)?.component ?? '';
  }

  const getCurrentStepIndex = () => {
    return generatedSteps.findIndex(({name}) => name === step);
  }

  const previousStep = () => {
    setErrors(null)
    navigate(-1);
  }

  const validate = async (fieldArrayToValidate) => {
    const areFieldsValid = await methods.trigger(fieldArrayToValidate);

    console.log(fieldArrayToValidate, areFieldsValid, methods.getValues(fieldArrayToValidate), step)
    return (areFieldsValid ? null : fieldArrayToValidate)
  }

  const nextStep = async(e) => {
    const index = getCurrentStepIndex();
    const pathname = location.pathname;
    const locationUrl = pathname.slice(0,pathname.lastIndexOf('/') + 1); 

    let invalidFields = null;
    switch(step){
      case 'identity': invalidFields = await validate(['picture', 'name', 'type', 'description']); break;
      case 'location': invalidFields = await validate(['location.coords']); break;
      case 'openhours': invalidFields = await validate(['openHours.Lunes.enabled',
                                                          'openHours.Martes.enabled',
                                                          'openHours.Miercoles.enabled',
                                                          'openHours.Jueves.enabled',
                                                          'openHours.Viernes.enabled',
                                                          'openHours.Sabado.enabled',
                                                          'openHours.Domingo.enabled',
                                                          'any.openHoursDay.enabled']); break;
      case 'call': 
        invalidFields = await validate(['links.call.number', 'links.call.extension']); 
        const call = methods.getValues('links.call')

          console.log(call.isMobile, methods.getValues('links.sms.number'), call?.number)
        if (!call.isMobile) {
          if (methods.getValues('links.sms.number') === call?.number)
            methods.setValue('links.sms.number', '')
          if (methods.getValues('links.telegram.number') === call?.number)
            methods.setValue('links.telegram.number', '')
          if (methods.getValues('links.whatsapp.number') === call?.number)
            methods.setValue('links.whatsapp.number', '')
        }
        break;
      case 'sms': invalidFields = await validate(['links.sms.number']); break;
      case 'telegram': invalidFields = await validate(['links.telegram.number']); break;
      case 'whatsapp': invalidFields = await validate(['links.whatsapp.number']); break;
      case 'facebook': invalidFields = await validate(['links.facebook.link']); break;
      case 'twitter': invalidFields = await validate(['links.twitter.handle']); break;
      case 'instagram': invalidFields = await validate(['links.instagram.username']); break;
      case 'tiktok': invalidFields = await validate(['links.tiktok.username']); break;
      case 'youtube': invalidFields = await validate(['links.youtube.channel']); break;
      case 'website': invalidFields = await validate(['links.website.link']); break;
      default: invalidFields = null;
    }

    console.log(invalidFields , methods.formState.errors)

    if (!invalidFields){
      if (index < (generatedSteps.length - 1)) {
        navigate(locationUrl + generatedSteps[index + 1].name);
      } else if (index === (generatedSteps.length - 1)) {
        methods.handleSubmit(submit)();
      }
      setErrors(null)
    } else {

      const invalidErrorMessages = []

      invalidFields.forEach(invalidField => {
        const chainNames = invalidField.split('.');
        let errorProperty = methods.formState.errors;
        
        let found = false
        chainNames.forEach(name => {
          const ep = errorProperty[name] 

          if (!ep) return

          errorProperty = ep
          found = true
        });

        if (!found) return  

        const invalidFieldMessage = errorProperty?.message;
        invalidFieldMessage && invalidErrorMessages.push(invalidFieldMessage);
      })

      setErrors(invalidErrorMessages)
    }
  }

  const submit = async (data) => { 
    console.log(data)
    dispatch(turnOn())
    const sanitizedRecord = sanitizeRecord(data)
    await updateBusiness(sanitizedRecord)
    dispatch(turnOff())
    navigate('/account/companies')
  };

  const closeSnack = (event, reason) => {
    if(reason === 'clickaway')
      return;

    setErrors(null);
  }

  return  <div>
            <TitleBar>
              <h4 className={Style.title}>Actualizacion de Negocio</h4>
            </TitleBar>
            <Content>
              <Snackbar 
                open={errors ? true : false}
                onClose={closeSnack}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} >

                <MuiAlert 
                  elevation={1} 
                  variant='filled' 
                  severity='error'
                  onClose={closeSnack}>

                  { errors?.map((error, index) => <p key={index}>{error}</p>) }

                </MuiAlert>
              </Snackbar>
                
              <FormProvider {...methods}>
                <form className={Style.topForm}>
  
                  {manageStep(step)}

                  <div className={Style.leftButton}>
                    <Fab color='primary' onClick={()=>previousStep()}>
                      {step === 'identity' ? 
                        <IoCloseSharp size='25px' /> : 
                        <IoMdArrowRoundBack size='25px'/>} 
                    </Fab>
                  </div>
                  <div className={Style.rightButton}>
                    <Fab color='primary' onClick={e=>nextStep(e)}>
                      { step === generatedSteps[generatedSteps.length - 1].name ?
                          <IoIosSave size='25px'/> :
                          <IoMdArrowRoundForward size='25px'/> }
                    </Fab>
                  </div>
                </form>
              </FormProvider>
              <DevTool control={methods.control} />
            </Content>
              
          </div>
};

export default UpdateBusinessForm;
