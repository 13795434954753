import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'

import {Fab, Snackbar} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import {IoCloseSharp} from 'react-icons/io5'
import {IoIosSave} from 'react-icons/io'

import Style from './_UpdateProductForm.module.sass'

import {useState, useEffect} from 'react'
import {useForm, FormProvider} from 'react-hook-form'
import {DevTool} from '@hookform/devtools'

import Identity from './Identity'
import {updateProduct} from './form-utils'

import 'fontsource-roboto'

import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {getAuth} from 'firebase/auth'
import {getFirestore, doc, getDoc} from 'firebase/firestore'

import {useDispatch} from 'react-redux'
import {turnOn, turnOff} from 'redux/app/backdropSlice'

const UpdateProductForm  = () => {
  const dispatch = useDispatch()
  const {companyId, productId} = useParams();

  const methods = useForm({
    mode: 'onChange'
  });

  const reset = methods.reset

  useEffect(() => {
    dispatch(turnOn())
    getDoc(doc(getFirestore(), `users/${getAuth().currentUser.uid}/companies/${companyId}/products/${productId}`))
      .then((doc) => {
        console.log(doc.id, doc.data())
        const record = doc.data()
        record.id = doc.id

        record.currentPicture = record.picture.medium
        delete record.picture

        console.log(record)
        reset(record)
      })
      .catch((e) => {
        console.log('Error while getting the product record: ', productId, 'by company: ', companyId)
      })
    dispatch(turnOff())
  }, [companyId, productId, reset, dispatch])

  const navigate = useNavigate()
  const location = useLocation()

  const [errors, setErrors ] = useState(null)

  const cancel = () => {
    setErrors(null)
    navigate(-1)
  }

  const validate = async (fieldArrayToValidate) => {
    const areFieldsValid = await methods.trigger(fieldArrayToValidate);

    console.log(fieldArrayToValidate, areFieldsValid, methods.getValues(fieldArrayToValidate))
    return (areFieldsValid ? null : fieldArrayToValidate)
  }

  const update = async(e) => {
    let invalidFields = await validate(['picture', 'name', 'description', 'price'])

    console.log(invalidFields , methods.formState.errors)

    if (!invalidFields){
      methods.handleSubmit(submit)();
      setErrors(null)
    } else {
      const invalidErrorMessages = []

      invalidFields.forEach(invalidField => {
        const chainNames = invalidField.split('.');
        let errorProperty = methods.formState.errors;
        
        chainNames.forEach(name => errorProperty = errorProperty[name]);

        const invalidFieldMessage = errorProperty?.message;
        invalidFieldMessage && invalidErrorMessages.push(invalidFieldMessage);
      })

      setErrors(invalidErrorMessages)
    }
  }

  const submit = async (data) => { 
    console.log(data)
    dispatch(turnOn())
    await updateProduct(companyId, data)
    dispatch(turnOff())
    navigate(`/account/companies/${companyId}/products`, {state: location.state, replace: true})
  };

  const closeSnack = (event, reason) => {
    if(reason === 'clickaway')
      return;

    setErrors(null);
  }

  return  <div>
            <TitleBar>
              <h4 className={Style.title}>Actualizacion de Producto</h4>
            </TitleBar>
            <Content>
              <Snackbar 
                open={errors ? true : false}
                onClose={closeSnack}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}} >

                <MuiAlert 
                  elevation={1} 
                  variant='filled' 
                  severity='error'
                  onClose={closeSnack}>

                  { errors?.map((error, index) => <p key={index}>{error}</p>) }

                </MuiAlert>
              </Snackbar>
                
              <FormProvider {...methods}>
                <form className={Style.topForm}>
  
                  <Identity />

                  <div className={Style.leftButton}>
                    <Fab color='primary' onClick={()=>cancel()}>
                        <IoCloseSharp size='25px' />
                    </Fab>
                  </div>
                  <div className={Style.rightButton}>
                    <Fab color='primary' onClick={e=>update(e)}>
                          <IoIosSave size='25px'/>
                    </Fab>
                  </div>

                </form>
              </FormProvider>
              <DevTool control={methods.control} />
            </Content>
              
          </div>
};

export default UpdateProductForm;
