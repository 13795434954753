import TitleBar from 'components/common/TitleBar'
import Content from 'components/common/Content'
import FloatingButton from 'components/common/FloatingButton'
import {IoMdArrowRoundBack} from 'react-icons/io'
import {IoCall} from 'react-icons/io5'
import {useEffect, useState} from 'react'
import {listenForOrderChanges, listenForIncomingSubOrders} from './delivery-utils'
import {useLocation} from 'react-router-dom'
import DeliveryMap from './DeliveryMap'
import CompanyCard from './CompanyCard'
import {OrderStatus, SubOrderStatus} from 'components/suborders/order-utils'
import {Button} from '@material-ui/core'

import Style from './_Order.module.sass'

const Order = () => {
  const location = useLocation()
  const [order, setOrder] = useState(location?.state?.order)
  const [subOrders, setSubOrders] = useState([])
  const [stopOrderLocations, setStopOrderLocations] = useState([])

  useEffect(() => {
    const detachListener = listenForOrderChanges(order.id, setOrder)
    return detachListener
  }, [order.id])

  useEffect(() => {
    const detachListener = listenForIncomingSubOrders(order.id, setSubOrders)
    return detachListener
  }, [order.id])
  
  const getSubOrderStatusColor = (status) => {
    let newColor = 'darkred'

    switch(status) {
      case SubOrderStatus.NEW:
        newColor = 'darkred'
        break
      case SubOrderStatus.IN_PREPARATION:
        newColor = 'darkred'
        break
      case SubOrderStatus.READY_FOR_PICKUP:
        newColor = 'darkred'
        break
      case SubOrderStatus.PICKED_UP:
        newColor = 'darkorange'
        break
      case SubOrderStatus.DELIVERED:
        newColor = 'darkgreen'
        break
      default:
        newColor = 'darkred'
        break
    }

    return newColor
  }

  const getStatusText = (status) => {
    let newText = ''

    switch(status) {
      case OrderStatus.NEW:
        newText = 'PENDIENTE'
        break
      case OrderStatus.IN_PROGRESS:
        newText = 'EN PROCESO'
        break
      case OrderStatus.ON_ITS_WAY:
        newText = 'EN CAMINO'
        break
      case OrderStatus.DELIVERED:
        newText = 'ENTREGADO'
        break
      default:
        break
    }

    return newText
  }

  return (
    <div>
      <TitleBar>
        <h4 className={Style.title}>Detalle de recorrido</h4>
      </TitleBar>
      <Content>
       <div className={Style.recipient}>
          <div className={Style.label}>Destinatario:</div>
            <Button 
              component='a' 
              color='secondary' 
              variant='contained' 
              size='small' 
              href={`tel: ${order?.recipient?.phoneNumber}`}>
              {order?.recipient?.name} 
              &nbsp;&nbsp;&nbsp;
              <IoCall size='20px'/>
            </Button>
        </div>
        <DeliveryMap  order={order} subOrders={subOrders} setStopOrderLocations={setStopOrderLocations}/>
        <div className={Style.stopList}>
        {
          stopOrderLocations.length > 0 && subOrders.length > 0 &&
          stopOrderLocations.map((nextStop, index) => {
            const subOrder = subOrders.find(subOrder => subOrder.companyLocation.lat === nextStop.location.lat &&
                                                        subOrder.companyLocation.lng === nextStop.location.lng)

            const stopInfo = {}
            stopInfo.isFirstStop = index === 0
            stopInfo.isLastStop = index === stopOrderLocations.length - 1
            stopInfo.stopNumber = index + 1

            console.log(subOrder)
            return <CompanyCard 
                      key={subOrder.id}
                      order={order}
                      subOrder={subOrder} 
                      statusColor={getSubOrderStatusColor(subOrder.status)} 
                      stopInfo={stopInfo} />
          })
        }
           <CompanyCard
              order={order}
              subOrder={{
                companyName: order?.recipient?.name,
                companyType: 'Destinatario',
                timestamp: order.timestamp,
                companyLocation: order.routeDetails.destination.marker
              }}
              statusColor={getSubOrderStatusColor(order.status)}
              stopInfo={{
                stopNumber: stopOrderLocations.length + 1,
              }} />

        </div>

        <div className={Style.price}>
          <div className={Style.countAndTotal}>
            <div className={Style.count}>
              Productos: {order.productCount}
            </div>

            <hr style={{border: '1px solid black', width: '100%'}}/>

            <div className={Style.subtotal}>
              Subtotal: {order.productsTotal}
              <div className={Style.tipoMoneda}> MXN </div>
            </div>

            <div className={Style.delivery}>
              Envio: {order.routeDetails.deliveryPrice}
              <div className={Style.tipoMoneda}> MXN </div>
            </div>

            <hr style={{border: '1px solid black', width: '100%'}}/>

            <div className={Style.total}>
              Total: {order.total}
              <div className={Style.tipoMoneda}> MXN </div>
            </div>
          </div>
        </div>

        <div className={Style.statusButtonDiv}>
            {getStatusText(order.status)}
        </div>
      </Content>
      <FloatingButton
        position='bottom-left'
        to='/admin/monitoring'>
        <IoMdArrowRoundBack style={{fill: 'white'}} size='26px'/>
      </FloatingButton>
    </div>
  )
};

export default Order;
